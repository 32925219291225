
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        

.c-modal-qr-scanner {
  & .modal__content {
    width: auto;
  }

  & .modal__main {
    padding: 1px 0 0;
    border-top: 1px solid @c-grey-lighter;
  }
}
