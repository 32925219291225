.text {
  margin: 0;

  font: inherit;
  font-size: @size;
  line-height: @line-height;
  color: inherit;

  &::after {
    content: '';
    display: table;
    width: 100%;
  }

  & p,
  & ul,
  & ol,
  & table,
  & img {
    max-width: 100%;
    margin: 0;

    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  & object,
  & video,
  & iframe {
    margin: 1.5em 0;
    max-width: 100%;
  }

  & b,
  & strong {
    font-weight: bold;
  }

  & i,
  & em {
    font-style: italic;
  }

  a {
    color: @c-red;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  & img {
    display: block;
    margin: 2em 0;
    max-width: 100%;
  }

  & > *:first-child,
  & > *:first-child > *:first-child {
    margin-top: 0;
  }

  & > *:last-child,
  & > *:last-child > *:last-child {
    margin-bottom: 0;
  }

  // - - - -

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: @w-bold;
    letter-spacing: 0.02em;
  }

  h1 {
    margin: (18/46em) 0;

    font-size: 3.5em;
    line-height: 1.2em;
  }

  h2 {
    margin: (18/30em) 0;

    font-size: 2.66666em;
    line-height: 1.2em;
  }

  h3 {
    margin: (18/20em) 0;

    font-size: 2em;
    line-height: 1.2em;
  }

  h4 {
    margin: (18/16em) 0;

    font-size: 1.5em;
    line-height: 1.2em;
  }

  h5 {
    margin: (18/14em) 0;

    font-size: 1.33333em;
    line-height: 1.2em;
  }

  h6 {
    margin: 1.5em 0;

    font-size: 1em;
    line-height: 1.2em;
    text-transform: uppercase;
  }

  // - - - -

  ul,
  ol {
    list-style: none;
    padding: 0 0 0 35px;
  }

  li {
    position: relative;
    margin: 0.66em 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
    }

    &:first-child {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  li p {
    margin: 0;
  }

  ul li:before {
    display: inline-block;
    top: 7px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: @c-red;
    margin-right: 8px;
  }

  ol {
    counter-reset: counter;
  }

  ol li {
    counter-increment: counter;
  }

  ol li:before {
    content: counter(counter) '.';
    margin-right: 3px;

    color: @c-red;
    font-weight: @w-bold;
  }

  // - - - -

  & table {
    display: block;
    overflow-x: auto;
  }

  // - - -

  & pre {
    font: inherit;
  }
}
