@nav-width: 25rem;

.profile-page {
  &__content {
    position: relative;
    flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    min-height: 38rem;
    background: white;
  }

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: @nav-width;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid @c-grey-lighter;
    overflow-y: auto;
    @media screen and (max-width: @breakpoints[sm]) {
      position: static;
      width: auto;
      height: auto;
    }
  }

  &__main {
    width: calc(100% - @nav-width);
    margin-left: auto;
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: @breakpoints[sm]) {
      width: auto !important;
      margin-left: 0;

      .person-info__header {
        //padding-top: 0;
        border-bottom: 0;
      }
    }

    &._resize {
      //width: calc(100% - 30rem);
      width: 100%;

      @media screen and (max-width: @breakpoints[xl]) {
        //width: calc(100% - 26rem);
      }
    }
  }

  //.page-header{
  //  padding: 1.5rem 2rem 1.5rem 2rem;
  //  @media screen and (max-width: @breakpoints[sm]) {
  //    padding: 1.5rem 3rem 1.5rem 2rem;
  //  }
  //  @media screen and (max-width: 848px) {
  //    padding: 1.5rem 3rem 1.5rem 2rem;
  //  }
  //
  //}
}
