.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem @page-sides-padding 1.5rem 2rem;
  box-shadow: inset 0 -1px 0.2rem rgba(0, 0, 0, 0.1);

  &__title {
    width: auto;
    margin: 0 3rem 0 0;
    font-size: 2.6rem;
    font-weight: @w-semi-bold;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title-col {
    display: flex;
  }

  &__search {
    width: 30rem;
  }

  &__title-col {
    display: flex;
    flex-wrap: wrap;
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }

  &__add-btn {
    padding: 0 0.4rem;
    width: 3rem;
    min-height: 3rem;

    font-size: 2.4rem;
    line-height: 1em;
  }

  &__status {
    margin-right: auto;
    //margin-left: 2rem;
    flex-shrink: 1;
    max-width: calc(100% - 5rem);

    & .dropdown__dropdown {
      width: 14rem;
    }

    & .dropdown__btn {
      border: 0;
      border-radius: 0;
      padding: 0;
      background: none;
      min-height: 2.8rem;

      font-size: @f-size;
      line-height: @f-lineheight;
      font-weight: @w-semi-bold;
      color: @c-grey-darkest;
    }

    & .dropdown__arrow svg {
      fill: @c-grey-darker !important;
    }
  }
}
