.preloader {
  position: absolute;
  z-index: 61;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: fade(white, 50);

  &._transparent {
    background: transparent;
  }

  &._inline {
    position: relative;
    min-height: 4rem;
  }

  &._cover-modal {
    top: -2.5rem;
    bottom: -2.5rem;
    left: -2.4rem;
    right: -2.4rem;
    width: auto;
    height: auto;
    border-radius: 0 0 @radius @radius;
  }

  &__circle {
    display: block;
    width: 3rem;
    height: 3rem;
    border: 0.3rem solid fade(@c-green, 20);
    border-right-color: @c-green;
    border-left-color: @c-green;
    border-radius: 50%;
    animation: rotate 0.6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    font-size: 0;
    position: sticky;
    top: calc(33vh - 3rem);
    bottom: calc(33vh - 3rem);
  }

  &._small &__circle {
    width: 2.2rem;
    height: 2.2rem;
    top: auto;
    bottom: auto;
  }
}
