.editor {
  border: 1px solid @c-grey-lighter;
  border-radius: @radius;
  position: relative;

  &__text .ProseMirror > *:last-child {
    margin-bottom: 0;
  }

  &__text .ProseMirror > *:first-child {
    margin-top: 0;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    border-bottom-color: white;
    border-radius: @radius @radius 0 0;
    overflow: hidden;
  }

  &__group {
    display: flex;
    border-right: 1px solid @c-grey-lighter;
    padding: 0 1px;

    &:last-child {
      margin-right: -1px;
    }
  }

  &__btn {
    padding: 0.4rem;
    min-height: 3.4rem;
    min-width: 3.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: @c-grey;
    }

    &._active,
    &:hover {
      background: @c-grey-lightest;
    }
  }

  &__content {
    background: @c-grey-lightest;
    padding: 0.8rem;
    border-radius: 0 0 @radius @radius;
    position: relative;
  }

  &__header-switcher {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
  }

  &__header-switcher ~ &__text {
    padding-right: 3rem;
  }

  & .ProseMirror {
    min-height: 8rem;
  }
}
