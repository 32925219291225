.information-for-documents {
  &__toggle {
    margin-bottom: 2rem;
  }

  &__editor {
    font-size: 1.2rem;
  }

  &__editor-result {
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    padding: 1.2rem 0.8rem;

    font-size: 1.2rem;
  }
}
