.date-input {
  .no-selection();
  &._loading {
    pointer-events: none;
  }

  &__main {
    display: flex;
    //border: 1px solid #E6E6E6;
    //border-radius: @radius;

    cursor: pointer;
  }

  &._error .input__input {
    border-color: @c-red-light;
  }

  &__picker {
    width: 50%;
    flex-grow: 1;
  }

  &__btn {
    flex-shrink: 0;
    padding: 0.2rem;
    width: 2.9rem;
    min-height: 3.2rem;
    border-radius: 0 0.3rem 0.3rem 0;
    background: transparent !important;
    border-left-width: 0;
    overflow: visible;

    & svg {
      fill: @c-blue;
      width: 1.8rem;
      height: 1.8rem;

      transition: transform @time;
    }

    &._clear svg {
      fill: @c-red-light;
    }

    &._reminder {
      svg {
        fill: @c-grey;
        transition: fill @time;
      }

      &::before {
        content: '';
        position: absolute;
        width: 80%;
        height: 80%;
        border-radius: 100%;
        background: @c-blue;
        transition: opacity @time;
        opacity: 0;
      }

      &:hover {
        svg {
          fill: @c-blue;
        }
      }

      &._active {
        &::before {
          opacity: 1;
        }
        svg {
          fill: white;
        }
      }
    }

    &:not(._reminder)::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      min-width: 1px;
      height: 1.8rem;
      transform: translateY(-50%);
      background: linear-gradient(
        to bottom,
        fade(@c-grey-darkest, 0),
        fade(@c-grey-darkest, 35%),
        fade(@c-grey-darkest, 0)
      );
    }
  }

  &__input-wrap {
    width: 50%;
    flex-grow: 1;
  }

  &__input {
    border-width: 0;
    cursor: pointer;

    & .input__input {
      .no-wrap();
    }

    &._readonly .input__input {
      cursor: pointer;
    }

    &._disabled .input__input {
      cursor: not-allowed;
    }

    &._over .input__label {
      width: calc(100% - 0.5rem);
    }

    &:not(:last-child) .input__input {
      padding-right: 2.9rem;
    }
  }

  &._disabled &__btn {
    cursor: not-allowed;
    opacity: 0.6;
  }

  //&._error &__input .input__input,
  //&._error &__btn {
  //  border-color: @c-red-light;
  //}

  & .mx-icon-calendar,
  & .mx-icon-clear {
    right: 0;
  }

  &__reminder-input {
    display: flex;
    //align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .input {
      width: 25rem;
    }

    &-buttons {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
    }
  }
}

.mx-datepicker-popup {
  & table {
    margin: 0;
  }

  & th,
  & td {
    padding: 0 !important;
    border-bottom: 0;
    background: transparent;
  }
}
