.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem 2rem;
  margin: 0;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  background: @c-green;
  border: 1px solid transparent;
  border-radius: @radius;
  min-height: 3.4rem;

  font-family: @f-font;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  font-weight: @w-semi-bold;
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: @f-letter-spacing;
  vertical-align: middle;
  //overflow: hidden;

  transition: color @time, background-color @time, border @time, opacity @time;

  //&:after {
  //  position: absolute;
  //  content: '';
  //  border-radius: 100%;
  //  background-color: fade(black, 8%);
  //  z-index: -1;
  //  left: 0;
  //  bottom: 0;
  //  width: 0;
  //  height: 0;
  //  transform-origin: left bottom;
  //  transform: translate3d(-6%, 41%, 0);
  //  transition: width .2s ease-out, height .2s ease-out;
  //  pointer-events: none;
  //}
  //
  //&:active {
  //
  //  &:after {
  //    width: 112%;
  //    height: 482%;
  //  }
  //}

  &:hover {
    background-color: @c-green-light;

    color: white;
    text-decoration: none;
  }

  &:disabled,
  &._disabled {
    cursor: not-allowed;
    //pointer-events: none;
    opacity: 0.4;
  }

  & svg {
    position: relative;
    fill: currentColor;
    //stroke: currentColor;
    flex-shrink: 0;
    margin-right: 0.8rem;
    margin-left: -1px;
    width: 2rem;
    height: 2rem;

    transition: @time;

    &:last-child {
      margin-left: 0.8rem;
      margin-right: -1px;
    }

    &:last-child:first-child {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:hover svg {
    color: white;
  }

  & .preloader {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    width: auto;
    height: auto;
  }

  &__badge {
    position: absolute;
    z-index: 4;
    top: 0.1rem;
    right: 0.1rem;
    border-radius: 1rem;
    height: 1.5rem;
    min-width: 1.5rem;
    background: white;
    padding: 0.2rem 0.4rem 0;
    font-size: 1.2rem;
    line-height: 1em;
    color: @c-green;
    text-align: center;
  }
}

// COLORS - - - -

.btn {
  // grey
  &._grey {
    background: @c-grey-lighter;
    color: @c-grey-darker;

    &:hover {
      background-color: @c-grey-lightest;
      opacity: 1;
    }

    &:hover svg {
      fill: @c-grey-darker;
    }
  }

  // white

  &._white {
    background: white;
    color: @c-grey;

    &:hover {
      background-color: @c-grey-lighter;
      opacity: 1;
    }

    &:hover svg {
      fill: @c-grey;
    }
  }

  // yellow

  &._yellow {
    background: @c-yellow;

    &:hover {
      background: @c-yellow-light;
    }
  }

  // blue

  &._blue {
    background: @c-blue;

    &:hover {
      background: @c-blue-light;
    }

    .btn__badge {
      color: @c-blue;
    }
  }

  // red

  &._red {
    background-color: @c-red;

    color: white;

    &:hover {
      background: @c-red-light;
    }
  }

  // black

  &._black {
    background: @c-grey-darker;

    &:hover {
      background: @c-grey-darker;
    }
  }
}

// SIZE - - - -

.btn {
  // large

  &._large {
    min-height: 4rem;
  }

  // small

  &._small {
    padding: 0.2rem 1.4rem;
    min-height: @small-btn-height;

    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: @w-regular;

    &::before {
      content: '';
      position: absolute;
      inset: -.5rem;
    }

    & svg {
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn__badge {
      outline: 1px solid currentColor;
      top: -0.8rem;
      right: auto;
      left: calc(100% - 0.8rem);
    }
  }
}

// FIGURE - - - -

.btn {
  // round

  &._round {
    border-radius: 2.2rem;
  }

  // square

  &._square {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 3.4rem;
  }

  // square small

  &._square._small {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    min-width: @small-btn-height;
  }

  // outline

  &._outline {
    border-color: @c-green;
    background: transparent;

    color: @c-green;

    &:hover {
      background-color: @c-green;
    }

    & svg {
      transition: none;
    }

    &:hover svg {
      fill: white;
    }
  }

  &._outline._white {
    border-color: white;

    color: white;

    & svg {
      fill: white;
    }

    &:hover {
      border-color: white;
      background: white;
    }

    &:hover svg {
      fill: @c-grey;
    }
  }

  &._outline._grey {
    border-color: @c-grey-light;

    color: @c-grey-light;

    & svg {
      fill: @c-grey-light;
    }

    &:hover {
      background-color: @c-grey;
      border-color: @c-grey;
    }

    &:hover svg {
      fill: white;
    }
  }

  &._outline._black {
    border-color: @c-grey-darker;

    color: @c-grey-darker;

    & svg {
      fill: @c-grey-darker;
    }

    &:hover {
      background-color: @c-grey-dark;
      border-color: @c-grey-dark;
    }

    &:hover svg {
      fill: white;
    }
  }

  &._outline._red {
    border-color: @c-red;

    color: @c-red;

    &:hover {
      background-color: @c-red-light;
      border-color: @c-red-light;
    }
  }

  &._outline._blue {
    border-color: @c-blue;

    color: @c-blue;

    &:hover {
      background-color: @c-blue-light;
      border-color: @c-blue-light;
    }
  }

  &._outline:hover {
    color: white;
  }
}

// dashed
.btn {
  &._dashed {
    background: transparent;
    border: 1px dashed @c-grey-light;

    color: @c-green;

    & svg {
      fill: @c-green;
    }

    &:hover {
      background: @c-green;
      border-color: @c-green;
    }
  }

  &._dashed._blue {
    color: @c-blue;

    & svg {
      fill: @c-blue;
    }

    &:hover {
      background: @c-blue;
      border-color: @c-blue;
    }
  }
  &._dashed._red {
    color: @c-red;

    & svg {
      fill: @c-red;
    }

    &:hover {
      background: @c-red;
      border-color: @c-red;
    }
  }

  &._dashed._orange {
    color: @c-orange;

    & svg {
      fill: @c-orange;
    }

    &:hover {
      background: @c-orange;
      border-color: @c-orange;
    }
  }

  // - - -

  &._dashed:hover {
    color: white;

    & svg {
      fill: white;
    }
  }
}

.btn {
  &._mr-1 {
    margin-right: 1rem;
  }

  &._mr-2 {
    margin-right: 2rem;
  }
}
