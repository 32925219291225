.editor-for-doc {
  position: relative;

  & b,
  & strong {
    font-weight: bold;
  }

  & p {
    margin: 0;
  }

  & .ProseMirror {
    background: #e4eefb;
  }

  & .menububble {
    position: absolute;
    z-index: 5;
    transform: translate(-50%, -20px);
    display: flex;
    background: @c-grey-darker;
    padding: 0.5rem;
    border-radius: @radius;
    visibility: hidden;
    opacity: 0;
  }

  & .menububble.is-active {
    visibility: visible;
    opacity: 1;
  }

  & .ProseMirror {
    min-height: 18px;
  }

  &__btn {
    padding: 0.4rem;
    min-height: 2.6rem;
    min-width: 2.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    background: transparent;

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: @c-grey;
    }

    &._active svg,
    &:hover svg {
      fill: white;
      //background: @c-grey-lightest;
    }
  }
}
