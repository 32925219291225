.time-kiosk-page {
  &__content {
    padding: 3rem 2rem;
    flex-grow: 1;
  }

  &__scan-title {
    margin: 0 0 2rem;
  }

  &__inline-preloader {
    margin: 2rem 0;
    display: flex;
    align-items: center;

    color: @c-grey;

    & .preloader {
      margin-right: 1rem;
    }
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__table {
    width: 50rem;
    max-width: 100%;
    margin-bottom: 3rem;

    & td:first-child {
      font-weight: @w-bold;
      color: @c-grey;
    }

    & td:last-child {
      text-align: right;
    }
  }

  &__note {
    color: @c-grey;
  }

  &__btn {
  }
}
