:root {
  --grid-offset-x: 1rem;
  --grid-offset-y: 1.5rem;
  --grid-offset-y-inputs: 2rem;
  --blank-preview-zoom: 112%;

  @media (max-width: @breakpoints[xl]) {
    --blank-preview-zoom: 100%;
  }
  //@media (max-width: 1450px) {
  //  --blank-preview-zoom: 90%;
  //}

  .modal {
    --blank-preview-zoom: 112%;

    @media (max-width: @breakpoints[md]) {
      --blank-preview-zoom: 100%;
    }
  }
}

* {
  box-sizing: border-box;
  flex: 0 1 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html._custom-scrollbar {
  &,
  * {
    &::-webkit-scrollbar {
      width: @scroll-width;
      height: @scroll-width;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.15);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);

      &:hover {
        background: rgba(0, 0, 0, 0.35);
      }
    }

    @supports (scrollbar-width: thin) {
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.05);
    }
  }

  .v-lightbox__img-wrap {
    &::-webkit-scrollbar {
      width: @scroll-width * 2;
      height: @scroll-width * 2;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background: #b5b5b5;

      &:hover {
        background: #9e9e9e;
      }
    }

    @supports (scrollbar-width: thin) {
      scrollbar-width: auto;
      scrollbar-color: #b5b5b5 #f2f2f2;
    }
  }
}

html {
  min-width: 0;
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  @media (max-width: 374px) {
    font-size: (1/0.375vw);
  }
}

body {
  position: relative;
  width: 100%;
  min-height: 100vh;
  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f9f9fb;
  min-width: 0;
  font-family: @f-font;
  font-size: @f-size;
  line-height: @f-lineheight;
  font-weight: @f-weight;
  letter-spacing: @f-letter-spacing;

  color: @c-primary-text;
  -webkit-overflow-scrolling: touch;
  .wrap();
}

a,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  color: inherit;

  &:disabled {
    opacity: 0.4;
  }
}

#app {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editr--content {
  min-height: 6.6rem;
  padding: @input-padding;

  font-size: @f-size-small;
  font-weight: @w-regular;
  line-height: @f-lineheight-small;
}

.reset-list {
  .reset-list();
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

img {
  font-size: 1rem;
  .no-wrap;
}

svg {
  fill: currentColor;
}

pre {
  white-space: break-spaces;
}
