.time-log-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 2rem;
    display: flex;
    align-items: center;
  }

  &__header-row {
    padding: 1rem 1rem 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @c-grey-lighter;

    &._sticky {
      position: sticky;
      z-index: 32;
      top: @header-height;
      background-color: white;
    }
  }

  &__employee-select {
    width: 25rem;
    margin-right: 1rem;
    min-width: 0;

    @media (max-width: @breakpoints[md]) {
      width: auto;
      flex: 1;
    }
  }

  .files-list__selected {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @media (max-width: @breakpoints[md]) {
      flex-direction: row;
    }
  }

  @media (max-width: @breakpoints[md]) {
    .files-list__selected {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      background-color: white;
      width: 100%;
      padding: 0.9rem 1.6rem;
    }
  }

  //&__status-select {
  //  width: 10rem;
  //  margin-right: 1rem;
  //}

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__search {
    max-width: 45%;
    min-width: 0;
    @media (max-width: @breakpoints[md]) {
      flex: 1;
      max-width: none;
    }

    .search {
      width: 100%;
    }
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__table-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__table-wrap > &__table > thead {
    position: sticky;
    top: 10.5rem;
    z-index: 2;
  }

  &__table {
    margin: 0;
    width: 100%;

    & > colgroup > col {
      &._checkbox {
        width: 4.8rem;
      }

      &._amount {
        width: 15rem;
      }

      &._actions {
        width: 4rem;
      }
    }

    & > tbody > tr {
      & > td,
      & > th {
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &__filters {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 2;
    border: 1px solid @c-grey-lighter;
    border-left: 0;
    border-right: 0;
    padding: 1.2rem 2rem;
    display: flex;
    gap: 1.5rem 2rem;
    margin-top: auto;
  }

  &__filter-select {
    width: 20rem;

    .select__option._disabled {
      display: none;
    }
  }

  &__dates-range {
    width: 25rem;
  }

  @media (max-width: @breakpoints[md]) {
    &__table-wrap {
      padding: 0 1.8rem;
    }

    &__table,
    &__table > tbody {
      display: block;
    }

    &__table > colgroup,
    &__table > thead {
      display: none;
    }

    &__table > tbody > tr {
      display: flex;
      flex-direction: column;
      padding: 1.1rem;
      border: 1px solid #e0e0e0;
      border-radius: 0.4rem;
      background-color: white;
      margin-top: 1.4rem;
      font-size: 1.2rem;
      padding-bottom: 0.5rem;
      position: relative;
    }

    &__table > tbody > tr > td {
      border: 0;
      padding: 0;
      text-align: left !important;
      margin-bottom: 1rem;
      vertical-align: baseline;

      &::before {
        content: attr(data-title);
        font-weight: 600;
      }

      &._actions {
        position: absolute;
        padding: 1.1rem;
        top: 0;
        right: 0;
      }
    }

    .preview-person-with-photo {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  // - - - -

  &__deductions-table {
    margin: 0;

    & th,
    & td {
      padding: 0.3rem 0;
      background: 0;

      &:first-child {
        padding-right: 1rem;
      }
    }

    tr:first-child:last-child td {
      border-bottom: 0;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  .files-list__selected {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @media (max-width: @breakpoints[md]) {
      flex-direction: row;
    }
  }

  @media (max-width: @breakpoints[md]) {
    .files-list__selected {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      background-color: white;
      width: 100%;
      padding: 0.9rem 1.6rem;
    }
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }
}
