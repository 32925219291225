/*@import 'variables/variables';
@import 'mixins/mixins';*/
:root {
  --grid-offset-x: 1rem;
  --grid-offset-y: 1.5rem;
  --grid-offset-y-inputs: 2rem;
  --blank-preview-zoom: 112%;
}
@media (max-width: 1600px) {
  :root {
    --blank-preview-zoom: 100%;
  }
}
:root .modal {
  --blank-preview-zoom: 112%;
}
@media (max-width: 980px) {
  :root .modal {
    --blank-preview-zoom: 100%;
  }
}
* {
  box-sizing: border-box;
  flex: 0 1 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html._custom-scrollbar::-webkit-scrollbar,
html._custom-scrollbar *::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}
html._custom-scrollbar::-webkit-scrollbar-track,
html._custom-scrollbar *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.15);
}
html._custom-scrollbar::-webkit-scrollbar-thumb,
html._custom-scrollbar *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
}
html._custom-scrollbar::-webkit-scrollbar-thumb:hover,
html._custom-scrollbar *::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.35);
}
@supports (scrollbar-width: thin) {
  html._custom-scrollbar,
  html._custom-scrollbar * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.05);
  }
}
html._custom-scrollbar .v-lightbox__img-wrap::-webkit-scrollbar {
  width: 1.6rem;
  height: 1.6rem;
}
html._custom-scrollbar .v-lightbox__img-wrap::-webkit-scrollbar-track {
  background: #f2f2f2;
}
html._custom-scrollbar .v-lightbox__img-wrap::-webkit-scrollbar-thumb {
  background: #b5b5b5;
}
html._custom-scrollbar .v-lightbox__img-wrap::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
@supports (scrollbar-width: thin) {
  html._custom-scrollbar .v-lightbox__img-wrap {
    scrollbar-width: auto;
    scrollbar-color: #b5b5b5 #f2f2f2;
  }
}
html {
  min-width: 0;
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
@media (max-width: 374px) {
  html {
    font-size: 2.66666667vw;
  }
}
body {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f9f9fb;
  min-width: 0;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  font-weight: 400;
  letter-spacing: 0;
  color: #333333;
  -webkit-overflow-scrolling: touch;
  white-space: normal;
  word-break: break-word;
}
@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}
a,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  color: inherit;
}
a:disabled,
button:disabled,
[type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled {
  opacity: 0.4;
}
#app {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editr--content {
  min-height: 6.6rem;
  padding: 0.8rem 1rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.33333333em;
}
.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.clearfix::after {
  content: '';
  display: table;
  clear: both;
}
img {
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
svg {
  fill: currentColor;
}
pre {
  white-space: break-spaces;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5em 0;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0;
}
h1,
.h1 {
  margin: 0.6em 0;
  font-size: 5.5rem;
}
h2,
.h2 {
  margin: 0.8em 0;
  font-size: 4.8rem;
}
h3,
.h3 {
  margin: 1.2em 0;
  text-transform: none;
  font-size: 2.6rem;
}
h4,
.h4 {
  margin: 1.33333333em 0;
  font-size: 2rem;
}
h5,
.h5 {
  font-size: 1.8rem;
  text-transform: uppercase;
}
h6,
.h6 {
  font-size: 1.4rem;
}
a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  transition: color 0.15s;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: none;
    color: #e64900;
  }
}
address {
  font: inherit;
  color: #616161;
  line-height: 1.71428571em;
}
ul,
ol,
p,
table {
  margin: 1.25em 0;
  color: inherit;
  font-size: inherit;
}
input,
textarea {
  display: inline-block;
  padding: 0.8rem 1rem 0.8rem;
  margin: 0;
  border: 1px solid #dedede;
  outline: none;
  border-radius: 0.3rem;
  background: white;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  vertical-align: top;
  color: #333333;
  transition: background-color 0.15s, color 0.15s, border-color 0.15s;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b8b8b8;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #b8b8b8;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #b8b8b8;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #b8b8b8;
}
textarea {
  resize: vertical;
}
textarea._no-resize {
  resize: none;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
table {
  border-collapse: collapse;
  max-width: 100%;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #1f1f1f;
  text-align: left;
}
th,
td {
  padding: 1.2rem 1rem;
  border-bottom: 1px solid #dedede;
  text-align: inherit;
  vertical-align: middle;
}
th:first-child,
td:first-child {
  padding-left: 2rem;
}
th:last-child,
td:last-child {
  padding-right: 2rem;
}
th._small-padding,
td._small-padding {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
th {
  background: #dedede;
  font-weight: 600;
  cursor: auto;
}
tfoot td {
  font-weight: 600;
}
@media (max-width: 980px) {
  th:first-child,
  td:first-child {
    padding-left: 1.6rem;
  }
  th:last-child,
  td:last-child {
    padding-right: 1.6rem;
  }
}
.row._dense,
[class*=' row-']._dense,
[class^='row-']._dense {
  --grid-offset-x: 0.5rem;
}
.container {
  width: 100%;
  padding-right: var(--grid-offset-x, 1rem);
  padding-left: var(--grid-offset-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}
.flex-1 {
  flex: 1;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: calc(var(--grid-offset-x, 1rem) * -1);
  margin-left: calc(var(--grid-offset-x, 1rem) * -1);
}
.row-align-start {
  align-items: flex-start;
}
.row-align-center {
  align-items: center;
}
.row-align-end {
  align-items: flex-end;
}
.row-grid {
  margin-bottom: calc(var(--grid-offset-y, 1.5rem) * -1);
}
.row-grid > [class*='col'] {
  margin-bottom: var(--grid-offset-y, 1.5rem);
}
.row-grid-inputs {
  margin-bottom: calc(var(--grid-offset-y-inputs, 2rem) * -1);
}
.row-grid-inputs > [class*='col'] {
  margin-bottom: var(--grid-offset-y-inputs, 2rem);
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > [class*='col'] {
  padding-right: 0;
  padding-left: 0;
}
.col,
[class*=' col-'],
[class^='col-'] {
  position: relative;
  width: 100%;
  padding-right: var(--grid-offset-x, 1rem);
  padding-left: var(--grid-offset-x, 1rem);
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (max-width: 450px) {
  .col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xs-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xs-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xs-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xs-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xs-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xs-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xs-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xs-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xs-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xs-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xs-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xs-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xs-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xs-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xs-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xs-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xs-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xs-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.333333%;
  }
  .offset-xs-2 {
    margin-left: 16.666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.333333%;
  }
  .offset-xs-5 {
    margin-left: 41.666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.333333%;
  }
  .offset-xs-8 {
    margin-left: 66.666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.333333%;
  }
  .offset-xs-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 575px) {
  .col-xm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xm-0 {
    margin-left: 0;
  }
  .offset-xm-1 {
    margin-left: 8.333333%;
  }
  .offset-xm-2 {
    margin-left: 16.666667%;
  }
  .offset-xm-3 {
    margin-left: 25%;
  }
  .offset-xm-4 {
    margin-left: 33.333333%;
  }
  .offset-xm-5 {
    margin-left: 41.666667%;
  }
  .offset-xm-6 {
    margin-left: 50%;
  }
  .offset-xm-7 {
    margin-left: 58.333333%;
  }
  .offset-xm-8 {
    margin-left: 66.666667%;
  }
  .offset-xm-9 {
    margin-left: 75%;
  }
  .offset-xm-10 {
    margin-left: 83.333333%;
  }
  .offset-xm-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 749px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 980px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 1100px) {
  .col-ml {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-ml-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-ml-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-ml-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-ml-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-ml-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-ml-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-ml-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-ml-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-ml-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-ml-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-ml-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-ml-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-ml-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-ml-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-ml-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-ml-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-ml-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-ml-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-ml-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-ml-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-ml-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-ml-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-ml-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-ml-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-ml-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-ml-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-ml-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-ml-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-ml-0 {
    margin-left: 0;
  }
  .offset-ml-1 {
    margin-left: 8.333333%;
  }
  .offset-ml-2 {
    margin-left: 16.666667%;
  }
  .offset-ml-3 {
    margin-left: 25%;
  }
  .offset-ml-4 {
    margin-left: 33.333333%;
  }
  .offset-ml-5 {
    margin-left: 41.666667%;
  }
  .offset-ml-6 {
    margin-left: 50%;
  }
  .offset-ml-7 {
    margin-left: 58.333333%;
  }
  .offset-ml-8 {
    margin-left: 66.666667%;
  }
  .offset-ml-9 {
    margin-left: 75%;
  }
  .offset-ml-10 {
    margin-left: 83.333333%;
  }
  .offset-ml-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 1340px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 1600px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 1920px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}
@media (max-width: 749px) {
  .row,
  [class*=' row-'],
  [class^='row-'] {
    --grid-offset-x: 0.5rem;
  }
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.3rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.3rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.3rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.3rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.3rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.3rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.3rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.3rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.3rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.3rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.3rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.3rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.3rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.3rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.3rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (max-width: 450px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.3rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.3rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.3rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.3rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.3rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.3rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.3rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.3rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.3rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.3rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 575px) {
  .m-xm-0 {
    margin: 0 !important;
  }
  .mt-xm-0,
  .my-xm-0 {
    margin-top: 0 !important;
  }
  .mr-xm-0,
  .mx-xm-0 {
    margin-right: 0 !important;
  }
  .mb-xm-0,
  .my-xm-0 {
    margin-bottom: 0 !important;
  }
  .ml-xm-0,
  .mx-xm-0 {
    margin-left: 0 !important;
  }
  .m-xm-1 {
    margin: 0.3rem !important;
  }
  .mt-xm-1,
  .my-xm-1 {
    margin-top: 0.3rem !important;
  }
  .mr-xm-1,
  .mx-xm-1 {
    margin-right: 0.3rem !important;
  }
  .mb-xm-1,
  .my-xm-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-xm-1,
  .mx-xm-1 {
    margin-left: 0.3rem !important;
  }
  .m-xm-2 {
    margin: 0.5rem !important;
  }
  .mt-xm-2,
  .my-xm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xm-2,
  .mx-xm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xm-2,
  .my-xm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xm-2,
  .mx-xm-2 {
    margin-left: 0.5rem !important;
  }
  .m-xm-3 {
    margin: 1rem !important;
  }
  .mt-xm-3,
  .my-xm-3 {
    margin-top: 1rem !important;
  }
  .mr-xm-3,
  .mx-xm-3 {
    margin-right: 1rem !important;
  }
  .mb-xm-3,
  .my-xm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xm-3,
  .mx-xm-3 {
    margin-left: 1rem !important;
  }
  .m-xm-4 {
    margin: 1.5rem !important;
  }
  .mt-xm-4,
  .my-xm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xm-4,
  .mx-xm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xm-4,
  .my-xm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xm-4,
  .mx-xm-4 {
    margin-left: 1.5rem !important;
  }
  .m-xm-5 {
    margin: 3rem !important;
  }
  .mt-xm-5,
  .my-xm-5 {
    margin-top: 3rem !important;
  }
  .mr-xm-5,
  .mx-xm-5 {
    margin-right: 3rem !important;
  }
  .mb-xm-5,
  .my-xm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xm-5,
  .mx-xm-5 {
    margin-left: 3rem !important;
  }
  .p-xm-0 {
    padding: 0 !important;
  }
  .pt-xm-0,
  .py-xm-0 {
    padding-top: 0 !important;
  }
  .pr-xm-0,
  .px-xm-0 {
    padding-right: 0 !important;
  }
  .pb-xm-0,
  .py-xm-0 {
    padding-bottom: 0 !important;
  }
  .pl-xm-0,
  .px-xm-0 {
    padding-left: 0 !important;
  }
  .p-xm-1 {
    padding: 0.3rem !important;
  }
  .pt-xm-1,
  .py-xm-1 {
    padding-top: 0.3rem !important;
  }
  .pr-xm-1,
  .px-xm-1 {
    padding-right: 0.3rem !important;
  }
  .pb-xm-1,
  .py-xm-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-xm-1,
  .px-xm-1 {
    padding-left: 0.3rem !important;
  }
  .p-xm-2 {
    padding: 0.5rem !important;
  }
  .pt-xm-2,
  .py-xm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xm-2,
  .px-xm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xm-2,
  .py-xm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xm-2,
  .px-xm-2 {
    padding-left: 0.5rem !important;
  }
  .p-xm-3 {
    padding: 1rem !important;
  }
  .pt-xm-3,
  .py-xm-3 {
    padding-top: 1rem !important;
  }
  .pr-xm-3,
  .px-xm-3 {
    padding-right: 1rem !important;
  }
  .pb-xm-3,
  .py-xm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xm-3,
  .px-xm-3 {
    padding-left: 1rem !important;
  }
  .p-xm-4 {
    padding: 1.5rem !important;
  }
  .pt-xm-4,
  .py-xm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xm-4,
  .px-xm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xm-4,
  .py-xm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xm-4,
  .px-xm-4 {
    padding-left: 1.5rem !important;
  }
  .p-xm-5 {
    padding: 3rem !important;
  }
  .pt-xm-5,
  .py-xm-5 {
    padding-top: 3rem !important;
  }
  .pr-xm-5,
  .px-xm-5 {
    padding-right: 3rem !important;
  }
  .pb-xm-5,
  .py-xm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xm-5,
  .px-xm-5 {
    padding-left: 3rem !important;
  }
  .m-xm-n1 {
    margin: -0.3rem !important;
  }
  .mt-xm-n1,
  .my-xm-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-xm-n1,
  .mx-xm-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-xm-n1,
  .my-xm-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-xm-n1,
  .mx-xm-n1 {
    margin-left: -0.3rem !important;
  }
  .m-xm-n2 {
    margin: -0.5rem !important;
  }
  .mt-xm-n2,
  .my-xm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xm-n2,
  .mx-xm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xm-n2,
  .my-xm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xm-n2,
  .mx-xm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xm-n3 {
    margin: -1rem !important;
  }
  .mt-xm-n3,
  .my-xm-n3 {
    margin-top: -1rem !important;
  }
  .mr-xm-n3,
  .mx-xm-n3 {
    margin-right: -1rem !important;
  }
  .mb-xm-n3,
  .my-xm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xm-n3,
  .mx-xm-n3 {
    margin-left: -1rem !important;
  }
  .m-xm-n4 {
    margin: -1.5rem !important;
  }
  .mt-xm-n4,
  .my-xm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xm-n4,
  .mx-xm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xm-n4,
  .my-xm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xm-n4,
  .mx-xm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xm-n5 {
    margin: -3rem !important;
  }
  .mt-xm-n5,
  .my-xm-n5 {
    margin-top: -3rem !important;
  }
  .mr-xm-n5,
  .mx-xm-n5 {
    margin-right: -3rem !important;
  }
  .mb-xm-n5,
  .my-xm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xm-n5,
  .mx-xm-n5 {
    margin-left: -3rem !important;
  }
  .m-xm-auto {
    margin: auto !important;
  }
  .mt-xm-auto,
  .my-xm-auto {
    margin-top: auto !important;
  }
  .mr-xm-auto,
  .mx-xm-auto {
    margin-right: auto !important;
  }
  .mb-xm-auto,
  .my-xm-auto {
    margin-bottom: auto !important;
  }
  .ml-xm-auto,
  .mx-xm-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 749px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.3rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.3rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 980px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.3rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.3rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.3rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.3rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 1100px) {
  .m-ml-0 {
    margin: 0 !important;
  }
  .mt-ml-0,
  .my-ml-0 {
    margin-top: 0 !important;
  }
  .mr-ml-0,
  .mx-ml-0 {
    margin-right: 0 !important;
  }
  .mb-ml-0,
  .my-ml-0 {
    margin-bottom: 0 !important;
  }
  .ml-ml-0,
  .mx-ml-0 {
    margin-left: 0 !important;
  }
  .m-ml-1 {
    margin: 0.3rem !important;
  }
  .mt-ml-1,
  .my-ml-1 {
    margin-top: 0.3rem !important;
  }
  .mr-ml-1,
  .mx-ml-1 {
    margin-right: 0.3rem !important;
  }
  .mb-ml-1,
  .my-ml-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-ml-1,
  .mx-ml-1 {
    margin-left: 0.3rem !important;
  }
  .m-ml-2 {
    margin: 0.5rem !important;
  }
  .mt-ml-2,
  .my-ml-2 {
    margin-top: 0.5rem !important;
  }
  .mr-ml-2,
  .mx-ml-2 {
    margin-right: 0.5rem !important;
  }
  .mb-ml-2,
  .my-ml-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-ml-2,
  .mx-ml-2 {
    margin-left: 0.5rem !important;
  }
  .m-ml-3 {
    margin: 1rem !important;
  }
  .mt-ml-3,
  .my-ml-3 {
    margin-top: 1rem !important;
  }
  .mr-ml-3,
  .mx-ml-3 {
    margin-right: 1rem !important;
  }
  .mb-ml-3,
  .my-ml-3 {
    margin-bottom: 1rem !important;
  }
  .ml-ml-3,
  .mx-ml-3 {
    margin-left: 1rem !important;
  }
  .m-ml-4 {
    margin: 1.5rem !important;
  }
  .mt-ml-4,
  .my-ml-4 {
    margin-top: 1.5rem !important;
  }
  .mr-ml-4,
  .mx-ml-4 {
    margin-right: 1.5rem !important;
  }
  .mb-ml-4,
  .my-ml-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-ml-4,
  .mx-ml-4 {
    margin-left: 1.5rem !important;
  }
  .m-ml-5 {
    margin: 3rem !important;
  }
  .mt-ml-5,
  .my-ml-5 {
    margin-top: 3rem !important;
  }
  .mr-ml-5,
  .mx-ml-5 {
    margin-right: 3rem !important;
  }
  .mb-ml-5,
  .my-ml-5 {
    margin-bottom: 3rem !important;
  }
  .ml-ml-5,
  .mx-ml-5 {
    margin-left: 3rem !important;
  }
  .p-ml-0 {
    padding: 0 !important;
  }
  .pt-ml-0,
  .py-ml-0 {
    padding-top: 0 !important;
  }
  .pr-ml-0,
  .px-ml-0 {
    padding-right: 0 !important;
  }
  .pb-ml-0,
  .py-ml-0 {
    padding-bottom: 0 !important;
  }
  .pl-ml-0,
  .px-ml-0 {
    padding-left: 0 !important;
  }
  .p-ml-1 {
    padding: 0.3rem !important;
  }
  .pt-ml-1,
  .py-ml-1 {
    padding-top: 0.3rem !important;
  }
  .pr-ml-1,
  .px-ml-1 {
    padding-right: 0.3rem !important;
  }
  .pb-ml-1,
  .py-ml-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-ml-1,
  .px-ml-1 {
    padding-left: 0.3rem !important;
  }
  .p-ml-2 {
    padding: 0.5rem !important;
  }
  .pt-ml-2,
  .py-ml-2 {
    padding-top: 0.5rem !important;
  }
  .pr-ml-2,
  .px-ml-2 {
    padding-right: 0.5rem !important;
  }
  .pb-ml-2,
  .py-ml-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-ml-2,
  .px-ml-2 {
    padding-left: 0.5rem !important;
  }
  .p-ml-3 {
    padding: 1rem !important;
  }
  .pt-ml-3,
  .py-ml-3 {
    padding-top: 1rem !important;
  }
  .pr-ml-3,
  .px-ml-3 {
    padding-right: 1rem !important;
  }
  .pb-ml-3,
  .py-ml-3 {
    padding-bottom: 1rem !important;
  }
  .pl-ml-3,
  .px-ml-3 {
    padding-left: 1rem !important;
  }
  .p-ml-4 {
    padding: 1.5rem !important;
  }
  .pt-ml-4,
  .py-ml-4 {
    padding-top: 1.5rem !important;
  }
  .pr-ml-4,
  .px-ml-4 {
    padding-right: 1.5rem !important;
  }
  .pb-ml-4,
  .py-ml-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-ml-4,
  .px-ml-4 {
    padding-left: 1.5rem !important;
  }
  .p-ml-5 {
    padding: 3rem !important;
  }
  .pt-ml-5,
  .py-ml-5 {
    padding-top: 3rem !important;
  }
  .pr-ml-5,
  .px-ml-5 {
    padding-right: 3rem !important;
  }
  .pb-ml-5,
  .py-ml-5 {
    padding-bottom: 3rem !important;
  }
  .pl-ml-5,
  .px-ml-5 {
    padding-left: 3rem !important;
  }
  .m-ml-n1 {
    margin: -0.3rem !important;
  }
  .mt-ml-n1,
  .my-ml-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-ml-n1,
  .mx-ml-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-ml-n1,
  .my-ml-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-ml-n1,
  .mx-ml-n1 {
    margin-left: -0.3rem !important;
  }
  .m-ml-n2 {
    margin: -0.5rem !important;
  }
  .mt-ml-n2,
  .my-ml-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-ml-n2,
  .mx-ml-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-ml-n2,
  .my-ml-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-ml-n2,
  .mx-ml-n2 {
    margin-left: -0.5rem !important;
  }
  .m-ml-n3 {
    margin: -1rem !important;
  }
  .mt-ml-n3,
  .my-ml-n3 {
    margin-top: -1rem !important;
  }
  .mr-ml-n3,
  .mx-ml-n3 {
    margin-right: -1rem !important;
  }
  .mb-ml-n3,
  .my-ml-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-ml-n3,
  .mx-ml-n3 {
    margin-left: -1rem !important;
  }
  .m-ml-n4 {
    margin: -1.5rem !important;
  }
  .mt-ml-n4,
  .my-ml-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-ml-n4,
  .mx-ml-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-ml-n4,
  .my-ml-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-ml-n4,
  .mx-ml-n4 {
    margin-left: -1.5rem !important;
  }
  .m-ml-n5 {
    margin: -3rem !important;
  }
  .mt-ml-n5,
  .my-ml-n5 {
    margin-top: -3rem !important;
  }
  .mr-ml-n5,
  .mx-ml-n5 {
    margin-right: -3rem !important;
  }
  .mb-ml-n5,
  .my-ml-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-ml-n5,
  .mx-ml-n5 {
    margin-left: -3rem !important;
  }
  .m-ml-auto {
    margin: auto !important;
  }
  .mt-ml-auto,
  .my-ml-auto {
    margin-top: auto !important;
  }
  .mr-ml-auto,
  .mx-ml-auto {
    margin-right: auto !important;
  }
  .mb-ml-auto,
  .my-ml-auto {
    margin-bottom: auto !important;
  }
  .ml-ml-auto,
  .mx-ml-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 1340px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.3rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.3rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.3rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.3rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.3rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.3rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.3rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.3rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.3rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.3rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.3rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.3rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.3rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.3rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.3rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.3rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.3rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.g-page-wrap {
  padding: 0 2rem;
  width: 100%;
}
.g-content-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
}
.g-td-content-min-height {
  min-height: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
.d-flex {
  display: flex !important;
}
.d-grid {
  display: grid !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (max-width: 450px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-grid {
    display: grid !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 575px) {
  .d-xm-none {
    display: none !important;
  }
  .d-xm-block {
    display: block !important;
  }
  .d-xm-table {
    display: table !important;
  }
  .d-xm-inline-block {
    display: inline-block !important;
  }
  .d-xm-inline {
    display: inline !important;
  }
  .d-xm-flex {
    display: flex !important;
  }
  .d-xm-grid {
    display: grid !important;
  }
  .d-xm-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 749px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 980px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1100px) {
  .d-ml-none {
    display: none !important;
  }
  .d-ml-block {
    display: block !important;
  }
  .d-ml-table {
    display: table !important;
  }
  .d-ml-inline-block {
    display: inline-block !important;
  }
  .d-ml-inline {
    display: inline !important;
  }
  .d-ml-flex {
    display: flex !important;
  }
  .d-ml-grid {
    display: grid !important;
  }
  .d-ml-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1340px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
.g-bg-red {
  background-color: #8f1b2c !important;
}
.g-bg-green {
  background-color: #14a75d !important;
}
.g-bg-blue {
  background-color: #4778ff !important;
}
.g-bg-yellow {
  background-color: #e7a811 !important;
}
.g-bg-black {
  background-color: #1f1f1f !important;
}
.g-bg-grey-lightest {
  background-color: #f2f2f2 !important;
}
.g-bg-white {
  background-color: white !important;
}
.g-c-white {
  color: white !important;
}
.g-c-primary {
  color: #333333 !important;
}
.g-c-black {
  color: #1f1f1f !important;
}
.g-c-grey {
  color: #808080 !important;
}
.g-c-grey-dark {
  color: #616161 !important;
}
.g-c-blue {
  color: #4778ff !important;
}
.g-c-yellow {
  color: #e7a811 !important;
}
.g-c-orange {
  color: #e17d10 !important;
}
.g-c-green {
  color: #14a75d !important;
}
.g-c-green-light {
  color: #00bb5d !important;
}
.g-c-red {
  color: #8f1b2c !important;
}
.g-fs-11 {
  font-size: 1.1rem !important;
}
.g-fs-12 {
  font-size: 1.2rem !important;
}
.g-fs-13 {
  font-size: 1.3rem !important;
}
.g-fs-14 {
  font-size: 1.4rem !important;
}
.g-fs-15 {
  font-size: 1.5rem !important;
}
.g-fs-16 {
  font-size: 1.6rem !important;
}
.g-fs-17 {
  font-size: 1.7rem !important;
}
.g-fs-18 {
  font-size: 1.8rem !important;
}
.g-fs-19 {
  font-size: 1.9rem !important;
}
.g-fs-20 {
  font-size: 2rem !important;
}
.g-fs-24 {
  font-size: 2.4rem !important;
}
.g-fs-25 {
  font-size: 2.5rem !important;
}
.g-fs-32 {
  font-size: 3.2rem !important;
}
.g-w-regular {
  font-weight: 400 !important;
}
.g-w-semi-bold {
  font-weight: 600 !important;
}
.g-w-medium {
  font-weight: 500 !important;
}
.g-w-bold {
  font-weight: 700 !important;
}
.nobr {
  white-space: nowrap;
  word-wrap: break-word;
  overflow-wrap: normal;
}
.g-text-uppercase {
  text-transform: uppercase !important;
}
.g-text-transform-none {
  text-transform: none !important;
}
.g-text-underline {
  text-decoration: underline !important;
}
.g-text-left {
  text-align: left !important;
}
.g-text-right {
  text-align: right !important;
}
.g-text-center {
  text-align: center !important;
}
.g-wrap {
  white-space: normal;
  word-break: break-word;
}
.g-no-wrap {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.g-align-center {
  align-items: center !important;
}
.g-align-self-center {
  align-self: center !important;
}
.g-justify-center {
  justify-content: center !important;
}
.g-justify-end {
  justify-content: flex-end !important;
}
.g-justify-between {
  justify-content: space-between !important;
}
.reset-button {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
}
.reset-button:not(:disabled):hover {
  text-decoration: unset;
}
.g-icon {
  width: 1.1em;
  height: 1.1em;
  vertical-align: middle;
  margin-top: calc((1em * 0.6) * -1);
  margin-bottom: calc((1em * 0.4) * -1);
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal__content,
.modal-leave-active .modal__content,
.modal-enter .v-lightbox__item,
.modal-leave-active .v-lightbox__item {
  transform: matrix(0.8, 0, 0, 0.8, 0, 0);
}
.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: transform cubic-bezier(0.25, 0.8, 0.5, 1) 0.15s, opacity cubic-bezier(0.25, 0.8, 0.5, 1) 0.15s;
  transform-origin: center;
}
.zoom-in-enter,
.zoom-in-leave-to {
  opacity: 0;
  transform: matrix(0.8, 0, 0, 0.8, 0, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.error-enter-active,
.error-leave-active {
  max-height: 70rem;
}
.error-enter,
.error-leave-to {
  margin: 0;
  max-height: 0;
}
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.15s, transform 0.15s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo {
  display: inline-block;
  height: 3rem;
  max-height: 100%;
  line-height: 0;
  text-transform: none;
}
.logo__img {
  height: 100%;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem 2rem;
  margin: 0;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  background: #14a75d;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  min-height: 3.4rem;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  transition: color 0.15s, background-color 0.15s, border 0.15s, opacity 0.15s;
}
.btn:hover {
  background-color: #00bb5d;
  color: white;
  text-decoration: none;
}
.btn:disabled,
.btn._disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.btn svg {
  position: relative;
  fill: currentColor;
  flex-shrink: 0;
  margin-right: 0.8rem;
  margin-left: -1px;
  width: 2rem;
  height: 2rem;
  transition: 0.15s;
}
.btn svg:last-child {
  margin-left: 0.8rem;
  margin-right: -1px;
}
.btn svg:last-child:first-child {
  margin-right: 0;
  margin-left: 0;
}
.btn:hover svg {
  color: white;
}
.btn .preloader {
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  width: auto;
  height: auto;
}
.btn__badge {
  position: absolute;
  z-index: 4;
  top: 0.1rem;
  right: 0.1rem;
  border-radius: 1rem;
  height: 1.5rem;
  min-width: 1.5rem;
  background: white;
  padding: 0.2rem 0.4rem 0;
  font-size: 1.2rem;
  line-height: 1em;
  color: #14a75d;
  text-align: center;
}
.btn._grey {
  background: #dedede;
  color: #333333;
}
.btn._grey:hover {
  background-color: #f2f2f2;
  opacity: 1;
}
.btn._grey:hover svg {
  fill: #333333;
}
.btn._white {
  background: white;
  color: #808080;
}
.btn._white:hover {
  background-color: #dedede;
  opacity: 1;
}
.btn._white:hover svg {
  fill: #808080;
}
.btn._yellow {
  background: #e7a811;
}
.btn._yellow:hover {
  background: #fbb304;
}
.btn._blue {
  background: #4778ff;
}
.btn._blue:hover {
  background: #478aff;
}
.btn._blue .btn__badge {
  color: #4778ff;
}
.btn._red {
  background-color: #8f1b2c;
  color: white;
}
.btn._red:hover {
  background: #aa0300;
}
.btn._black {
  background: #333333;
}
.btn._black:hover {
  background: #333333;
}
.btn._large {
  min-height: 4rem;
}
.btn._small {
  padding: 0.2rem 1.4rem;
  min-height: 2.4rem;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 400;
}
.btn._small::before {
  content: '';
  position: absolute;
  inset: -0.5rem;
}
.btn._small svg {
  margin-right: 0.4rem;
}
.btn._small svg:last-child {
  margin-right: 0;
}
.btn._small .btn__badge {
  outline: 1px solid currentColor;
  top: -0.8rem;
  right: auto;
  left: calc(100% - 0.8rem);
}
.btn._round {
  border-radius: 2.2rem;
}
.btn._square {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-width: 3.4rem;
}
.btn._square._small {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  min-width: 2.4rem;
}
.btn._outline {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.btn._outline:hover {
  background-color: #14a75d;
}
.btn._outline svg {
  transition: none;
}
.btn._outline:hover svg {
  fill: white;
}
.btn._outline._white {
  border-color: white;
  color: white;
}
.btn._outline._white svg {
  fill: white;
}
.btn._outline._white:hover {
  border-color: white;
  background: white;
}
.btn._outline._white:hover svg {
  fill: #808080;
}
.btn._outline._grey {
  border-color: #b8b8b8;
  color: #b8b8b8;
}
.btn._outline._grey svg {
  fill: #b8b8b8;
}
.btn._outline._grey:hover {
  background-color: #808080;
  border-color: #808080;
}
.btn._outline._grey:hover svg {
  fill: white;
}
.btn._outline._black {
  border-color: #333333;
  color: #333333;
}
.btn._outline._black svg {
  fill: #333333;
}
.btn._outline._black:hover {
  background-color: #616161;
  border-color: #616161;
}
.btn._outline._black:hover svg {
  fill: white;
}
.btn._outline._red {
  border-color: #8f1b2c;
  color: #8f1b2c;
}
.btn._outline._red:hover {
  background-color: #aa0300;
  border-color: #aa0300;
}
.btn._outline._blue {
  border-color: #4778ff;
  color: #4778ff;
}
.btn._outline._blue:hover {
  background-color: #478aff;
  border-color: #478aff;
}
.btn._outline:hover {
  color: white;
}
.btn._dashed {
  background: transparent;
  border: 1px dashed #b8b8b8;
  color: #14a75d;
}
.btn._dashed svg {
  fill: #14a75d;
}
.btn._dashed:hover {
  background: #14a75d;
  border-color: #14a75d;
}
.btn._dashed._blue {
  color: #4778ff;
}
.btn._dashed._blue svg {
  fill: #4778ff;
}
.btn._dashed._blue:hover {
  background: #4778ff;
  border-color: #4778ff;
}
.btn._dashed._red {
  color: #8f1b2c;
}
.btn._dashed._red svg {
  fill: #8f1b2c;
}
.btn._dashed._red:hover {
  background: #8f1b2c;
  border-color: #8f1b2c;
}
.btn._dashed._orange {
  color: #e17d10;
}
.btn._dashed._orange svg {
  fill: #e17d10;
}
.btn._dashed._orange:hover {
  background: #e17d10;
  border-color: #e17d10;
}
.btn._dashed:hover {
  color: white;
}
.btn._dashed:hover svg {
  fill: white;
}
.btn._mr-1 {
  margin-right: 1rem;
}
.btn._mr-2 {
  margin-right: 2rem;
}
.link {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  align-items: center;
  position: relative;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.15s;
}
.link:not(:disabled):hover {
  text-decoration: unset;
}
.link:not(:disabled):hover {
  text-decoration: unset;
}
.link:not(:disabled):hover {
  text-decoration: none;
  color: #4778ff;
}
.link._dashed {
  text-decoration-style: dashed;
}
.link svg {
  position: relative;
  fill: currentColor;
  flex-shrink: 0;
  margin-right: 0.8rem;
  margin-left: -1px;
  width: 2rem;
  height: 2rem;
}
.link svg:last-child {
  margin-left: 0.8rem;
  margin-right: -1px;
}
.link svg:last-child:first-child {
  margin-right: 0;
  margin-left: 0;
}
.link._primary {
  color: #333333;
}
.link._green {
  color: #14a75d;
}
.link._blue {
  color: #4778ff;
}
.link:disabled {
  cursor: auto;
  opacity: 0.6;
  color: #808080;
}
.light-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  background: transparent;
  border: 0;
  border-radius: 0;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  color: #1f1f1f;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  transition: color 0.15s;
}
.light-btn:hover {
  color: #478aff;
}
.photo-preview {
  background: #d8d8d8;
  overflow: hidden;
  width: 8rem;
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;
  transition: box-shadow 0.15s;
}
.photo-preview:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 75%;
}
.photo-preview:not(._empty):hover {
  box-shadow: 0 0 1rem rgba(31, 31, 31, 0.5);
}
.photo-preview__photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}
.modal {
  position: fixed;
  z-index: 91;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgba(64, 64, 69, 0.58);
  padding: 1rem;
  transition-duration: 0.3s;
  transition-property: opacity;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: contain;
}
@supports (height: 100dvh) {
  .modal {
    height: 100dvh;
  }
}
.modal__content {
  max-width: 100%;
  width: 62.8rem;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: translate3d(0, 0, 0);
  margin: auto;
}
@media (max-width: 749px) {
  .modal__content {
    width: 100%;
  }
}
.modal._for-doc .modal__content {
  width: 96rem;
}
.modal._slim .modal__content {
  width: 36rem;
}
.modal._medium .modal__content {
  width: 40rem;
}
.modal._medium-lg .modal__content {
  width: 50rem;
}
.modal._wide .modal__content {
  width: 96rem;
}
.modal._x-wide .modal__content {
  width: 140rem;
}
.modal._full-wide .modal__content {
  width: 100%;
}
.modal._full-height .modal__content {
  align-self: stretch;
}
.modal._small-title .modal__title {
  font-size: 1.8rem;
}
.modal__head {
  flex-shrink: 0;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.4rem 1.2rem;
  min-height: 6rem;
  border-radius: 0.3rem 0.3rem 0 0;
  position: sticky;
  top: -1rem;
  z-index: 100;
}
.modal._warning .modal__head {
  background-color: #e7a811;
  color: white;
}
.modal__title-ico {
  flex-shrink: 0;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1rem;
  margin-left: -0.2rem;
}
.modal__title {
  width: 50%;
  margin: 0;
  flex-grow: 1;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: 600;
  white-space: pre-line;
}
.modal._warning .modal__title-ico,
.modal._error .modal__title-ico {
  fill: white;
}
.modal._success .modal__title {
  color: #14a75d;
}
.modal._success .modal__title-ico {
  fill: #14a75d;
}
.modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid #979797;
  margin-left: 3rem;
  border-radius: 50%;
  transition: 0.15s;
}
.modal__close:hover {
  border-color: #8f1b2c;
}
.modal__close svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #979797;
  transition: 0.15s;
}
.modal__close:hover svg {
  fill: #8f1b2c;
  opacity: 1;
}
.modal._warning .modal__close,
.modal._error .modal__close {
  border-color: white;
}
.modal._warning .modal__close svg,
.modal._error .modal__close svg {
  fill: white;
}
.modal__main {
  padding: 2.5rem 2.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.modal._full-content .modal__main {
  padding: 0;
}
@media (max-width: 749px) {
  .modal {
    padding: 0;
  }
  .modal__head {
    top: 0;
  }
  .modal__head,
  .modal__main {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
.person-preview {
  position: relative;
}
.person-preview._loading {
  min-height: 32rem;
}
.text {
  font: inherit;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: inherit;
}
.text::after {
  content: '';
  display: table;
  width: 100%;
}
.text p,
.text ul,
.text ol,
.text table,
.text img {
  max-width: 100%;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.text object,
.text video,
.text iframe {
  margin: 0 0;
  max-width: 100%;
}
.text b,
.text strong {
  font-weight: bold;
}
.text i,
.text em {
  font-style: italic;
}
.text a {
  color: #8f1b2c;
  text-decoration: underline;
}
.text a:hover {
  text-decoration: none;
}
.text img {
  display: block;
  margin: 2em 0;
  max-width: 100%;
}
.text > *:first-child,
.text > *:first-child > *:first-child {
  margin-top: 0;
}
.text > *:last-child,
.text > *:last-child > *:last-child {
  margin-bottom: 0;
}
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  font-weight: 700;
  letter-spacing: 0.02em;
}
.text h1 {
  margin: 0.39130435em 0;
  font-size: 3.5em;
  line-height: 1.2em;
}
.text h2 {
  margin: 0.6em 0;
  font-size: 2.66666em;
  line-height: 1.2em;
}
.text h3 {
  margin: 0.9em 0;
  font-size: 2em;
  line-height: 1.2em;
}
.text h4 {
  margin: 1.125em 0;
  font-size: 1.5em;
  line-height: 1.2em;
}
.text h5 {
  margin: 1.28571429em 0;
  font-size: 1.33333em;
  line-height: 1.2em;
}
.text h6 {
  margin: 1.5em 0;
  font-size: 1em;
  line-height: 1.2em;
  text-transform: uppercase;
}
.text ul,
.text ol {
  list-style: none;
  padding: 0 0 0 3.5rem;
}
.text li {
  position: relative;
  margin: 0.66em 0;
}
.text li::before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
}
.text li:first-child {
  margin-bottom: 0;
}
.text li:last-child {
  margin-bottom: 0;
}
.text li p {
  margin: 0;
}
.text ul li:before {
  display: inline-block;
  top: 0.7rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background: #8f1b2c;
  margin-right: 0.8rem;
}
.text ol {
  counter-reset: counter;
}
.text ol li {
  counter-increment: counter;
}
.text ol li:before {
  content: counter(counter) '.';
  margin-right: 0.3rem;
  color: #8f1b2c;
  font-weight: 700;
}
.text table {
  display: block;
  overflow-x: auto;
}
.text pre {
  font: inherit;
}
@media screen and (max-width: 980px) {
  .text table {
    display: block;
    overflow-x: auto;
  }
  .text img {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  font-size: 1.4rem;
  word-break: break-word;
}
.checkbox:not(._disabled):not(._readonly) {
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.checkbox._small {
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.checkbox._disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.checkbox input {
  display: none;
}
.checkbox__mark {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.3rem;
  border: 1px solid #b8b8b8;
  flex-shrink: 0;
  color: white;
  transition: 0.15s;
}
.checkbox__mark svg {
  width: 1rem;
  height: 1rem;
  opacity: 0;
}
.checkbox__mark::before {
  content: '';
  position: absolute;
  inset: -0.6rem;
}
.checkbox:not(._disabled):not(._readonly):hover .checkbox__mark {
  border-color: #14a75d;
}
.checkbox._small .checkbox__mark {
  width: 1.6rem;
  height: 1.6rem;
}
.checkbox._checked .checkbox__mark {
  border-color: #14a75d;
  background: #14a75d;
}
.checkbox._checked .checkbox__mark svg {
  opacity: 1;
}
.checkbox._small .checkbox__mark svg {
  width: 0.8rem;
  height: 0.8rem;
}
.checkbox__text {
  margin-left: 0.8rem;
  font-size: inherit;
}
.checkbox__text .g-icon {
  font-size: 1.2em;
}
.checkbox._disabled .checkbox__text {
  opacity: 0.8;
}
.checkbox._radio .checkbox__mark {
  border-radius: 50%;
}
.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  font-size: 1.4rem;
}
.radio input {
  display: none;
}
.radio__mark {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  flex-shrink: 0;
  color: #14a75d;
  transition: 0.15s;
}
.radio__mark::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: white;
  opacity: 0;
  transition: opacity 0.15s;
}
.radio__mark:hover {
  border-color: currentColor;
}
.radio input:checked ~ .radio__mark {
  background: currentColor;
  border-color: currentColor;
}
.radio input:checked ~ .radio__mark::before {
  opacity: 1;
}
.radio__text {
  margin-left: 0.8rem;
  font-size: inherit;
}
.radio._disabled {
  cursor: not-allowed;
}
.radio._disabled .radio__text {
  color: #808080;
}
.radio-list__list {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 0 0 -2rem;
}
.radio-list__radio {
  margin: 1rem 0 0 2rem;
}
.error-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #aa0300;
  transition: 0.3s;
}
.error-list__item {
  margin: 0.5rem 0;
}
.input {
  position: relative;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  display: flex;
  flex-direction: column;
}
.input__label {
  display: block;
  margin-bottom: 0.6rem;
  font-size: inherit;
  line-height: inherit;
}
.input__pre {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  height: 3.4rem;
  align-items: center;
  color: #808080;
  padding-left: 0.5rem;
  pointer-events: none;
}
.input__pre ~ .input__input {
  padding-left: 1.5rem;
}
.input__input {
  width: 100%;
  border-radius: 0.3rem;
  background: white;
  font-size: inherit;
  line-height: inherit;
  transition-property: color, border, outline, box-shadow, background;
  transition-duration: 0.15s;
  min-height: 3.4rem;
}
.input__input:autofill,
.input__input:-webkit-autofill {
  background: white !important;
}
.input._no-resize .input__input {
  resize: none;
}
.input._over .input__label {
  position: absolute;
  bottom: 100%;
  left: 0.5rem;
  z-index: 2;
  transform: translateY(2.5rem);
  transform-origin: left bottom;
  display: block;
  margin: 0;
  width: calc(100% - 1rem / 2);
  pointer-events: none;
  color: #b8b8b8;
  transition: 0.15s;
}
.input._over .input__label > span {
  display: inline-block;
  padding: 0 0.6rem;
  background: white;
  border-radius: 0.3rem;
}
.input__input:autofill ~ .input__label,
.input__input:-webkit-autofill ~ .input__label,
.input._over._focus .input__label,
.input._over._filled .input__label {
  transform: translateY(0.5rem) scale(0.9);
}
.input._disabled .input__label {
  color: rgba(184, 184, 184, 0.6);
  cursor: not-allowed;
}
.input._disabled .input__required,
.input._disabled .input__input {
  opacity: 0.6;
  cursor: not-allowed;
}
.input._error .input__input {
  border-color: #aa0300;
}
.input__required {
  color: #e7a811;
}
.input__hide-btn,
.input__show-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
}
.input__hide-btn:not(:disabled):hover,
.input__show-btn:not(:disabled):hover {
  text-decoration: unset;
}
.input__hide-btn:not(:disabled):hover,
.input__show-btn:not(:disabled):hover {
  text-decoration: unset;
}
.input__hide-btn svg,
.input__show-btn svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: #4778ff;
  transition: transform 0.15s;
}
.input__hide-btn:hover svg,
.input__show-btn:hover svg {
  transform: scale(1.1);
}
.input._password .input__input {
  padding-right: 3rem;
}
.input._loading {
  pointer-events: none;
}
.input .preloader {
  width: auto;
}
.select {
  position: relative;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  min-width: 0;
}
.select._full-width {
  width: 100%;
}
.select__title {
  display: block;
  margin-bottom: 0.5rem;
  font: inherit;
}
.select._disabled .select__title {
  opacity: 0.8;
}
.select._over .select__title {
  position: absolute;
  bottom: 100%;
  left: 0.5rem;
  z-index: 2;
  transform: translateY(2.5rem);
  transform-origin: left bottom;
  display: block;
  margin: 0;
  width: calc(100% - 1rem / 2);
  pointer-events: none;
  color: #b8b8b8;
  transition: 0.15s;
}
.select._over .select__title > span {
  display: inline-block;
  padding: 0 0.6rem;
  background: white;
  border-radius: 0.3rem;
}
.select._over._open .select__title,
.select._over._selected .select__title {
  transform: translateY(0.5rem) scale(0.9);
}
.select__wrap {
  position: relative;
}
.select__container {
  width: 100%;
  min-height: 3.4rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-radius: 0.3rem;
  border: 1px solid #dedede;
  background: white;
  cursor: pointer;
  transition: 0.15s;
}
.select__container > * {
  flex-shrink: 0;
}
.select._disabled .select__container {
  cursor: not-allowed;
  opacity: 0.6;
}
.select._open .select__container,
.select:not(._disabled):not(._error):hover .select__container {
  border-color: #cdcccc;
}
.select._error .select__container {
  border-color: #aa0300;
}
.select__main {
  position: relative;
  width: 40%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 0.6rem 0 0.6rem 1rem;
}
.select._multiple .select__main {
  flex-wrap: wrap;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.4rem;
}
.select__input {
  width: 0;
  flex: 0 1 auto;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  font: inherit;
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  transition: max-height 0.15s;
}
.select._open:not(._multiple) .select__input {
  position: absolute;
  flex-grow: 1;
  top: 50%;
  width: calc(100% - 1rem);
  transform: translateY(-50%);
}
.select._multiple._selected .select__input {
  max-height: 0;
}
.select._multiple._open .select__input {
  max-height: none;
  width: 25%;
  flex-grow: 1;
  padding: 0.3rem 0.2rem 0.3rem 0.5rem;
  margin: 0.2rem 0.4rem 0.2rem 0;
}
.select__current {
  display: inline-block;
  flex: 1 1 auto;
  line-height: 1.6rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.select._selected._open:not(._multiple) .select__current {
  opacity: 0.5;
}
.select._multiple .select__current {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.2rem 0.4rem 0.2rem 0;
  padding: 0.3rem 0.2rem 0.3rem 0.5rem;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  flex-grow: 0;
  transition: 0.15s;
  white-space: normal;
  word-break: break-word;
}
.select._multiple .select__current:last-child {
  margin: 0;
}
.select__current .select__cancel {
  height: auto;
}
.select .select__current._placeholder {
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 4rem);
  margin: 0;
  padding: 0;
  opacity: 0.5;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.select__cancel {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  margin-left: 0.2rem;
  position: relative;
  height: 3.2rem;
}
.select__cancel svg {
  width: 0.8rem;
  height: 0.8rem;
  fill: #b8b8b8;
  transition: 0.15s;
}
.select:not(._disabled) .select__cancel:hover svg {
  fill: #aa0300;
}
.select._multiple:not(._disabled) .select__current:hover {
  color: #aa0300;
}
.select._multiple:not(._disabled) .select__current:hover svg {
  fill: #aa0300;
}
.select._error .select__input {
  border-color: #aa0300;
}
.select__dropdown {
  position: absolute;
  z-index: 41;
  transform-origin: center top;
  top: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 4rem;
  pointer-events: none;
}
.select._right .select__dropdown {
  right: 0;
  left: auto;
}
.select._to-top .select__dropdown {
  top: auto;
  bottom: 100%;
  transform-origin: center bottom;
  padding-bottom: 0;
  padding-top: 4rem;
}
.select__dropdown-inner {
  background: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.4rem 0.6rem 0.2rem rgba(0, 0, 0, 0.1);
  border: 1px solid #dedede;
  margin: 1px 0;
  border-radius: 0.3rem;
  max-height: 34rem;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: auto;
}
.select__option {
  padding: 0.8rem 1rem;
  cursor: pointer;
}
.select__option._selected {
  background-color: #f2f2f2;
  color: #4778ff !important;
}
.select__option._selected * {
  color: #4778ff !important;
}
.select__option._selected:not(._disabled):focus,
.select__option._selected:not(._disabled):hover {
  background-color: rgba(71, 120, 255, 0.5) !important;
}
.select__option._disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.select__option:not(._disabled):focus,
.select__option:not(._selected):focus,
.select__option:not(._disabled):hover,
.select__option:not(._selected):hover {
  background-color: #4778ff !important;
  color: white !important;
}
.select__option:not(._disabled):focus *,
.select__option:not(._selected):focus *,
.select__option:not(._disabled):hover *,
.select__option:not(._selected):hover * {
  color: white !important;
}
.select__option-row {
  margin-bottom: 0.4rem;
}
.select__option-row:last-child {
  margin-bottom: 0;
}
.select__dropdown-placeholder {
  padding: 1rem 2rem;
  opacity: 0.6;
  text-align: center;
}
.select__arrow {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7rem;
  height: 3.2rem;
  padding-right: 0.5rem;
  position: relative;
}
.select__arrow svg {
  width: 0.6rem;
  height: 1rem;
  opacity: 0.5;
  transform: rotate(90deg);
  transition: 0.15s;
}
.select__arrow .preloader {
  right: 0.5rem;
  width: auto;
}
.select._open .select__arrow svg {
  transform: rotate(270deg);
}
.select._open .select__arrow svg,
.select:not(._disabled):hover .select__arrow svg {
  opacity: 0.65;
}
.select__required {
  color: #e7a811;
}
.toaster {
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100%;
  z-index: 1002;
  padding: 1rem 0 1rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.toaster__item {
  padding: 1.4rem 5rem 1.4rem 2.3rem;
  transition: transform 0.3s, opacity 0.3s;
  border-radius: 1rem 0 0 1rem;
  width: 37.5rem;
  position: relative;
  opacity: 0;
  margin: 1rem 0;
  color: white;
  background-color: #4778ff;
  word-break: break-word;
  max-width: calc(100vw - 2rem);
  cursor: pointer;
  box-shadow: 0 0.1rem 0.6rem rgba(71, 120, 255, 0.9);
  transform: translateX(100%);
}
.toaster__item-text {
  font-weight: 600;
}
.toaster__item._active {
  opacity: 0.9;
  transform: none;
}
.toaster__item._active:hover {
  opacity: 1;
}
.toaster__item-ico {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 2rem;
  top: 1.3rem;
}
.toaster__item-ico svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.toaster__item._success {
  background-color: #14a75d;
  box-shadow: 0 0.1rem 0.6rem rgba(20, 167, 93, 0.9);
}
.toaster__item._error {
  background-color: #8f1b2c;
  box-shadow: 0 0.1rem 0.6rem rgba(143, 27, 44, 0.9);
}
.toaster__item ul {
  margin-bottom: 0;
  margin-top: 0.5rem;
  padding-left: 2.5rem;
}
.alert {
  background: #b8b8b8;
  border-radius: 0.3rem;
  overflow: hidden;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: white;
}
.alert._error {
  background-color: #aa0300;
}
.alert._warning {
  background-color: #e17d10;
}
.alert._success {
  background-color: #14a75d;
}
.alert__list {
  list-style: none;
  margin: 0;
  padding: 0.5rem 2rem;
}
.alert__list-item {
  position: relative;
  margin: 0.8rem 0;
  padding: 0 0 0 1rem;
  font: inherit;
}
.alert__list-item:before {
  content: '';
  position: absolute;
  top: 0.6rem;
  left: 0;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background: white;
  opacity: 0.6;
}
.dropdown {
  position: relative;
  display: inline-flex;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.dropdown__btn {
  width: 100%;
  border-color: #b8b8b8;
  background: transparent;
  padding-left: 1rem;
  padding-right: 0;
  font: inherit;
  text-align: left;
  color: #b8b8b8;
  text-decoration: none;
  text-transform: none;
}
.dropdown__btn:hover {
  background-color: #808080;
  border-color: #808080;
  color: white;
}
.dropdown._dashed .dropdown__btn {
  border-style: dashed;
}
.dropdown__btn-text {
  width: 50%;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.dropdown__arrow {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  padding-right: 0.5rem;
  margin-left: 0.2rem;
}
.dropdown__arrow svg {
  width: 0.6rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: #b8b8b8;
  transition: 0.15s;
}
.dropdown__btn:hover .dropdown__arrow svg {
  fill: white;
}
.dropdown._open .dropdown__arrow svg {
  transform: rotate(270deg);
}
.dropdown__dropdown {
  position: absolute;
  z-index: 41;
  top: calc(100% + 0.5rem);
  left: -2rem;
  width: calc(100% + 4rem);
  height: 1rem;
  transition: 0.3s;
}
.dropdown__btn ~ .dropdown__dropdown {
  top: 100%;
}
.dropdown._on-hover .dropdown__dropdown {
  visibility: hidden;
  opacity: 0;
}
.dropdown._on-hover:hover .dropdown__dropdown {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.dropdown__drop-arrow {
  pointer-events: none;
  width: 2rem;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}
.dropdown__drop-arrow::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -0.6rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  transform-origin: center;
  background: white;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.15);
}
.dropdown__dropdown-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 0.6rem 0;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dedede;
  border-top: 0;
  border-radius: 0.3rem;
  min-width: 100%;
}
.dropdown__drop-arrow ~ .dropdown__dropdown-inner {
  top: 100%;
}
.dropdown._right .dropdown__dropdown {
  left: auto;
  right: 0;
}
.dropdown._right .dropdown__dropdown-inner {
  left: auto;
  right: 0;
  transform: none;
}
.dropdown._left .dropdown__dropdown {
  left: 0;
}
.dropdown._left .dropdown__dropdown-inner {
  left: 0;
  transform: none;
}
.dropdown__item {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  border: 0;
  padding: 0.8rem 1rem;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  transition: 0.15s;
}
.dropdown__item:disabled {
  cursor: not-allowed;
}
.dropdown__item svg {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  flex-shrink: 0;
  fill: #616161;
  transition: 0.15s;
}
.dropdown__item:not(:disabled):hover {
  background-color: #4778ff;
  color: white;
  text-decoration: none;
}
.dropdown__item:not(:disabled):hover svg {
  fill: white;
}
.dropdown__item._red:not(:disabled) {
  color: #8f1b2c;
}
.dropdown__item._red:not(:disabled) svg {
  fill: #8f1b2c;
}
.dropdown__item._green:not(:disabled) {
  color: #14a75d;
}
.dropdown__item._green:not(:disabled) svg {
  fill: #14a75d;
}
.dropdown__item._red:not(:disabled):hover,
.dropdown__item._green:not(:disabled):hover {
  background-color: #8f1b2c;
  color: white;
}
.dropdown__item._red:not(:disabled):hover svg,
.dropdown__item._green:not(:disabled):hover svg {
  fill: white;
}
.dropdown__item._green:not(:disabled):hover {
  background-color: #14a75d;
}
.dropdown._right .dropdown__item {
  text-align: right;
}
.actions {
  position: relative;
  display: inline-flex;
}
.actions__btn {
  border-color: #dedede;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.actions__dropdown {
  position: absolute;
  z-index: 41;
  top: calc(100% + 0.4rem);
  left: 0;
  width: 100%;
  transition: 0.3s;
}
.actions__dropdown-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 0.5rem;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
  border-top: 0;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
}
.actions__dropdown-inner > * {
  margin: 0 0.8rem 0 0;
}
.actions__dropdown-inner > *:last-child {
  margin-right: 0;
}
.actions._left .actions__dropdown {
  top: 0;
  left: auto;
  right: calc(100% + 0.4rem);
  width: auto;
  height: 100%;
}
.actions._left .actions__dropdown-inner {
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  box-shadow: -0.2rem 0 0.6rem rgba(0, 0, 0, 0.25);
}
.help-tooltip {
  position: relative;
  display: inline-flex;
  margin: 0 0.5rem;
}
.help-tooltip__btn {
  min-height: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.help-tooltip__dropdown {
  position: absolute;
  z-index: 41;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  transition: 0.3s;
}
.help-tooltip__arrow {
  width: 2rem;
  height: 2rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  pointer-events: none;
}
.help-tooltip__arrow::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -0.6rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  transform-origin: center;
  background: white;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.15);
}
.help-tooltip__dropdown-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 1.6rem 2rem;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dedede;
  border-top: 0;
  border-radius: 0.3rem;
  min-width: 18rem;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip__trigger {
  display: inline-block;
}
.tooltip__ico {
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-top: -0.5rem;
  margin-bottom: -0.2rem;
  fill: #4778ff;
}
.tooltip__dropdown {
  position: absolute;
  z-index: 41;
  top: calc(100% + 0.7rem);
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8);
  transition-duration: 0.3s;
  transition-property: visibility, opacity, transform;
  transition-delay: 0s;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.tooltip__trigger:hover + .tooltip__dropdown {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.2s;
}
.tooltip__arrow {
  width: 2rem;
  height: 2rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  margin-bottom: -1px;
}
.tooltip__arrow::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -0.6rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  transform-origin: center;
  background: white;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dedede;
}
.tooltip__dropdown-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 1rem;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  min-width: 18rem;
  text-align: center;
}
.tooltip._left .tooltip__dropdown-inner {
  left: auto;
  transform: none;
  right: 0;
}
.tooltip._right .tooltip__dropdown-inner {
  left: 0;
  transform: none;
}
.tooltip._top .tooltip__dropdown {
  top: auto;
  bottom: calc(100% + 0.7rem);
}
.tooltip._top .tooltip__dropdown-inner {
  top: auto;
  bottom: 0;
}
.tooltip._top .tooltip__arrow {
  bottom: auto;
  top: 100%;
  margin-bottom: 0;
  margin-top: -1px;
  transform: translateX(-50%) scaleY(-1);
}
.tooltip._auto .tooltip__dropdown-inner {
  min-width: auto;
  width: auto;
  white-space: nowrap;
}
.preloader {
  position: absolute;
  z-index: 61;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.preloader._transparent {
  background: transparent;
}
.preloader._inline {
  position: relative;
  min-height: 4rem;
}
.preloader._cover-modal {
  top: -2.5rem;
  bottom: -2.5rem;
  left: -2.4rem;
  right: -2.4rem;
  width: auto;
  height: auto;
  border-radius: 0 0 0.3rem 0.3rem;
}
.preloader__circle {
  display: block;
  width: 3rem;
  height: 3rem;
  border: 0.3rem solid rgba(20, 167, 93, 0.2);
  border-right-color: #14a75d;
  border-left-color: #14a75d;
  border-radius: 50%;
  animation: rotate 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  font-size: 0;
  position: sticky;
  top: calc(33vh - 3rem);
  bottom: calc(33vh - 3rem);
}
.preloader._small .preloader__circle {
  width: 2.2rem;
  height: 2.2rem;
  top: auto;
  bottom: auto;
}
.editor {
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  position: relative;
}
.editor__text .ProseMirror > *:last-child {
  margin-bottom: 0;
}
.editor__text .ProseMirror > *:first-child {
  margin-top: 0;
}
.editor__header {
  display: flex;
  flex-wrap: wrap;
  border-bottom-color: white;
  border-radius: 0.3rem 0.3rem 0 0;
  overflow: hidden;
}
.editor__group {
  display: flex;
  border-right: 1px solid #dedede;
  padding: 0 1px;
}
.editor__group:last-child {
  margin-right: -1px;
}
.editor__btn {
  padding: 0.4rem;
  min-height: 3.4rem;
  min-width: 3.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
}
.editor__btn:last-child {
  margin-right: 0;
}
.editor__btn svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: #808080;
}
.editor__btn._active,
.editor__btn:hover {
  background: #f2f2f2;
}
.editor__content {
  background: #f2f2f2;
  padding: 0.8rem;
  border-radius: 0 0 0.3rem 0.3rem;
  position: relative;
}
.editor__header-switcher {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}
.editor__header-switcher ~ .editor__text {
  padding-right: 3rem;
}
.editor .ProseMirror {
  min-height: 8rem;
}
.side-nav {
  position: fixed;
  z-index: 82;
  left: 0;
  top: 5rem;
  width: 25rem;
  height: calc(100vh - 5rem);
  background: white;
  box-shadow: 0 0.6rem 0.8rem 0 rgba(140, 140, 140, 0.5);
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
}
@supports (height: 100dvh) {
  .side-nav {
    height: calc(100dvh - 5rem);
  }
}
.side-nav._is-modal {
  position: static;
  z-index: auto;
  width: 100%;
  height: auto;
}
.side-nav._is-modal .side-nav__content {
  padding-left: 0;
}
.side-nav._is-modal .side-nav__link::before,
.side-nav._is-modal .side-nav__link::after {
  border-radius: 0;
}
.side-nav._is-modal .side-nav__list-wrap {
  height: auto;
  overflow: hidden;
}
.side-nav._is-modal .sublist,
.side-nav._is-modal .sublist::after {
  border-radius: 0;
}
.side-nav._less {
  width: 6rem;
}
.side-nav__content {
  width: 100%;
  height: 50%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  transition: padding-left 0.3s;
}
.side-nav._less .side-nav__content {
  padding-left: 0;
}
.side-nav__toggle {
  flex-shrink: 0;
  width: 100%;
  height: 4rem;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7.4rem;
  cursor: pointer;
  text-decoration: none !important;
  transition: padding-right 0.3s;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.side-nav__toggle svg {
  width: 1rem;
  height: 1.8rem;
  opacity: 0.3;
  transform: rotate(180deg);
  transition: opacity 0.15s, transform 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__toggle:hover svg {
    opacity: 0.5;
  }
}
.side-nav._less .side-nav__toggle {
  padding-right: 2.1rem;
}
.side-nav._less .side-nav__toggle svg {
  transform: rotate(180deg) rotateY(180deg);
}
.side-nav__logo {
  margin: 1.5rem 2rem;
  flex-shrink: 0;
}
.side-nav__list-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  height: 50%;
  padding: 2rem 0;
}
.side-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.side-nav__item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}
.side-nav__item::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 0.2rem;
  background: transparent;
  transition: 0.15s;
}
.side-nav__item._open::before {
  background: white;
}
.side-nav__link {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0.6rem 5rem 0.8rem 1.5rem;
  min-height: 4.8rem;
  cursor: pointer;
  color: #808080;
  text-decoration: none !important;
  transition: 0.15s;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  font-weight: inherit;
}
.side-nav__link > * {
  position: relative;
  z-index: 4;
}
.side-nav__link::before,
.side-nav__link::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: -1px;
  right: 0;
  width: 0;
  height: calc(100% + 0.2rem);
  background: transparent;
  border-radius: 2rem 0 0 2rem;
  transition: 0.3s;
}
.side-nav__link::before {
  width: 100%;
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__link:hover {
    color: #e64900;
    text-decoration: none;
  }
  .side-nav__link:hover::after {
    background-color: #f2f2f2;
    width: 100%;
  }
}
.side-nav__link._active {
  color: white;
}
.side-nav__link._active::after {
  background-color: #e64900;
  width: 100%;
}
.side-nav._less .side-nav__link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  justify-content: center;
}
.side-nav__item._open .side-nav__link::before,
.side-nav__link._active::before {
  background: white;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__link:hover::before {
    background: white;
  }
}
.side-nav__item._open .side-nav__link::after {
  border-bottom-left-radius: 0;
  background-color: #f2f2f2;
  width: 100%;
}
.side-nav__item .side-nav__link._active::after {
  background-color: #e64900;
}
.side-nav__link-content {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  transition: width 0.3s;
}
.side-nav._less .side-nav__link-content {
  width: 2rem;
}
.side-nav__link-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;
  fill: rgba(128, 128, 128, 0.8);
  transition: fill 0.15s;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__link:hover .side-nav__link-icon {
    fill: #e64900;
  }
}
.side-nav__link._active .side-nav__link-icon {
  fill: white;
}
.side-nav__plus,
.side-nav__show-more {
  position: absolute;
  top: 1.3rem;
  left: 19.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.2rem;
  height: 2.2rem;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.5);
  font-size: 1.7rem;
  text-decoration: none !important;
  transition: 0.15s;
  z-index: 5;
}
.side-nav__plus::before,
.side-nav__show-more::before {
  content: '';
  position: absolute;
  inset: -0.6rem;
}
.side-nav._is-modal .side-nav__plus,
.side-nav._is-modal .side-nav__show-more {
  left: auto;
  right: 1.5rem;
}
.side-nav__plus._active {
  color: #e64900 !important;
  border-color: #e64900 !important;
  background: white;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__plus:hover {
    color: #e64900 !important;
    border-color: #e64900 !important;
    background: white;
  }
}
.side-nav__show-more {
  border: 0;
  pointer-events: none;
}
.side-nav._less .side-nav__plus,
.side-nav._less .side-nav__show-more {
  opacity: 0;
  pointer-events: none;
}
.side-nav__show-more svg {
  position: relative;
  left: -1px;
  width: 0.6rem;
  height: 0.8rem;
  transform: rotate(90deg);
  fill: rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
@media (hover: hover) and (pointer: fine) {
  .side-nav__link:hover ~ .side-nav__show-more svg {
    fill: #e64900;
  }
}
.side-nav__item._open .side-nav__show-more {
  border-color: #e64900;
}
.side-nav__item._open .side-nav__show-more svg {
  transform: rotate(270deg);
}
.side-nav__link._active ~ .side-nav__plus,
.side-nav__link._active ~ .side-nav__show-more {
  color: white;
  border-color: white;
}
.side-nav__link._active ~ .side-nav__plus svg,
.side-nav__link._active ~ .side-nav__show-more svg {
  fill: white;
}
.side-nav__link-name {
  min-width: 13.5rem;
}
.sublist {
  position: relative;
  z-index: 4;
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  background: #fafafa;
  border-radius: 0 0 0 2rem;
  overflow: hidden;
  min-width: 23rem;
}
.sublist::after {
  content: '';
  position: absolute;
  z-index: 2;
  left: 0;
  right: -1rem;
  bottom: 0;
  top: -1rem;
  box-shadow: inset 0.2rem 0.3rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0 2rem;
}
.sublist__item {
  position: relative;
  z-index: 3;
  min-height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.3rem 0 1.5rem;
}
.sublist__link {
  padding: 0.4rem 0.4rem 0.6rem 0;
  font-size: 1.2rem;
  text-decoration: none;
}
.sublist__link._active {
  color: #e64900;
}
@media (hover: hover) and (pointer: fine) {
  .sublist__link:hover {
    text-decoration: underline;
  }
}
.sublist__link-content {
  flex-grow: 1;
}
.sublist__plus {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.2rem;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.5);
  border: 1px solid rgba(128, 128, 128, 0.5);
  font-size: 1.7rem;
  text-decoration: none;
  transition: 0.15s;
}
.sublist__plus._active {
  color: #e64900 !important;
  border-color: #e64900 !important;
  text-decoration: none;
  background: white;
}
.sublist__plus::before {
  content: '';
  position: absolute;
  inset: -0.6rem;
}
@media (hover: hover) and (pointer: fine) {
  .sublist__plus:hover {
    color: #e64900 !important;
    border-color: #e64900 !important;
    text-decoration: none;
    background: white;
  }
}
@media screen and (max-width: 1600px) {
  .side-nav__content {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 1340px) {
  .side-nav__content {
    padding-left: 0;
  }
  .side-nav__link::before,
  .side-nav__link::after {
    border-radius: 0;
  }
}
@media screen and (max-width: 1340px) {
  .sublist,
  .sublist::after {
    border-radius: 0;
  }
}
.user-panel {
  position: relative;
  width: auto;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.user-panel__trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.user-panel__photo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
  background: #d8d8d8;
  margin-right: 0.6rem;
  text-decoration: none;
}
.user-panel__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-panel__ico-placeholder {
  width: 1.4rem;
  height: 1.4rem;
  opacity: 0.25;
}
.user-panel__photo-placeholder {
  opacity: 0.5;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.user-panel__name {
  width: auto;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: 600;
}
.user-panel__name-arrow {
  transform: rotate(90deg);
  width: 0.6rem;
  height: 1rem;
  flex-shrink: 0;
  margin-left: 0.6rem;
  fill: #616161;
  transition: 0.15s;
}
.user-panel._open .user-panel__name-arrow {
  transform: rotate(270deg);
}
.user-panel__dropdown {
  position: absolute;
  top: calc(100% + 1rem);
  z-index: 41;
  right: -1.5rem;
  min-width: calc(100% + 3rem);
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.1);
  border: 1px solid #dedede;
  margin: 1px 0;
  border-radius: 0.3rem;
  transform-origin: center top;
  transition: 0.3s;
}
.user-panel__dropdown-link {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  line-height: 1.2em;
  transition: background-color 0.15s, color 0.15s;
}
.user-panel__dropdown-link:not(:disabled):hover {
  text-decoration: unset;
}
.user-panel__dropdown-link:not(:disabled):hover {
  text-decoration: unset;
}
.user-panel__dropdown-link:focus,
.user-panel__dropdown-link:hover {
  background-color: #f2f2f2;
  color: #8f1b2c;
}
@media (max-width: 749px) {
  .user-panel__name-arrow {
    margin-left: 0;
  }
  .user-panel__dropdown {
    min-width: 12rem;
  }
}
.main-header {
  background: white;
  padding: 0 2rem;
  width: 100%;
  box-shadow: 0 0 0.5rem 0 rgba(206, 206, 206, 0.9);
}
.main-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 1rem 0;
}
.main-header__logo {
  flex-shrink: 0;
  margin-right: auto;
  height: 3rem;
}
.main-header__mail,
.main-header__help,
.main-header__setting-lists {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.main-header__mail:not(:disabled):hover,
.main-header__help:not(:disabled):hover,
.main-header__setting-lists:not(:disabled):hover {
  text-decoration: unset;
}
.main-header__mail:not(:disabled):hover,
.main-header__help:not(:disabled):hover,
.main-header__setting-lists:not(:disabled):hover {
  text-decoration: unset;
}
.main-header__mail svg,
.main-header__help svg,
.main-header__setting-lists svg {
  width: 2.2rem;
  height: 2.2rem;
  fill: #808080;
  transition: 0.15s;
}
.main-header__mail:hover svg,
.main-header__help:hover svg,
.main-header__setting-lists:hover svg {
  fill: #4778ff;
}
.main-header__mail {
  margin-right: 2rem;
}
.main-header__help {
  margin-right: 2rem;
}
.main-header__separator {
  display: block;
  width: 1px;
  height: 2rem;
  background: #dedede;
  margin: 0 2rem;
}
.main-header__menu-btn {
  padding: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0;
  background: transparent;
  margin-left: 1.5rem;
  margin-right: -1rem;
}
.main-header__menu-btn:hover {
  background-color: #dedede;
}
.main-header__menu-btn svg {
  fill: #333333;
  width: 2rem;
}
.main-header__menu-btn:hover svg {
  fill: #478aff;
}
@media (max-width: 1600px) {
  .main-header {
    padding: 0 2rem;
  }
}
@media (max-width: 749px) {
  .main-header {
    padding: 0 1.6rem;
  }
  .main-header__help,
  .main-header__setting-lists {
    margin-right: 3rem;
  }
  .main-header__menu-btn {
    margin-left: 1.5rem;
  }
}
@media (max-width: 450px) {
  .main-header__content {
    gap: 1.6rem;
  }
  .main-header__content > a:not(:first-child),
  .main-header__content button {
    margin: 0;
  }
}
.main-footer {
  padding-left: 2rem;
  background: white;
  border-top: 1px solid #f2f2f2;
}
.main-footer__content {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 4rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.main-footer__development {
  margin: 0;
  font-weight: 600;
  text-align: center;
}
.main-footer__development a {
  font: inherit;
  color: inherit;
  text-decoration: none;
}
@media (max-width: 1340px) {
  .main-footer {
    padding-left: 0;
  }
}
.search {
  display: flex;
  border-radius: 0.3rem;
  width: 22rem;
  border: 1px solid #b8b8b8;
  transition: 0.15s;
}
@media screen and (max-width: 749px) {
  .search {
    margin-right: 0 !important;
    width: 100% !important;
  }
}
.search._white {
  background-color: white;
}
.search._light {
  border-color: #dedede;
}
.search__input-wrap {
  display: flex;
  width: 50%;
  flex-grow: 1;
  overflow: hidden;
}
.search__ico {
  align-self: center;
  width: 1.8rem;
  height: 1.8rem;
  fill: #dedede;
  margin-left: 1rem;
  flex-shrink: 0;
}
.search__input {
  border: 0;
  border-radius: 0;
  width: 50%;
  flex-grow: 1;
  background: transparent;
}
.search__btn {
  background: transparent;
  border-radius: 0;
  padding: 0.2rem;
  min-height: 0;
  border: 0;
  width: 2.9rem;
  overflow: visible;
  flex-shrink: 0;
  transition: 0.15s;
}
.search__btn:hover {
  background-color: transparent;
}
.search__btn svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: #b8b8b8;
}
.search__btn:hover svg {
  fill: #14a75d;
}
.search__btn._cancel:hover svg {
  fill: #aa0300;
}
.search__btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(157, 157, 157, 0.5), rgba(255, 255, 255, 0));
}
.search._short._closed {
  width: 3.1rem;
}
.search._short._closed .search__btn::before {
  opacity: 0;
}
.search._short .search__input-wrap {
  width: 0;
}
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1.5rem 2rem;
  box-shadow: inset 0 -1px 0.2rem rgba(0, 0, 0, 0.1);
}
.page-header__title {
  width: auto;
  margin: 0 3rem 0 0;
  font-size: 2.6rem;
  font-weight: 600;
}
.page-header__title:last-child {
  margin-right: 0;
}
.page-header__title-col {
  display: flex;
}
.page-header__search {
  width: 30rem;
}
.page-header__title-col {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3rem;
}
.page-header__title-col:last-child {
  margin-right: 0;
}
.page-header__add-btn {
  padding: 0 0.4rem;
  width: 3rem;
  min-height: 3rem;
  font-size: 2.4rem;
  line-height: 1em;
}
.page-header__status {
  margin-right: auto;
  flex-shrink: 1;
  max-width: calc(100% - 5rem);
}
.page-header__status .dropdown__dropdown {
  width: 14rem;
}
.page-header__status .dropdown__btn {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  min-height: 2.8rem;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  font-weight: 600;
  color: #1f1f1f;
}
.page-header__status .dropdown__arrow svg {
  fill: #333333 !important;
}
@media (max-width: 1600px) {
  .page-header {
    padding-right: 3rem;
  }
}
@media (max-width: 749px) {
  .page-header {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    flex-wrap: wrap;
    box-shadow: none;
  }
  .page-header__title:not(:nth-last-child(2)):not(:last-child) {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .page-header .dropdown {
    max-width: 80%;
  }
  .page-header__title {
    font-size: 2.4rem;
  }
  .page-header__title-col {
    display: block;
    width: 100%;
  }
  .page-header__title-col .page-header__title {
    margin-right: 0;
  }
  .page-header__search {
    margin-top: 1rem;
    width: 100%;
    background-color: white;
  }
}
.mail-modal {
  position: fixed;
  z-index: 61;
  right: 3rem;
  bottom: 0;
  width: 52rem;
  max-width: calc(100% - 3rem);
  background: white;
  border-radius: 0.3rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.2);
}
.mail-modal__head {
  display: flex;
  padding: 1.3rem 1.5rem;
  background-color: #333333;
  border-radius: 0.3rem 0.3rem 0 0;
  cursor: pointer;
  color: white;
}
.mail-modal__head-title {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  padding-right: 1.5rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.mail-modal__head-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: 0.15s;
}
.mail-modal__head-btn:not(:disabled):hover {
  text-decoration: unset;
}
.mail-modal__head-btn:not(:disabled):hover {
  text-decoration: unset;
}
.mail-modal__head-btn:last-child {
  margin-right: 0;
}
.mail-modal__head-btn svg {
  width: 1.4rem;
  height: 1.4rem;
  opacity: 0.5;
  fill: white;
}
.mail-modal__head-btn._toggle svg {
  width: 1.2rem;
  height: 1.2rem;
}
.mail-modal__head-btn._close svg {
  width: 1.2rem;
  height: 1.2rem;
}
.mail-modal__head-btn:hover {
  opacity: 0.5;
}
.mail-modal__content {
  padding: 2.5rem 1.5rem 2rem;
  overflow-y: auto;
  max-height: calc(100vh - (5.5rem + 4.6rem + 4.9rem) - 5rem);
}
.mail-modal__input-wrap {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}
.mail-modal__input-wrap:last-child {
  margin-bottom: 0;
}
.mail-modal__input-wrap .editor__content {
  padding: 1rem;
}
.mail-modal__input-wrap .ProseMirror {
  min-height: 5.1rem;
}
.mail-modal__input {
  width: 10rem;
  flex-grow: 2;
}
.mail-modal__input-delete-btn.btn,
.mail-modal__input-add-btn.btn {
  flex-shrink: 0;
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.mail-modal__input-delete-btn.btn:hover,
.mail-modal__input-add-btn.btn:hover {
  background-color: #00bb5d;
}
.mail-modal__input-delete-btn.btn svg,
.mail-modal__input-add-btn.btn svg {
  width: 1rem;
  height: 1rem;
}
.mail-modal__input-delete-btn.btn:hover svg,
.mail-modal__input-add-btn.btn:hover svg {
  fill: white;
}
.mail-modal__input-delete-btn.btn svg {
  width: 1.2rem;
  height: 1.2rem;
}
.mail-modal__input-delete-btn.btn:hover {
  background-color: #8f1b2c;
}
.mail-modal__files {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.mail-modal__files-list-wrap {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.mail-modal__files-list-title {
  margin: 2rem 0 0;
  font-size: 1.4rem;
}
.mail-modal__files-list-item {
  min-width: 30rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  padding: 0.2rem 1rem;
  margin-top: 1rem;
}
.mail-modal__files-list-item-text {
  max-width: calc(100% - 2.8rem);
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 1.2rem;
}
.mail-modal__files-list-item-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  flex-shrink: 0;
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.15s;
}
.mail-modal__files-list-item-btn:not(:disabled):hover {
  text-decoration: unset;
}
.mail-modal__files-list-item-btn:not(:disabled):hover {
  text-decoration: unset;
}
.mail-modal__files-list-item-btn:hover {
  background-color: #8f1b2c;
}
.mail-modal__files-list-item-btn svg {
  width: 0.8rem;
  height: 0.8rem;
  fill: white;
}
.mail-modal__footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 1.5rem;
}
.mail-modal__footer::before {
  content: '';
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0.8rem;
  bottom: 100%;
  height: 1.5rem;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}
.mail-modal__footer-btn._delete:hover {
  background-color: #8f1b2c;
}
.mail-modal__footer-btn._delete:hover svg {
  fill: white;
}
.mail-modal._short {
  width: 20rem;
}
.mail-modal._short .mail-modal__head {
  padding: 1rem 1.5rem;
}
@media (max-width: 749px) {
  .mail-modal {
    right: 0;
    max-width: 100%;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}
.pagination__nav {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.71428571em;
  height: 1.71428571em;
  color: rgba(31, 31, 31, 0.6);
  transition: color 0.15s, opacity 0.15s;
  text-decoration: none;
}
.pagination__nav:not(:disabled):hover {
  text-decoration: unset;
}
.pagination__nav:not(:disabled):hover {
  text-decoration: unset;
}
.pagination__nav._prev {
  transform: rotate(180deg);
}
.pagination__nav._disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.pagination__nav svg {
  width: 0.35714286em;
  height: 0.64285714em;
}
.pagination__nav:hover {
  color: #14a75d;
}
.pagination__pages {
  min-width: 2.85714286em;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.pagination__dropdown {
  position: absolute;
  top: calc(100% + 0.42857143em);
  left: calc(50% - 2.14285714em);
  width: 4.28571429em;
  z-index: 41;
  transition: 0.3s;
}
.pagination__dropdown-content {
  display: flex;
  padding: 0.14285714em;
  width: 100%;
  overflow: hidden;
  border-radius: 0.3rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.15);
  background: white;
}
.pagination__dropdown-arrow {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2rem;
  height: 1rem;
  overflow: hidden;
  pointer-events: none;
}
.pagination__dropdown-arrow::before {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  transform-origin: center;
  background: white;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.15);
}
.pagination__input-to {
  border: 0;
  background: transparent;
  width: 50%;
  padding: 0.35714286em;
  text-align: center;
  font-size: 0.85714286em;
}
.pagination__btn-to {
  min-height: 0;
  padding: 0.14285714em;
  border-radius: 0 0.2rem 0.2rem 0;
  width: 50%;
  font-size: 0.85714286em;
}
.person-info {
  padding: 1rem 2.5rem;
}
.person-info._no-padding {
  padding: 0;
}
.person-info--separate-content {
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
}
.person-info__no-customer {
  position: absolute;
  z-index: 21;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.person-info__no-customer-title {
  padding: 1.4rem 2rem;
  border-radius: 3rem;
  background: #f2f2f2;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.person-info__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
}
.person-info__header._inner {
  padding-left: 0;
  padding-right: 0;
}
.person-info__profile-wrap {
  display: flex;
}
.person-info__header-content {
  display: flex;
}
.person-info__photo-wrap {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  background: #d8d8d8;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 1.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.person-info__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
  transition: opacity 0.15s;
}
.person-info__photo:hover {
  opacity: 0.8;
}
.person-info__photo-placeholder {
  opacity: 0.5;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.person-info__profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
}
.person-info._inactive .person-info__profile,
.person-info._deleted .person-info__profile {
  opacity: 0.6;
}
.person-info__company-name {
  margin: 0 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #808080;
  text-transform: uppercase;
}
.person-info__name {
  margin: 0;
  font-size: 1.4rem;
}
.person-info__location {
  margin: 0 0 0 2.5rem;
}
.person-info__account-number {
  width: 20%;
  flex-grow: 1;
  margin: 0 2rem 0 2rem;
}
.person-info__account-number-val {
  font-weight: 600;
}
.person-info__account-number .checkbox {
  margin-right: 2rem;
}
.person-info__vendor-categories {
  width: 20%;
  flex-grow: 1;
  margin: 0 2rem 0 2rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.person-info__status {
  display: flex;
  align-items: center;
  margin: 0 2rem 0 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #b8b8b8;
}
.person-info__status::before {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  display: inline-block;
  border-radius: 50%;
  background: currentColor;
  vertical-align: 1px;
  margin-right: 0.6rem;
}
.person-info__header._inner .person-info__status {
  margin-right: 0;
}
.person-info._active .person-info__status {
  color: #14a75d;
}
.person-info._inactive .person-info__status {
  color: #e7a811;
}
.person-info._deleted .person-info__status {
  color: #8f1b2c;
}
.person-info__drop-menu {
  margin-left: 1rem;
}
.person-info__section {
  padding: 1.8rem 0 0;
  border-top: 1px solid #dedede;
}
.person-info__section:first-child {
  border-top: 0;
}
.person-info__toggle-sections .person-info__section:first-child {
  border-top: 1px solid #dedede;
}
.person-info__toggle-btn {
  background: white;
  margin-top: -1px;
  border-radius: 0 0 0.3rem 0.3rem;
  border-color: #dedede;
  border-top: 0;
  align-self: center;
  min-width: 8rem;
  color: #808080;
  text-transform: lowercase;
}
.person-info__toggle-btn:hover {
  background-color: #dedede;
  color: #333333;
}
.person-info__toggle-btn svg {
  width: 0.4rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: #808080;
}
.person-info__toggle-btn:hover svg {
  fill: #333333;
}
.person-info._short-info .person-info__toggle-btn svg {
  transform: rotate(270deg);
}
.person-info__contact-wrap {
  position: relative;
  margin-bottom: 1.8rem;
  min-width: 200px;
}
.person-info__contact-name {
  margin: 0 0 0.6rem;
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
  font-weight: 400;
}
.person-info__contact-name svg {
  position: relative;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  fill: #14a75d;
  flex-shrink: 0;
}
.person-info__contact,
.person-info__contact-table {
  margin: 0 0 0.6rem;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 600;
}
.person-info__contact:last-child,
.person-info__contact-table:last-child {
  margin-bottom: 0;
}
.person-info__contact a,
.person-info__contact-table a {
  font: inherit;
  color: inherit;
  text-decoration: none;
}
.person-info__contact {
  display: flex;
}
.person-info__contact._error {
  color: #aa0300;
}
.person-info__contact._accent {
  align-items: center;
  color: #ff7813;
}
.person-info__contact._middle {
  align-items: center;
}
.person-info__contact > span:first-child {
  margin-right: 0.3rem;
}
.person-info__contact-icos {
  align-self: center;
  display: flex;
  align-items: center;
}
.person-info__contact-ico {
  display: flex;
  margin-left: 0.5rem;
}
.person-info__contact-ico svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: #b8b8b8;
}
.person-info__contact-ico._viber svg {
  fill: #665cac;
}
.person-info__contact-ico._whatsapp svg {
  fill: #25d366;
}
.person-info__link {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: 0.15s;
}
.person-info__link:not(:disabled):hover {
  text-decoration: unset;
}
.person-info__link:not(:disabled):hover {
  text-decoration: unset;
}
.person-info__link:hover {
  color: #4778ff;
}
.person-info__contact-table {
  width: 100%;
}
.person-info__contact-table td {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}
.person-info__contact-table td:first-child {
  padding-left: 0;
  font-weight: 400;
}
.person-info__contact-table td:last-child {
  padding-right: 0;
}
.person-info__contact-table tr:last-child td {
  padding-bottom: 0.8rem;
}
.person-info__contact-table tbody:last-child tr:last-child td {
  padding-bottom: 0;
}
.person-info__contact-table tbody._error {
  color: #aa0300;
}
.person-info__contact-table tbody._accent {
  color: #ff7813;
}
.person-info._inactive .person-info__contact,
.person-info._deleted .person-info__contact,
.person-info._inactive .person-info__contact-name,
.person-info._deleted .person-info__contact-name,
.person-info._deleted .person-info__contact-table,
.person-info._inactive .person-info__contact-table {
  opacity: 0.6;
}
.person-info__map {
  position: absolute;
  top: -1.8rem;
  left: 0;
  width: calc(100% + 1.5rem);
  height: calc(100% + 3.6rem);
}
.person-info__stamp-wrap {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  max-width: 80rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.person-info__stamp {
  width: 100%;
  height: 70%;
  opacity: 0.5;
  transform: rotate(-15deg);
  flex-shrink: 0;
  display: flex;
}
.person-info__stamp svg {
  width: 100%;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-weight: bold;
  font-size: inherit;
  fill: #fff;
  stroke: #000000;
  stroke-width: 0.5;
  stroke-opacity: 0.4;
}
.person-info__birthday-icon {
  margin-right: 0.8rem;
}
.person-info__birthday-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #ff7813;
}
.person-info__info-for-documents {
  font-size: 1.2rem;
  font-weight: 600;
}
.person-info__mobile-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.person-info__sub-accounts {
  width: 100%;
}
.person-info__sub-accounts tr:not(._empty) {
  cursor: pointer;
  transition: background-color 0.15s;
}
.person-info__sub-accounts tr:not(._empty):hover {
  background-color: #f2f2f2;
}
.person-info__sub-accounts td._actions {
  width: 5.4rem;
}
@media (max-width: 749px) {
  .person-info {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .person-info--separate-content {
    height: auto;
    overflow: visible;
  }
  .person-info__header {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    display: block;
  }
  .person-info__photo-wrap {
    margin-top: 0;
    margin-bottom: 0;
    width: 4.8rem;
    height: 4.8rem;
  }
  .person-info__account-number {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.8rem;
  }
  .person-info__vendor-categories {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.8rem;
  }
  .person-info__profile-wrap {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  .person-info__header-content {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-left: 0;
    border-right: 0;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
  }
  .person-info__status {
    margin-right: auto;
  }
}
.customers-list {
  position: relative;
  background: white;
}
.customers-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #dedede;
}
.customers-list._short .customers-list__header {
  top: 0;
}
.customers-list__drop-menu {
  margin-right: 1rem;
}
.customers-list__drop-menu .dropdown__dropdown-inner {
  width: 14rem;
}
.customers-list__search {
  width: 21.8rem;
  margin-right: 1rem;
}
.customers-list__search-toggle {
  background: transparent;
  margin-right: 1rem;
  border: 1px solid #dedede;
}
.customers-list__search-toggle svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: rgba(0, 0, 0, 0.65);
}
.customers-list__search-toggle._open,
.customers-list__search-toggle:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.customers-list__search-toggle._open svg,
.customers-list__search-toggle:hover svg {
  fill: white;
}
.customers-list__main-search-short-wrap {
  padding: 2rem;
  border-bottom: 1px solid #dedede;
}
.customers-list__main-search-short {
  width: 100%;
}
.customers-list__sort {
  max-width: 60%;
  width: 18rem;
  margin-left: auto;
}
.customers-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.customers-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.customers-list__head-btn {
  margin-right: 1rem;
}
.customers-list__table-wrap {
  position: relative;
}
.customers-list__table {
  width: 100%;
  margin: 0;
}
.customers-list__table thead {
  position: sticky;
  top: 11.6rem;
}
.customers-list__table th {
  background: #f2f2f2;
}
.customers-list__table td:first-child {
  width: 5.5rem;
}
.customers-list__table td a {
  text-decoration: none;
}
.customers-list__customer {
  cursor: pointer;
  transition: 0.15s;
}
.customers-list__customer:hover {
  background-color: #f2f2f2;
}
.customers-list._short .customers-list__customer._selected {
  background-color: #e3ebff;
}
.customers-list__customer-profile {
  display: flex;
  align-items: center;
}
.customers-list__photo-wrap {
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.customers-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customers-list__profile-info {
  width: 50%;
  flex-grow: 1;
}
.customers-list._short {
  border-right: 1px solid #dedede;
}
.customers-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customers-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.customers-list__info {
  padding-top: 1px;
}
.customers-list__info > * {
  margin-bottom: 0.4rem;
}
.tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  background: #f2f2f2;
  border-bottom: 0.2rem solid #e7e7e7;
  font-size: 1.4rem;
}
@media (max-width: 1100px) {
  .tabs {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
}
.tabs._white {
  background: white;
}
.tabs__toggle-btn.btn {
  align-self: flex-end;
  margin-right: 1rem;
  border-radius: 0.3rem 0.3rem 0 0;
  border-bottom: 0;
  min-height: 4rem;
}
.tabs__toggle-btn.btn:hover {
  border-color: #dedede;
}
.tabs__toggle-btn.btn svg {
  transform: rotate(90deg);
  fill: #478aff;
}
.tabs__toggle-btn.btn:hover svg {
  fill: #478aff;
}
.tabs._open .tabs__toggle-btn.btn svg {
  transform: rotate(270deg);
}
.tabs__tab {
  position: relative;
  padding: 0.9rem 1rem;
  margin-right: 1rem;
  border: 0;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 600;
}
.tabs__tab:last-child {
  margin-right: 0;
}
.tabs__tab::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 0.2rem;
  background: #14a75d;
  transform: translateX(-50%) scaleX(0);
  transition: 0.15s;
}
.tabs__tab:disabled {
  cursor: not-allowed;
}
.tabs__tab:not(:disabled):hover::after,
.tabs__tab:not(:disabled)._active::after {
  transform: translateX(-50%) scaleX(1);
}
.tabs._hide .tabs__tab::after {
  opacity: 0.5;
}
.tabs__plus.btn._small {
  align-self: center;
  margin-left: -1rem;
  margin-right: 1rem;
}
.tabs__plus.btn._small:last-child {
  margin-right: 0;
}
.tabs__plus.btn._small:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.tabs__plus.btn._small svg {
  width: 1rem;
  height: 1rem;
}
.tabs__plus.btn._small:hover svg {
  fill: white;
}
.tabs__btn-wrap {
  align-self: center;
  padding: 0 1rem;
}
.tabs__btn-wrap .btn {
  text-transform: none;
}
.tabs__carousel {
  display: flex;
  flex: 1;
  width: 0;
  padding: 0 1rem;
  margin: 0 -1rem -0.2rem;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), black 1rem, black 1rem, black calc(100% - 1rem), rgba(0, 0, 0, 0) 100%);
  overflow-y: hidden;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.tabs__carousel::-webkit-scrollbar {
  display: none !important;
}
.tabs__carousel-container {
  display: flex;
  width: 100%;
}
.tabs__carousel-list {
  display: flex;
  width: 100%;
}
.tabs__carousel-item {
  padding-bottom: 0.2rem;
  display: flex;
  margin-right: 1rem;
  flex-shrink: 0;
}
.tabs__carousel-item:last-child {
  margin-right: 0;
}
.file-show {
  position: relative;
  line-height: 0;
  cursor: auto;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}
.file-show:not(._clickable) {
  cursor: pointer;
}
.file-show:not(._clickable) > * {
  pointer-events: none;
}
.file-show._rect {
  padding-top: 100%;
}
.file-show__file {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.file-show__file:not(:disabled):hover {
  text-decoration: unset;
}
.file-show__file:not(:disabled):hover {
  text-decoration: unset;
}
.file-show__file._placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.file-show__file .file-show__file-ico,
.file-show__file > svg {
  width: 13rem;
  height: 16rem;
  z-index: 2;
  object-fit: cover;
}
.file-show._rect .file-show__file {
  position: absolute;
  left: 0;
  top: 0;
}
.file-show svg,
.file-show__file-ico {
  width: 4rem;
  height: 4rem;
  fill: #808080;
}
.file-show__name {
  padding-top: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  z-index: 2;
}
.file-show__name svg {
  width: 1.2em !important;
  height: 1.2em !important;
  fill: currentColor !important;
  color: currentColor !important;
  vertical-align: middle;
  margin-top: -0.2em;
}
.file-show__type-ico {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f14e16;
  text-transform: uppercase;
}
.file-show__type-ico svg {
  width: 40%;
  height: 40%;
  fill: currentColor;
  background: white;
  border-radius: 3px;
  opacity: 0.8;
  flex-shrink: 0;
}
.file-show__type-ico span {
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 600;
  background: white;
  flex-shrink: 0;
  padding: 0.1em;
  border-radius: 3px;
  opacity: 0.8;
}
.person-files .dropdown__btn {
  width: 3.4rem;
  padding: 0.4rem 0.4rem 0 0;
  background: #4778ff;
  border: 0;
}
.person-files .dropdown__btn:hover {
  background-color: #478aff;
}
.person-files .person-files__btn-ico {
  width: 0.8rem;
  height: 1.7rem;
  margin: auto !important;
  fill: white;
}
.person-files__amount {
  position: absolute;
  z-index: 4;
  top: 0.4rem;
  right: 0.4rem;
  border-radius: 1rem;
  height: 1.5rem;
  min-width: 1.5rem;
  background: white;
  padding: 0.2rem 0.2rem 0;
  font-size: 1.2rem;
  line-height: 1em;
  color: #4778ff;
  text-align: center;
}
.person-files__main {
  width: 34rem;
  padding: 1.5rem 2rem;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
}
.person-files__list {
  height: 40%;
  flex: 1 1 auto;
  overflow-y: auto;
  margin-right: -2rem;
  padding-right: 2rem;
}
.person-files__file {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding: 1.6rem 0;
}
.person-files__file:first-child {
  padding-top: 0;
}
.person-files__file-ico-wrap {
  position: relative;
  width: 3rem;
  height: 4rem;
  margin-right: 1.4rem;
  flex-shrink: 0;
}
.person-files__file-ico-wrap svg {
  width: 100%;
  height: 100%;
  fill: #808080;
}
.person-files__file-ico-wrap::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}
.person-files__file-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: zoom-in;
}
.person-files__file-content {
  width: 50%;
  flex-grow: 1;
}
.person-files__file-title-wrap {
  display: flex;
  align-items: center;
}
.person-files__file-title {
  width: auto;
  margin: 0 0.5rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 1.4rem;
  font-weight: 600;
}
.person-files__file-title a {
  font: inherit;
  text-decoration: none;
}
.person-files__file-title-input {
  width: 50%;
  flex-grow: 1;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.person-files__file-save,
.person-files__file-cancel {
  margin-left: 0.5rem;
}
.person-files__file-save svg,
.person-files__file-cancel svg {
  width: 1rem;
  height: 1rem;
}
.person-files__file-edit {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  flex-shrink: 0;
  padding: 0.2rem 0.4rem 0;
  cursor: pointer;
}
.person-files__file-edit:not(:disabled):hover {
  text-decoration: unset;
}
.person-files__file-edit:not(:disabled):hover {
  text-decoration: unset;
}
.person-files__file-edit svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #4778ff;
}
.person-files__file-edit:hover svg {
  fill: #478aff;
}
.person-files__file-weight {
  margin: 0;
  color: #808080;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.person-files__file-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.person-files__file-action {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  display: flex;
  margin: 0.6rem 2rem 0 0;
  cursor: pointer;
  text-decoration: none;
  color: #14a75d;
}
.person-files__file-action:not(:disabled):hover {
  text-decoration: unset;
}
.person-files__file-action:not(:disabled):hover {
  text-decoration: unset;
}
.person-files__file-action:last-child {
  margin-right: 0;
}
.person-files__file-action svg {
  width: 1.4rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  fill: #14a75d;
}
.person-files__file-action._delete {
  color: #8f1b2c;
}
.person-files__file-action._delete svg {
  fill: #8f1b2c;
}
.person-files__file-action:hover {
  color: #00bb5d;
  text-decoration: underline;
}
.person-files__file-action:hover svg {
  fill: #00bb5d;
}
.person-files__file-action._delete:hover {
  color: #aa0300;
}
.person-files__file-action._delete:hover svg {
  fill: #aa0300;
}
.person-files__upload-wrap {
  flex-shrink: 0;
  text-align: center;
}
.person-files__upload-note {
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.person-files__upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  padding: 2rem;
  cursor: pointer;
  transition: 0.15s;
}
.person-files__upload input {
  display: none;
}
.person-files__upload svg {
  width: 2.4rem;
  height: 2.4rem;
  opacity: 0.4;
  margin-bottom: 1rem;
  transition: 0.15s;
}
.person-files__upload:hover {
  border-color: #cdcccc;
}
.person-files__upload:hover svg {
  opacity: 0.6;
}
.person-files__error {
  margin-bottom: 1rem;
}
.customer-tabs._height {
  height: auto;
}
.customer-tabs__head-wrap {
  position: relative;
}
.customer-tabs__head-wrap::after {
  content: '';
  height: 0.2rem;
  background-color: #e7e7e7;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
}
.customer-tabs__head {
  padding: 0 2rem;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), black 2rem, black 2rem, black calc(100% - 2rem), rgba(0, 0, 0, 0) 100%);
  position: relative;
  z-index: 5;
  overflow-y: hidden;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.customer-tabs__head::-webkit-scrollbar {
  display: none !important;
}
.customer-tabs__head-container {
  display: flex;
  width: 100%;
}
.customer-tabs__head-inner {
  display: flex;
  width: 100%;
}
.customer-tabs__tab-item {
  margin-right: 1rem;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 0.2rem;
}
.customer-tabs__tab-item:last-child {
  margin-right: 0;
}
.customer-tabs__tab {
  position: relative;
  padding: 0.9rem 1rem;
  text-decoration: none !important;
  font-weight: 600;
  flex-shrink: 0;
}
.customer-tabs__tab::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 0.2rem;
  background: #14a75d;
  transform: translateX(-50%) scaleX(0);
  z-index: 5;
  transition: 0.15s;
}
.customer-tabs__tab:hover::after,
.customer-tabs__tab._active::after {
  transform: translateX(-50%) scaleX(1);
}
.customer-tabs__plus.btn._small {
  align-self: center;
}
.customer-tabs__plus.btn._small:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.customer-tabs__plus.btn._small svg {
  width: 1rem;
  height: 1rem;
}
.customer-tabs__plus.btn._small:hover svg {
  fill: white;
}
@media (max-width: 980px) {
  .customer-tabs__head {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), black 1.6rem, black 1.6rem, black calc(100% - 1.6rem), rgba(0, 0, 0, 0) 100%);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .customer-tabs__tab-item {
    margin-right: 1.6rem;
  }
  .customer-tabs__tab {
    padding-left: 0;
    padding-right: 0;
  }
  .customer-tabs__plus.btn._small {
    margin-left: 0.5rem;
  }
}
@media (max-width: 749px) {
  .customer-tabs__head-wrap {
    position: sticky;
    top: 5rem;
    background-color: #f9f9fb;
    z-index: 10;
  }
}
.customer-notes {
  position: relative;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-notes__scroll-block {
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
}
.customer-notes__alert {
  flex-shrink: 0;
  margin: 1rem 2rem;
}
.customer-notes__table {
  width: 100%;
  margin: 0;
}
.customer-notes__table th:nth-child(1),
.customer-notes__table td:nth-child(1) {
  width: 15.5rem;
}
.customer-notes__table th:nth-child(3),
.customer-notes__table td:nth-child(3) {
  width: 20%;
}
.customer-notes__table th:last-child,
.customer-notes__table td:last-child {
  width: 10.1rem;
  text-align: right;
}
.customer-notes__scroll-block tr:last-child td {
  border-bottom: 0;
}
.customer-notes__scroll-pusher {
  padding: 0;
  width: 0.8rem !important;
}
.customer-notes a {
  text-decoration: none;
}
.customer-notes__input {
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  border-radius: 0.3rem;
}
.customer-notes__input .editr--toolbar {
  display: none;
}
.customer-notes__input .editr--content {
  min-height: 3.2rem;
}
.customer-notes__input .editor__content {
  padding: 0.7rem 1rem 0.8rem;
}
.customer-notes__input .ProseMirror {
  min-height: auto;
}
.customer-notes__note > div {
  margin: 0;
}
.customer-notes__btn {
  margin-right: 0.5rem;
}
.customer-notes__btn:last-child {
  margin-right: 0;
}
.customer-notes__btn-save {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
@media (max-width: 980px) {
  .customer-notes__btn {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .customer-notes__btn:last-child {
    margin-bottom: 0;
  }
  .customer-notes__table {
    display: block;
  }
  .customer-notes__table tbody,
  .customer-notes__table thead {
    display: block;
  }
  .customer-notes__table thead {
    background-color: #dedede;
  }
  .customer-notes__table tr {
    display: flex;
  }
  .customer-notes__table th:last-child {
    font-size: 0;
  }
  .customer-notes__table td,
  .customer-notes__table th {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .customer-notes__table td:first-child,
  .customer-notes__table th:first-child {
    width: 24%;
    flex-shrink: 0;
    max-width: 8.7rem;
    padding-right: 0;
  }
  .customer-notes__table td:nth-child(2),
  .customer-notes__table th:nth-child(2) {
    width: 40%;
    flex-grow: 1;
  }
  .customer-notes__table td:nth-child(3),
  .customer-notes__table th:nth-child(3) {
    width: 18%;
  }
  .customer-notes__table td:last-child,
  .customer-notes__table th:last-child {
    width: auto;
    flex-basis: 5rem;
  }
  .customer-notes__table td:first-child:last-child,
  .customer-notes__table th:first-child:last-child {
    flex-basis: 100%;
    max-width: none;
    width: 100%;
  }
  .customer-notes > .customer-notes__table tbody tr td {
    width: auto;
  }
  .customer-notes > .customer-notes__table tbody tr td:first-child,
  .customer-notes > .customer-notes__table tbody tr td:nth-child(3) {
    display: none;
  }
  .customer-notes > .customer-notes__table tbody tr td:nth-child(2) {
    padding-left: 1.6rem;
  }
  .customer-notes > .customer-notes__table tbody tr td:last-child {
    width: 8rem;
  }
}
@media (max-width: 749px) {
  .customer-notes {
    height: auto;
  }
  .customer-notes__scroll-block {
    height: auto;
    overflow: visible;
  }
  .customer-notes__row {
    position: relative;
    flex-wrap: wrap;
    padding: 1em;
    border-bottom: 1px solid #dedede;
  }
  .customer-notes__table-head {
    display: none !important;
  }
  .customer-notes__date {
    max-width: unset !important;
    width: 100% !important;
    border-bottom: 0;
    padding: 0 !important;
  }
  .customer-notes__note {
    order: 4;
    width: 100% !important;
    padding: 0;
    border-bottom: 0;
    margin-top: 0.5em;
  }
  .customer-notes__agent {
    width: 100% !important;
    order: 2;
    border-bottom: 0;
    padding: 0;
    font-weight: 700;
  }
  .customer-notes__menu {
    position: absolute;
    right: 0.2em;
    top: 0.2em;
    border: none;
  }
}
.customer-commodities {
  position: relative;
  background: white;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-commodities__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.8rem 2rem 1rem;
}
.customer-commodities__filter {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}
.customer-commodities__filter-items {
  margin-top: -1rem;
  margin-left: -1rem;
}
.customer-commodities__filter-item {
  background: transparent;
  border: 1px solid #dedede;
  min-height: 3rem;
  margin: 1rem 0 0 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.customer-commodities__filter-item:hover {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.customer-commodities__filter-item:last-child {
  margin-right: 0;
}
.customer-commodities__filter-item._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.customer-commodities__filter-item > span:last-child {
  margin-left: 0.4rem;
}
.customer-commodities__add-commodity {
  min-height: 3rem;
  min-width: 3rem;
  padding: 0;
  margin-left: 3rem;
  font-size: 2rem;
  line-height: 1em;
}
.customer-commodities__content-wrap {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
}
.customer-commodities__table-wrap {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.customer-commodities__scroll-block {
  flex-grow: 1;
}
.customer-commodities__table {
  margin: 0;
  display: block;
}
.customer-commodities__table thead,
.customer-commodities__table tbody {
  display: block;
}
.customer-commodities__table .hide-particle__placeholder:after,
.customer-commodities__table .hide-particle__placeholder:before {
  background: transparent !important;
}
.customer-commodities__table tr {
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
}
.customer-commodities__table tr:hover {
  background-color: #f2f2f2;
}
.customer-commodities__table th,
.customer-commodities__table td {
  flex-shrink: 0;
}
.customer-commodities__table th {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.customer-commodities__table th:first-child,
.customer-commodities__table td:first-child {
  flex-grow: 1;
  width: 22rem;
}
.customer-commodities__table th:nth-child(2),
.customer-commodities__table td:nth-child(2) {
  width: 18rem;
}
.customer-commodities__table th:nth-child(2) .search-table-col,
.customer-commodities__table td:nth-child(2) .search-table-col {
  width: 16rem;
}
.customer-commodities__table th:nth-child(3),
.customer-commodities__table td:nth-child(3) {
  width: 22rem;
}
.customer-commodities__table th:nth-child(3) .search-table-col,
.customer-commodities__table td:nth-child(3) .search-table-col {
  width: 20rem;
}
.customer-commodities__table th._status-location,
.customer-commodities__table td._status-location {
  width: 22rem;
}
.customer-commodities__table th._status-location .search-table-col,
.customer-commodities__table td._status-location .search-table-col {
  width: 20rem;
}
.customer-commodities__table th._actions,
.customer-commodities__table td._actions {
  width: 8.4rem;
  text-align: right;
}
.customer-commodities__table th._actions .search-table-col,
.customer-commodities__table td._actions .search-table-col {
  width: 6.4rem;
}
.customer-commodities__scroll-block .customer-commodities__table th._actions,
.customer-commodities__scroll-block .customer-commodities__table td._actions {
  width: 8.4rem;
}
.customer-commodities a {
  text-decoration: none;
}
.customer-commodities__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 16rem;
}
.customer-commodities__help-list li {
  margin: 0.6rem 0;
}
.customer-commodities__status {
  margin-bottom: 0.4rem;
}
.customer-commodities__cel-row-wrap {
  border-bottom: 1px dashed #dedede;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.customer-commodities__cel-row-wrap:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.customer-commodities__cel-row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem 0 0.5rem -0.5rem;
}
.customer-commodities__cel-row:last-child {
  margin-bottom: 0;
}
.customer-commodities__cel-row .customer-commodities__cel-row-item {
  margin: 0.5rem 0 0 0.5rem;
}
@media (max-width: 1920px) {
  .customer-commodities__table th._status-location,
  .customer-commodities__table td._status-location {
    width: 16rem;
  }
  .customer-commodities__table th._status-location .search-table-col,
  .customer-commodities__table td._status-location .search-table-col {
    width: 14rem;
  }
  .customer-commodities__scroll-block .customer-commodities__table th._status-location,
  .customer-commodities__scroll-block .customer-commodities__table td._status-location {
    width: 16rem;
  }
}
@media (max-width: 1600px) {
  .customer-commodities__table th:nth-child(2),
  .customer-commodities__table td:nth-child(2) {
    width: 15rem;
  }
  .customer-commodities__table th:nth-child(2) .search-table-col,
  .customer-commodities__table td:nth-child(2) .search-table-col {
    width: 13rem;
  }
  .customer-commodities__table th:nth-child(3),
  .customer-commodities__table td:nth-child(3) {
    width: 18rem;
  }
  .customer-commodities__table th:nth-child(3) .search-table-col,
  .customer-commodities__table td:nth-child(3) .search-table-col {
    width: 16rem;
  }
  .customer-commodities__photo-wrap {
    display: none;
  }
}
@media (max-width: 980px) {
  .customer-commodities__head {
    align-items: center;
    padding: 1.6rem 1.6rem 2rem;
    flex-wrap: wrap;
  }
  .customer-commodities__select-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .customer-commodities__select-wrap .select {
    flex-grow: 1;
  }
  .customer-commodities__search {
    width: 100%;
    margin-top: 1rem;
    background-color: white;
  }
  .customer-commodities__content-mob {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .customer-commodities__content-mob .list-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .customer-commodities__list-mob {
    position: relative;
  }
  .customer-commodities__list-mob-item {
    padding: 1.1rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    margin-bottom: 0.8rem;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .customer-commodities__list-mob-item:last-child {
    margin-bottom: 0;
  }
  .customer-commodities__list-mob-item-params {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
  }
  .customer-commodities__list-mob-item-params > div {
    flex-grow: 1;
    width: 40%;
    margin-top: 1rem;
    margin-right: 1rem;
    flex-basis: 28rem;
  }
  .customer-commodities__list-mob-item .customer-commodities__notes-btn {
    position: absolute;
    top: 1.1rem;
    right: 1.1rem;
  }
  .customer-commodities__list-mob-item .customer-commodities__notes-btn ~ .preview-commodity {
    margin-right: 4rem;
  }
}
@media (max-width: 749px) {
  .customer-commodities {
    height: auto;
    background-color: transparent;
  }
  .customer-commodities__content-wrap {
    display: block;
    overflow: visible;
    height: auto;
  }
}
.customer-files {
  position: relative;
  background: white;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-files__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.8rem 2rem 1rem;
}
.customer-files__filter {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}
.customer-files__filter-items {
  margin-top: -1rem;
  margin-left: -1rem;
}
.customer-files__filter-item {
  background: transparent;
  border: 1px solid #dedede;
  min-height: 3rem;
  margin: 1rem 0 0 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.customer-files__filter-item:hover {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.customer-files__filter-item:last-child {
  margin-right: 0;
}
.customer-files__filter-item._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.customer-files__filter-item > span:last-child {
  margin-left: 0.4rem;
}
.customer-files__content-wrap {
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}
.customer-files .files-list__table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}
.customer-files .files-list__cards {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 980px) {
  .customer-files__head {
    align-items: center;
    padding: 1.6rem 1.6rem 2rem;
    flex-wrap: wrap;
  }
  .customer-files__select-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .customer-files__select-wrap .select {
    flex-grow: 1;
  }
  .customer-files__search {
    width: 100%;
    margin-top: 1rem;
    background-color: white;
  }
}
@media (max-width: 749px) {
  .customer-files {
    height: auto;
    background-color: transparent;
  }
  .customer-files__content-wrap {
    display: block;
    overflow: visible;
    height: auto;
  }
}
.customer-sales {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-sales__header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-sales__page-btns {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  margin-right: 2rem;
}
.customer-sales__page-btn-group {
  margin: 1.5rem 0 0 1.5rem;
  display: flex;
}
.customer-sales__page-btn {
  background: transparent;
  border: 1px solid #e0e0e0;
  min-height: 3rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: none;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.customer-sales__page-btn:hover {
  background: transparent;
  border-color: #14a75d;
  color: #14a75d;
}
.customer-sales__page-btn._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.customer-sales__page-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.customer-sales__page-btn._add {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
  width: 3rem;
}
.customer-sales__page-btn._add:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
  color: white;
}
.customer-sales__page-btn:not(._active):hover ~ .customer-sales__page-btn {
  border-left-color: #00bb5d;
}
.customer-sales__search {
  margin-left: auto;
}
.customer-sales__table {
  width: 100%;
  margin: 0;
}
.customer-sales__content {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
}
@media (max-width: 980px) {
  .customer-sales__header {
    align-items: center;
    padding: 1.6rem 1.6rem 2rem;
    flex-wrap: wrap;
  }
  .customer-sales__select-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .customer-sales__select-wrap .select {
    flex-grow: 1;
  }
  .customer-sales__search {
    width: 100%;
    margin-top: 1rem;
    background-color: white;
  }
  .customer-sales__select-add {
    min-height: 3rem;
    min-width: 3rem;
    padding: 0;
    margin-left: 3rem;
    font-size: 2rem;
    line-height: 1em;
  }
}
@media (max-width: 749px) {
  .customer-sales {
    height: auto;
  }
  .customer-sales__content {
    display: block;
    overflow: visible;
    height: auto;
  }
}
.customer-emails {
  position: relative;
  background: white;
  width: 100%;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-emails__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 2rem 2rem;
}
.customer-emails__filter-mail-type {
  width: 16rem;
}
.customer-emails__scroll-block {
  height: 20rem;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.customer-emails__table {
  width: 100%;
  margin: 0;
  display: flex;
}
.customer-emails__table thead,
.customer-emails__table tbody {
  width: 100%;
  display: block;
}
.customer-emails__table tr {
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: 0.15s;
}
.customer-emails__table tr:hover {
  background-color: #f2f2f2;
}
.customer-emails__table tr:hover .hide-particle__placeholder::before {
  opacity: 0;
}
.customer-emails__table tr:hover .hide-particle__placeholder::after {
  opacity: 1;
}
.customer-emails__table th,
.customer-emails__table td {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.customer-emails__table th:first-child,
.customer-emails__table td:first-child {
  width: 18rem;
}
.customer-emails__table th:nth-child(2),
.customer-emails__table td:nth-child(2) {
  width: 20%;
  flex-grow: 1;
}
.customer-emails__table th:last-child,
.customer-emails__table td:last-child {
  width: 13rem;
  text-align: right;
}
.customer-emails__table tr:last-child td {
  border-bottom: 0;
}
.customer-emails a {
  text-decoration: none;
}
.customer-emails__hide-particle-wrap {
  display: flex;
}
.customer-emails__hide-particle {
  width: 5rem;
  flex-grow: 1;
}
.customer-emails__email-list {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.customer-emails__files {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
}
.customer-emails__file {
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid #dedede;
  background: #f2f2f2;
  max-width: 12rem;
  margin: 0.5rem 0 0 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.customer-emails__file svg {
  width: 1.2rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  fill: #b8b8b8;
}
@media (max-width: 980px) {
  .customer-emails__table th:first-child,
  .customer-emails__table td:first-child {
    width: 10rem;
  }
}
@media (max-width: 749px) {
  .customer-emails {
    height: auto;
    background-color: transparent;
  }
  .customer-emails__scroll-block {
    height: auto;
    overflow: visible;
  }
  .customer-emails__table th:last-child,
  .customer-emails__table td:last-child {
    width: 10rem;
  }
}
.customer-logs {
  position: relative;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-logs__scroll-block {
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
}
.customer-logs__table-wrap {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-logs__table {
  width: 100%;
  margin: 0;
}
.customer-logs__table tr:last-child td {
  border-bottom: 0;
}
.customer-logs__table th:nth-child(1),
.customer-logs__table td:nth-child(1) {
  width: 14rem;
}
.customer-logs a {
  text-decoration: none;
}
ul.customer-logs__log-group {
  padding-left: 1.3rem;
}
ul.customer-logs__log-group li::before {
  background-color: #808080;
}
.customer-logs__log-group._added li::before {
  background-color: #14a75d;
}
.customer-logs__log-group._changed li::before {
  background-color: #e17d10;
}
.customer-logs__log-group._removed li::before {
  background-color: #8f1b2c;
}
@media (max-width: 980px) {
  .customer-logs__table th:nth-child(1),
  .customer-logs__table td:nth-child(1) {
    width: 13rem;
  }
}
@media (max-width: 749px) {
  .customer-logs__scroll-block {
    height: auto;
    overflow: auto;
  }
}
@media (max-width: 450px) {
  .customer-logs__table th:nth-child(1),
  .customer-logs__table td:nth-child(1) {
    width: 9rem;
  }
}
.customer-reports {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.customer-reports__head {
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  display: flex;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  gap: 1.5rem 2rem;
}
.customer-reports__category-select {
  width: 30rem;
}
.customer-reports__dates-select {
  width: 20rem;
}
.customer-reports__dates-select .select__option._disabled {
  display: none;
}
.customer-reports__dates-range {
  width: 25rem;
}
.customer-reports__actions {
  margin-left: auto;
}
.customer-reports__main-content {
  padding: 2rem 2rem 4rem;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
}
.customer-reports__default {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.customer-reports__default-title {
  color: #808080;
}
@media (max-width: 1340px) {
  .customer-reports__head {
    flex-wrap: wrap;
  }
  .customer-reports__category-select {
    flex: 1 1 100%;
  }
}
@media (max-width: 980px) {
  .customer-reports__dates-select {
    flex: 1 1 100%;
  }
  .customer-reports__dates-range {
    flex: 1;
  }
}
.customer-settings {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customer-settings__header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-settings__page-btns {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  margin-right: 2rem;
}
.customer-settings__page-btn-group {
  margin: 1.5rem 0 0 1.5rem;
  display: flex;
}
.customer-settings__page-btn {
  background: transparent;
  border: 1px solid #e0e0e0;
  min-height: 3rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: none;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.customer-settings__page-btn:hover {
  background: transparent;
  border-color: #14a75d;
  color: #14a75d;
}
.customer-settings__page-btn._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.customer-settings__page-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.customer-settings__page-btn._add {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
  width: 3rem;
}
.customer-settings__page-btn._add:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
  color: white;
}
.customer-settings__page-btn:not(._active):hover ~ .customer-settings__page-btn {
  border-left-color: #00bb5d;
}
.customer-settings__search {
  margin-left: auto;
}
.customer-settings__table {
  width: 100%;
  margin: 0;
}
.customer-settings__content {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}
@media (max-width: 980px) {
  .customer-settings__header {
    align-items: center;
    padding: 1.6rem 1.6rem 2rem;
    flex-wrap: wrap;
  }
  .customer-settings__select-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .customer-settings__select-wrap .select {
    flex-grow: 1;
  }
  .customer-settings__search {
    width: 100%;
    margin-top: 1rem;
    background-color: white;
  }
  .customer-settings__select-add {
    min-height: 3rem;
    min-width: 3rem;
    padding: 0;
    margin-left: 3rem;
    font-size: 2rem;
    line-height: 1em;
  }
}
@media (max-width: 749px) {
  .customer-settings {
    height: auto;
  }
  .customer-settings__content {
    display: block;
    overflow: visible;
    height: auto;
  }
}
.upload-photos {
  width: 100%;
  background: white;
}
.upload-photos__main {
  width: 100%;
  display: flex;
}
.upload-photos__list {
  margin-right: 3rem;
}
.upload-photos__upload-outer {
  flex-grow: 1;
  min-width: 15%;
  display: flex;
}
.upload-photos__img-wrap ~ .upload-photos__upload-outer {
  padding-left: 4rem;
}
.upload-photos__upload-wrap {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.upload-photos__upload {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dedede;
  border-radius: 0.3rem;
  padding: 3rem;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}
.upload-photos__upload-ico {
  fill: #f2f2f2;
}
.upload-photos__upload-title {
  margin: 2rem 0 2.4rem;
  color: #f2f2f2;
  font-weight: 700;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.upload-photos__upload-note {
  margin: 0;
  color: #616161;
}
.upload-photos__upload-note-accent {
  text-decoration: underline;
  color: #4778ff;
}
.upload-photos__input {
  display: none;
}
.upload-photos__loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px dashed #b8b8b8;
  border-radius: 0.3rem;
  padding: 3rem;
}
.upload-photos__loader {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.4rem solid #f2f2f2;
  border-left-color: #14a75d;
  border-right-color: #14a75d;
  animation: rotate 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.upload-photos__loader-text {
  margin: 1rem 0 -1rem;
  color: #808080;
  font-size: 1.2rem;
}
.upload-photos__footer {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.upload-photos__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.upload-photos__logs {
  padding-top: 1.6rem;
  margin-top: 2rem;
  border-top: 1px dashed #dedede;
}
.select-settings {
  display: flex;
}
.select-settings._add {
  cursor: pointer;
}
.select-settings__select {
  width: 50%;
  flex: 1 1 auto;
}
.select-settings__select:not(:last-child) .select__container {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-settings__select._open ~ .select-settings__btn,
.select-settings__input:hover ~ .select-settings__btn,
.select-settings__select:not(._disabled):not(._error):hover ~ .select-settings__btn,
.select-settings._add:hover .select-settings__input .input__input {
  border-color: #cdcccc;
  cursor: pointer;
}
.select-settings__input,
.select-settings__input .input__input {
  width: 50%;
  flex: 1 1 auto;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-settings__input .input__input {
  width: 100%;
}
.select-settings__btn {
  flex-shrink: 0;
  padding: 0.2rem;
  width: 2.9rem;
  height: 3.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: transparent !important;
  border: 1px solid #dedede;
  border-left-width: 0;
  overflow: visible;
}
.select-settings__btn svg {
  fill: #4778ff;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s;
}
.select-settings__btn._rotate svg {
  transition: transform 0.45s;
}
.select-settings__btn:hover svg {
  transform: scale(1.1);
}
.select-settings__btn._rotate:hover svg {
  transform: rotate(180deg);
}
.select-settings__btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  min-width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.35), rgba(31, 31, 31, 0));
}
.select-settings__select._error ~ .select-settings__btn {
  border-color: #aa0300;
}
.select-settings__select._disabled ~ .select-settings__btn {
  opacity: 0.6;
  cursor: not-allowed;
}
.select-settings._add .select-settings__btn:before {
  display: none;
}
.date-input {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.date-input._loading {
  pointer-events: none;
}
.date-input__main {
  display: flex;
  cursor: pointer;
}
.date-input._error .input__input {
  border-color: #aa0300;
}
.date-input__picker {
  width: 50%;
  flex-grow: 1;
}
.date-input__btn {
  flex-shrink: 0;
  padding: 0.2rem;
  width: 2.9rem;
  min-height: 3.2rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: transparent !important;
  border-left-width: 0;
  overflow: visible;
}
.date-input__btn svg {
  fill: #4778ff;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s;
}
.date-input__btn._clear svg {
  fill: #aa0300;
}
.date-input__btn._reminder svg {
  fill: #808080;
  transition: fill 0.15s;
}
.date-input__btn._reminder::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 100%;
  background: #4778ff;
  transition: opacity 0.15s;
  opacity: 0;
}
.date-input__btn._reminder:hover svg {
  fill: #4778ff;
}
.date-input__btn._reminder._active::before {
  opacity: 1;
}
.date-input__btn._reminder._active svg {
  fill: white;
}
.date-input__btn:not(._reminder)::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  min-width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.35), rgba(31, 31, 31, 0));
}
.date-input__input-wrap {
  width: 50%;
  flex-grow: 1;
}
.date-input__input {
  border-width: 0;
  cursor: pointer;
}
.date-input__input .input__input {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.date-input__input._readonly .input__input {
  cursor: pointer;
}
.date-input__input._disabled .input__input {
  cursor: not-allowed;
}
.date-input__input._over .input__label {
  width: calc(100% - 0.5rem);
}
.date-input__input:not(:last-child) .input__input {
  padding-right: 2.9rem;
}
.date-input._disabled .date-input__btn {
  cursor: not-allowed;
  opacity: 0.6;
}
.date-input .mx-icon-calendar,
.date-input .mx-icon-clear {
  right: 0;
}
.date-input__reminder-input {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.date-input__reminder-input .input {
  width: 25rem;
}
.date-input__reminder-input-buttons {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}
.mx-datepicker-popup table {
  margin: 0;
}
.mx-datepicker-popup th,
.mx-datepicker-popup td {
  padding: 0 !important;
  border-bottom: 0;
  background: transparent;
}
.date-show {
  display: inline-flex;
  width: auto;
}
.date-show .mx-input-wrapper {
  display: inline;
}
.date-show .mx-icon-calendar {
  display: none;
}
.photos-grid {
  flex-basis: 10%;
  display: grid;
  grid-auto-rows: 9.6rem;
  grid-gap: 2rem;
}
.photos-grid__img-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  overflow: hidden;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photos-grid__img-wrap:first-child {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.photos-grid__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}
.photos-grid__checkbox {
  position: absolute;
  z-index: 5;
  top: 0.5rem;
  left: 0.5rem;
  opacity: 0.8;
}
.photos-grid__checkbox .checkbox__mark {
  border-color: white;
}
.photos-grid__checkbox._checked,
.photos-grid__checkbox:hover {
  opacity: 1;
}
.photos-grid__delete,
.photos-grid__edit {
  position: absolute;
  z-index: 5;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  background: transparent;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  transition: 0.15s;
}
.photos-grid__delete svg,
.photos-grid__edit svg {
  fill: white;
}
.photos-grid__delete:hover,
.photos-grid__edit:hover {
  opacity: 1;
  border-color: #aa0300;
  background-color: #aa0300;
}
.photos-grid__edit {
  top: auto;
  bottom: 0.5rem;
  border-radius: 0.3rem;
  opacity: 0;
}
.photos-grid__edit:hover {
  border-color: #478aff;
  background-color: #478aff;
}
.photos-grid__img-wrap:hover .photos-grid__edit {
  opacity: 1;
}
.photos-grid__no-photos {
  color: #b8b8b8;
  font-weight: 700;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.add-estimates__main {
  padding: 2.4rem 2rem;
}
.add-estimates__forms {
  border-top: 1px dashed #dedede;
  padding: 3rem 2rem 2rem;
}
.add-estimates__form {
  width: 100%;
  margin: 0;
  border: 1px solid #dedede;
}
.add-estimates__form > table {
  width: 100%;
  margin: 0;
}
.add-estimates__form td {
  border-bottom: 0;
}
.add-estimates__form tbody tr:first-child td {
  padding-top: 2.4rem;
}
.add-estimates__form td:first-child {
  width: 28%;
}
.add-estimates__form td:last-child {
  width: 5.4rem;
}
.add-estimates__form td:nth-last-child(2) {
  width: 16rem;
}
.add-estimates__form tfoot td {
  padding-bottom: 2.4rem;
}
.add-estimates__form-variant-select {
  width: 30rem;
  max-width: 100%;
  margin-bottom: 2rem;
}
.add-estimates__delete.btn:hover {
  background-color: #aa0300;
}
.add-estimates__delete.btn:hover svg {
  fill: white;
}
.add-estimates__note {
  width: 100%;
}
.add-estimates__footer {
  padding: 1rem 2rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-estimates__btn-left {
  margin-right: 1rem;
}
.add-estimates__result {
  margin-top: 1.2rem;
  padding: 2.4rem 2rem;
  border-top: 1px solid #dedede;
}
.add-estimates__result-table {
  margin: 0;
  width: 50rem;
  max-width: 100%;
  margin-left: auto;
}
.add-estimates__result-table td:first-child {
  width: 45%;
}
.add-estimates__result-table tfoot td {
  background-color: #f2f2f2;
}
.add-estimates__logs {
  padding-top: 1.6rem;
  margin: 2rem 2rem 1.4rem;
  border-top: 1px dashed #dedede;
}
.info-block__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  min-height: 4rem;
  padding: 0.6rem 2rem;
}
.info-block__header._toggle {
  cursor: pointer;
}
.info-block__header._blue-light {
  background: #81afe2;
  color: white;
}
.info-block__header._blue {
  background: #4778ff;
  color: white;
}
.info-block__header-photo-wrap {
  height: 4rem;
  width: 4rem;
  background: #d8d8d8;
  margin-right: 0.8rem;
  margin-top: -0.6rem;
  margin-bottom: -0.6rem;
}
.info-block__header-photo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.info-block__ico {
  margin-right: 0.8rem;
}
.info-block__title-wrap {
  flex-grow: 1;
  margin-right: 2rem;
  display: flex;
  align-items: center;
}
.info-block__title {
  padding-top: 0.2rem;
  margin: 0 2rem 0 0;
  flex-grow: 1;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: uppercase;
}
.info-block__title-wrap .info-block__title {
  flex-grow: 0;
  margin-right: 1rem;
}
.info-block__title-wrap .notes-btn {
  margin: 0;
}
.info-block__title-edit-btn {
  border: 0;
  padding: 0.4rem 0.4rem 0.2rem;
  background: transparent;
  cursor: pointer;
}
.info-block__title-edit-btn svg {
  fill: #333333;
  transition: 0.15s;
}
.info-block__title-edit-btn:hover svg {
  fill: #00bb5d;
}
.info-block__header-error-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #e17d10;
  margin-left: 0.5rem;
  margin-right: 1rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}
.info-block__filled {
  display: flex;
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.info-block__filled-list {
  opacity: 0.7;
  margin-left: 0.6rem;
}
.info-block__toggle {
  margin-left: 1rem;
}
.info-block__toggle svg {
  transform: rotate(90deg);
}
.info-block._open > .info-block__header .info-block__toggle svg {
  transform: rotate(270deg);
}
.info-block__header-btn {
  margin-left: 1rem;
}
.info-block__main {
  padding: 3rem 2rem;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  transition: padding-top 0.3s, margin-top 0.3s;
}
.info-block__main._inner {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.info-block__link {
  text-decoration: underline;
  color: #4778ff;
}
.info-block__row {
  margin-top: 3rem;
  position: relative;
}
@media (max-width: 980px) {
  .info-block__row {
    margin-top: 2rem;
  }
}
.info-block__row:first-child {
  margin-top: 0;
}
.info-block__cell-with-delete {
  display: flex;
  align-items: flex-start;
}
.info-block__delete.btn {
  margin-right: 1rem;
  margin-top: 0.6rem;
}
.info-block__delete.btn svg {
  width: 1.2rem;
  height: 1.2rem;
}
.info-block__delete.btn:hover {
  background: #aa0300;
}
.info-block__delete.btn:hover svg {
  fill: white;
}
.info-block__note-checkbox {
  margin-top: 0.7rem;
  margin-left: 1rem;
}
.info-block__error-wrap {
  padding: 0 2rem;
}
.info-block__error {
  margin-bottom: 2rem;
}
.info-block__footer {
  padding: 1rem 2rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-block__footer._inner {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.info-block__footer._marg-top {
  margin-top: 2rem;
}
.info-block__footer-row {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  grid-row-gap: 2rem;
}
.info-block__footer-actions {
  margin-left: auto;
}
.info-block__footer-separator {
  margin: 0 auto;
  width: 1rem;
}
.info-block__footer-checkbox {
  margin-left: 2rem;
}
.info-block__light-btn {
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 2.2rem;
  outline: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
}
.info-block__light-btn:hover {
  text-decoration: none;
  color: #4778ff;
}
.info-block__light-btn:last-child {
  margin-right: 0;
}
.info-block__light-btn._disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.info-block__btn-additional.btn {
  position: relative;
  z-index: 3;
  background: white;
}
.info-block__btn-additional-wrap {
  position: relative;
}
.info-block__btn-additional-wrap::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 1%;
  width: 99%;
  height: 0;
  transform: translateY(-1px);
  border-bottom: 1px dashed #dedede;
}
.info-block__btn {
  margin-left: 1rem;
}
.info-block__btn-left {
  margin-right: 1rem;
}
.info-block__nested-info-block {
  border: 1px solid #dedede;
  width: calc(100% - 1rem);
  margin: 0.5rem auto;
}
.info-block__nested-info-block + .info-block__main {
  margin-top: 3rem;
  border-top: 1px solid #dedede;
}
.info-block__nested-info-block._open + .info-block__main {
  margin-top: 0;
  padding-top: 6.5rem;
  border-top: 1px solid #dedede;
}
.info-block__nested-info-block + .info-block__footer {
  padding-top: 2rem;
}
.info-block__logs {
  margin: -1rem 2rem 1.4rem;
}
.info-block__info-table {
  margin: 0;
}
.info-block__info-table td {
  padding: 1.2rem 0;
}
.info-block__info-table td:first-child {
  position: relative;
  padding-right: 1rem;
  width: 21rem;
  vertical-align: top;
  max-width: 40%;
  color: #808080;
}
.info-block__info-table td:first-child::after {
  content: ':';
  position: relative;
  z-index: 2;
  background: white;
  padding-right: 0.3rem;
}
.info-block__info-table td:first-child > span {
  position: relative;
  background: white;
  z-index: 2;
}
.info-block__info-table td:first-child > span::before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0.4rem;
  left: 0;
  width: 20.5rem;
  height: 1px;
  border-bottom: 1px dotted #dedede;
}
.info-block__section-title {
  margin: 2rem 0 2rem;
  font-size: 1.8rem;
}
.info-block__section-title:first-child {
  margin-top: 0;
}
.info-block__group {
  position: relative;
  width: calc(100% - 4rem);
  margin: 3.5rem auto 2rem;
  border: 1px solid #dedede;
}
.info-block__main .info-block__group {
  width: calc(100% - 2rem);
}
.info-block__group-title {
  position: absolute;
  top: -1rem;
  left: 1.7rem;
  background: #f2f2f2;
  background: white;
  padding: 0.3rem 0.5rem;
  margin: 0;
  text-transform: none;
  font-size: 1.2rem;
}
.info-block__group-delete.btn {
  position: absolute;
  top: -1.3rem;
  right: 2rem;
  background: white;
}
.info-block__group-edit.btn {
  position: absolute;
  top: -1.3rem;
  right: 5.4rem;
  background: white;
}
.info-block__group + .info-block__main {
  padding-top: 1rem;
}
.info-block__light-table {
  width: 100%;
}
.info-block__table-wrap {
  padding: 1rem;
  overflow: auto;
}
.info-block__cell-title {
  margin: 0 0 0.7rem;
  font-size: 1.2rem;
  color: #8a8a8a;
  font-weight: 400;
}
.info-block__actions {
  width: 100%;
}
.info-block__checkbox {
  margin-top: 0.5rem;
}
.info-block__checkbox .checkbox__text {
  font-size: 1.1rem;
}
@media (max-width: 1100px) {
  .info-block__header,
  .info-block__main,
  .info-block__error-wrap,
  .info-block__footer {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .info-block__group {
    width: calc(100% - 3.2rem);
  }
  .info-block__table-wrap {
    overflow: visible;
  }
}
.notes-btn {
  position: relative;
  overflow: visible;
  margin-bottom: 1rem;
}
.notes-btn svg {
  margin-right: 0 !important;
}
.notes-btn__quantity {
  position: absolute;
  top: -0.9rem;
  right: -0.9rem;
  background: #4778ff;
  min-width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  text-align: center;
  color: white;
  padding: 0 0.4rem;
  white-space: nowrap;
}
.expenses-table {
  width: 100%;
  margin: 0;
}
.expenses-table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.expenses-table._static-head thead {
  position: static;
}
.expenses-table td {
  vertical-align: top;
}
.expenses-table td._no-link {
  cursor: auto;
}
.expenses-table col._checkbox {
  width: 5rem;
}
.expenses-table col._toggle {
  width: 5.3rem;
}
.expenses-table col._photo {
  width: 8.5rem;
}
.expenses-table col._date {
  width: 12rem;
}
.expenses-table col._agent {
  width: 20rem;
}
.expenses-table col._status {
  width: 15rem;
}
.expenses-table col._category {
  width: 18rem;
}
.expenses-table col._actions {
  width: 4rem;
}
.expenses-table col._memo {
  width: 20rem;
}
.expenses-table col._payments {
  width: 20rem;
}
.expenses-table col._customer {
  width: 25rem;
}
.expenses-table col._vendor {
  width: 20rem;
}
.expenses-table tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.expenses-table__toggle {
  padding: 0;
  min-width: 2.4rem;
  min-height: 2.4rem;
  background: white;
  border-color: #dedede;
}
.expenses-table__toggle svg {
  width: 0.8rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: #333333;
}
.expenses-table__toggle:hover {
  background-color: #4778ff;
}
.expenses-table__toggle:hover svg {
  fill: white;
}
.expenses-table__table-row {
  cursor: pointer;
}
.expenses-table__table-row._open .expenses-table__toggle,
.expenses-table__card._open .expenses-table__toggle {
  background: #4778ff;
}
.expenses-table__table-row._open .expenses-table__toggle svg,
.expenses-table__card._open .expenses-table__toggle svg {
  transform: rotate(270deg);
  fill: white;
}
.expenses-table__cell._first {
  transition: border-color 0.15s;
  border-left: 1px dashed transparent;
}
.expenses-table__cell._last {
  transition: border-color 0.15s;
  border-right: 1px dashed transparent;
}
.expenses-table__table-row._open .expenses-table__cell._first {
  border-color: #dbdbdb;
}
.expenses-table__table-row._open .expenses-table__cell._last {
  border-color: #dbdbdb;
}
.expenses-table__cell-content {
  position: relative;
  height: 4.6rem;
}
.expenses-table__cell-content._not-current > * {
  opacity: 0.35;
}
.expenses-table__cell-content .d-flex > .g-no-wrap {
  flex: 1;
  width: 0;
}
.expenses-table__cell-list .expenses-table__cell-content {
  height: 5.9rem;
  padding-top: 1.3rem;
}
.expenses-table__cell-content::after {
  content: '';
  position: absolute;
  left: -1rem;
  top: calc(100% + 0.6rem);
  height: 0;
  width: calc(100% + 2rem);
  border-bottom: 1px dashed #dbdbdb;
  opacity: 0;
  transition: opacity 0.15s;
}
.expenses-table__table-row._open .expenses-table__cell-content::after {
  opacity: 1;
}
.expenses-table__cell-content:last-child::after {
  display: none;
}
.expenses-table__amount {
  display: inline-block;
  min-width: 1.7rem;
  height: 1.7rem;
  background: white;
  box-shadow: 0 0 0.7rem rgba(162, 162, 162, 0.5);
  border-radius: 0.3rem;
  opacity: 1;
  margin-right: 0.6rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  transition: 0.15s;
  flex-shrink: 0;
}
.expenses-table__amount._absolute {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  margin-right: 0;
}
.expenses-table__table-row._open .expenses-table__amount {
  opacity: 0;
  display: none;
}
.expenses-table__row-separator td {
  background: white !important;
  padding: 0.5rem 0;
}
.expenses-table__dropdown {
  margin-left: 0.4rem;
}
.expenses-table__dropdown .dropdown__dropdown-inner {
  width: 30rem;
}
.expenses-table__drop-statuses-list {
  list-style: none;
  margin: -1.2rem 0 0;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 400;
}
.expenses-table__drop-status {
  width: 48%;
  margin-top: 1.2rem;
}
.expenses-table__photo-wrap {
  position: relative;
  background: #d8d8d8;
  overflow: hidden;
  width: 6.5rem;
  height: 4.6rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.expenses-table__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.expenses-table__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.expenses-table__short-item._selected {
  background-color: #e3ebff;
}
.expenses-table__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.expenses-table__short-item-content {
  flex-grow: 1;
  width: 50%;
  margin-top: -0.5rem;
  margin-right: -1rem;
}
.expenses-table__short-item-content > div {
  display: flex;
  justify-content: space-between;
}
.expenses-table__short-el {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  flex: 1;
  display: flex;
  margin-right: 1rem;
}
.expenses-table__short-el .g-no-wrap {
  flex: 1;
  width: 0;
}
.expenses-table__short-el._right {
  text-align: right;
  justify-content: flex-end;
}
.expenses-table__short-el._no-shrink {
  flex: 0 0 auto;
}
.expenses-table:not(table):not(._short) {
  padding: 1.6rem;
}
.expenses-table__card {
  min-height: 9rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  background-color: white;
  margin-bottom: 1.4rem;
  font-size: 1.2rem;
}
.expenses-table__card:last-child {
  margin-bottom: 0;
}
.expenses-table__card-info {
  padding-right: 3rem;
}
.expenses-table__card-info-item {
  margin-bottom: 0.3rem;
}
.expenses-table__card-info-item:last-child {
  margin-bottom: 0;
}
.expenses-table__card-actions {
  position: absolute;
  padding: 1.1rem;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expenses-table__card-actions > * {
  margin-bottom: 1.2rem;
}
.expenses-table__card-actions > *:last-child {
  margin-bottom: 0;
}
.expenses-table__card-item {
  padding: 1rem 3rem 1rem 0;
  position: relative;
  border-top: 1px dashed #e0e0e0;
}
.expenses-table__card-item-actions {
  position: absolute;
  right: 0;
  top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expenses-table__card-item-actions > * {
  margin-bottom: 1rem;
}
.expenses-table__card-item._first {
  margin-top: 1rem;
  border-top-style: solid;
}
.expenses-table__card-item:last-child {
  padding-bottom: 0;
}
.expenses-table__card-item-row {
  position: relative;
}
.expenses-table__card-item-row._not-current > * {
  opacity: 0.35;
}
.expenses-table__card-item .expenses-table__amount {
  right: auto;
  left: 0.2rem;
  top: 0.2rem;
}
.expenses-table__card-item .expenses-table__photo-wrap {
  margin-top: 0;
  margin-bottom: 0;
}
.expenses-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.expenses-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.expenses-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.expenses-list__search,
.expenses-list__head-btn {
  margin-right: 1rem;
}
.expenses-list__selected {
  margin: 0;
  margin-left: 1rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.expenses-list__table {
  width: 100%;
  margin: 0;
}
.expenses-list__table-wrap {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.expenses-list__filters {
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 2;
  border: 1px solid #dedede;
  border-left: 0;
  border-right: 0;
  padding: 1.2rem 2rem;
  display: flex;
  gap: 1.5rem 2rem;
  margin-top: auto;
}
.expenses-list__filter-select {
  width: 20rem;
}
.expenses-list__filter-select .select__option._disabled {
  display: none;
}
.expenses-list__dates-range {
  width: 25rem;
}
.expenses-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expenses-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.expenses-list__info {
  padding-top: 1px;
}
.expenses-list__info > * {
  margin-bottom: 0.4rem;
}
.expenses-list._short .expenses-list__header {
  border-bottom: 1px solid #dedede;
  top: 0;
}
@media (max-width: 749px) {
  .expenses-list__selection-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
  }
  .expenses-list__selected {
    order: -1;
    margin-right: auto;
    margin-left: 0;
  }
  .expenses-list__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
}
.contracts-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.contracts-list__header-row {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.contracts-list__header-row._sticky {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background-color: white;
}
.contracts-list._short .contracts-list__header-row._sticky {
  top: 0;
}
.contracts-list__table-wrap {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.contracts-list__table-wrap table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.contracts-list__table {
  margin: 0;
  width: 100%;
}
.contracts-list__table col._checkbox {
  width: 4.8rem;
}
.contracts-list__table col._amount {
  width: 15rem;
}
.contracts-list__table col._actions {
  width: 4rem;
}
.contracts-list__table td:last-child,
.contracts-list__table th:last-child {
  text-align: right;
}
.contracts-list__item {
  transition: background-color 0.15s;
}
@media (min-width: 981px) {
  .contracts-list__item:hover {
    background-color: #f2f2f2;
  }
}
.contracts-list__item td:not(._no-link) {
  cursor: pointer;
}
.contracts-list__item-pay {
  margin-top: -0.5rem;
}
.contracts-list__item-pay-full {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  display: block;
  margin-bottom: -0.6rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  color: #4778ff;
}
.contracts-list__item-pay-full:not(:disabled):hover {
  text-decoration: unset;
}
.contracts-list__item-pay-full:not(:disabled):hover {
  text-decoration: unset;
}
.contracts-list__item-pay-full:disabled {
  cursor: auto;
  opacity: 0.6;
  color: #808080;
  text-decoration: none;
}
@media (max-width: 980px) {
  .contracts-list__table-wrap {
    padding: 0 1.8rem;
  }
  .contracts-list__table,
  .contracts-list tbody {
    display: block;
  }
  .contracts-list colgroup,
  .contracts-list thead {
    display: none;
  }
  .contracts-list tr {
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    margin-top: 1.4rem;
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    position: relative;
  }
  .contracts-list td {
    border: 0;
    padding: 0;
    text-align: left !important;
    margin-bottom: 0.6rem;
    vertical-align: baseline;
  }
  .contracts-list td::before {
    content: attr(data-title);
    font-weight: 600;
  }
  .contracts-list td._actions {
    position: absolute;
    padding: 1.1rem;
    top: 0;
    right: 0;
  }
}
.contracts-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contracts-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.contracts-list .files-list__selected {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 980px) {
  .contracts-list .files-list__selected {
    flex-direction: row;
  }
}
@media (max-width: 980px) {
  .contracts-list .files-list__selected {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0.9rem 1.6rem;
  }
}
.contracts-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.contracts-list__search,
.contracts-list__head-btn {
  margin-right: 1rem;
}
.contracts-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.contracts-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  font-size: 1.2rem;
  cursor: pointer;
}
.contracts-list__short-item._selected {
  background-color: #e3ebff;
}
.contracts-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.contracts-list__short-item-content {
  flex-grow: 1;
  width: 50%;
  margin-top: -0.5rem;
  margin-right: -1rem;
}
.contracts-list__short-item-content > div {
  display: flex;
  justify-content: space-between;
}
.contracts-list__short-el {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  flex: 1;
  display: flex;
  margin-right: 1rem;
}
.contracts-list__short-el .g-no-wrap {
  flex: 1;
  width: 0;
}
.contracts-list__short-el._right {
  text-align: right;
  justify-content: flex-end;
}
.contracts-list__short-el._no-shrink {
  flex: 0 0 auto;
}
.tasks-list {
  position: relative;
}
.tasks-list__table {
  margin: 0;
  width: 100%;
}
.tasks-list__table col._actions {
  width: 1px;
}
.tasks-list__table td:last-child,
.tasks-list__table th:last-child {
  text-align: right;
}
.tasks-list__item {
  cursor: pointer;
  transition: background-color 0.15s;
}
.tasks-list__item:hover {
  background-color: #f2f2f2;
}
.sending-logs__toggle-btn {
  display: inline-block;
  cursor: pointer;
  padding-bottom: 0.2rem;
  margin: 0;
  font-size: 1.4rem;
  color: #616161;
  border-bottom: 1px dashed #808080;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  transition: margin-bottom 0.3s;
}
.sending-logs__toggle-btn svg {
  width: 0.8rem;
  height: 1rem;
  fill: #333333;
  margin-left: 0.4rem;
  transform: rotate(90deg);
  transition: 0.15s;
}
.sending-logs._open .sending-logs__toggle-btn {
  margin-bottom: 2rem;
}
.sending-logs._open .sending-logs__toggle-btn svg {
  transform: rotate(270deg);
}
.sending-logs__table {
  width: 100%;
  margin: 0;
}
.files-list .notes-btn {
  margin-bottom: 0;
}
.files-list ._no-link {
  cursor: auto;
}
.files-list__header-row {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.files-list__header-row._sticky {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background-color: white;
}
.files-list._short .files-list__header-row._sticky {
  top: 0;
}
.files-list__header-content {
  display: flex;
  align-items: center;
  width: 100%;
}
.files-list__search._full-width {
  width: 100%;
}
.files-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
  margin-right: 2rem;
  margin-left: 1rem;
}
.files-list__head-btn {
  margin-right: 1rem;
}
.files-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.files-list__drop-menu {
  margin-right: 1rem;
}
.files-list__drop-menu .dropdown__dropdown-inner {
  width: 15rem;
}
.files-list__filter {
  margin-top: -1rem;
  margin-left: -1rem;
}
.files-list__filter-item {
  background: transparent;
  border: 1px solid #dedede;
  min-height: 3rem;
  margin: 1rem 0 0 1rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.files-list__filter-item:hover {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.files-list__filter-item._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.files-list__table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
}
.files-list__table td {
  vertical-align: top;
}
.files-list__table th._check,
.files-list__table td._check {
  width: 4.8rem;
}
.files-list__table th:last-child,
.files-list__table td:last-child {
  width: 9rem;
}
.files-list__table-row {
  cursor: pointer;
}
.files-list__table-row td {
  transition: background-color 0.15s;
}
@media (min-width: 1101px) {
  .files-list__table-row:hover td,
  .files-list__table-row._open td {
    background-color: #f2f2f2;
  }
}
.files-list .hide-particle__placeholder::after {
  display: block;
  opacity: 0;
}
.files-list__table-row:hover .hide-particle__placeholder::after,
.files-list__table-row._open .hide-particle__placeholder::after {
  opacity: 1;
}
.files-list__amount {
  display: inline-block;
  min-width: 1.7rem;
  height: 1.7rem;
  background: white;
  box-shadow: 0 0 0.7rem rgba(162, 162, 162, 0.5);
  border-radius: 0.3rem;
  opacity: 1;
  padding: 0 0.4rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  transition: 0.15s;
}
.files-list__amount._absolute {
  position: absolute;
  top: 1.2rem;
  left: 1.5rem;
}
.files-list__table-row._open .files-list__amount {
  opacity: 0;
}
.files-list__container-item {
  padding: 0;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-collor 0.15s;
  display: flex;
}
.files-list__container-item:last-child {
  margin-bottom: -1px;
}
.files-list__container-item-row {
  flex: 1;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-collor 0.15s;
}
.files-list__container-item-row:last-child {
  border-right: none;
}
.files-list__container-item .files-list__cell-content {
  border-bottom: 1px dashed transparent;
  transition: border-bottom 0.15s;
}
.files-list__container-item .files-list__cell-content._customer {
  border-right: 1px dashed transparent;
}
.files-list__table-row._open .files-list__container-item .files-list__commodity-row:not(:last-child) .files-list__cell-content:not(._customer) {
  border-color: #dedede;
}
.files-list__table-row._open .files-list__container-item .files-list__cell-content._customer {
  border-right-color: #dedede;
}
.files-list__table-row._open .files-list__container-item {
  border-color: #dedede;
}
.files-list__table-row._open .files-list__container-item-row {
  border-color: #dedede;
}
.files-list__container-item .files-list__commodity-row::before {
  display: none;
}
.files-list__commodity-row {
  position: relative;
  display: flex;
  padding: 0;
  flex-grow: 1;
}
.files-list__commodity-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  right: 2rem;
  height: 1px;
  border-bottom: 1px dashed #dbdbdb;
  opacity: 0;
  transition: 0.15s;
}
.files-list__commodity-row._main::before {
  display: none;
}
.files-list__table-row._open .files-list__commodity-row::before {
  opacity: 1;
}
.files-list__toggle {
  padding: 0;
  min-width: 2.2rem;
  min-height: 2.2rem;
  background: transparent;
  box-shadow: 0 0 0.6rem rgba(187, 187, 187, 0.5);
}
.files-list__toggle svg {
  width: 0.8rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: #333333;
}
.files-list__toggle:hover {
  background-color: #4778ff;
}
.files-list__toggle:hover svg {
  fill: white;
}
.files-list__table-row._open .files-list__toggle,
.files-list__card._open .files-list__toggle {
  background: #4778ff;
}
.files-list__table-row._open .files-list__toggle svg,
.files-list__card._open .files-list__toggle svg {
  transform: rotate(270deg);
  fill: white;
}
.files-list__commodity-cell {
  position: relative;
  transition: background-color 0.15s;
  padding: 0;
}
.files-list__commodity-cell._sum {
  background-color: white;
}
.files-list__cell-content {
  position: relative;
  padding: 1.2rem 1rem;
}
.files-list__cell-content._center {
  align-self: center;
}
.files-list__cell-list .files-list__cell-content {
  padding-top: 1.3rem;
}
.files-list__head-cell._file,
.files-list td._file {
  width: 18rem;
  flex-grow: 1;
}
.files-list__cell-content._commodity-info {
  flex: 1;
  width: 0;
}
.files-list__head-cell._customer,
.files-list__cell-content._customer {
  width: 20rem;
  flex-shrink: 0;
}
.files-list__head-cell._invoice,
.files-list__cell-content._invoice {
  width: 10rem;
}
.files-list__head-cell._balance,
.files-list__cell-content._balance {
  width: 10rem;
}
.files-list__head-cell._status,
.files-list__cell-content._status {
  width: 12rem;
}
.files-list__head-cell._commodity-status,
.files-list__cell-content._commodity-status {
  width: 14rem;
}
.files-list__head-cell._commodity-container-return-locations,
.files-list__cell-content._commodity-container-return-location {
  width: 12rem;
}
.files-list__head-cell._commodity-notes,
.files-list__cell-content._commodity-notes {
  width: 6rem;
  flex-shrink: 0;
}
.files-list__head-cell._date,
.files-list td._date {
  width: 10rem;
}
.files-list__head-cell._container-loaded-drop-location,
.files-list td._container-loaded-drop-location {
  width: 14rem;
}
.files-list__head-cell._container-size-and-weight,
.files-list td._container-size-and-weight {
  width: 12rem;
}
.files-list__cell-container-item {
  margin-bottom: 1rem;
}
.files-list__cell-container-item:last-child {
  margin-bottom: 0;
}
.files-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.files-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.files-list__info {
  padding-top: 1px;
}
.files-list__info > * {
  margin-bottom: 0.4rem;
}
.files-list__short-filter {
  width: 100%;
  margin-bottom: 1rem;
}
.files-list__short-filter .dropdown__btn {
  border-color: #14a75d;
  background-color: #14a75d;
  color: white;
}
.files-list__short-filter .dropdown__btn:hover {
  border-color: #00bb5d;
  background-color: #00bb5d;
}
.files-list__short-filter .dropdown__arrow svg {
  fill: white;
}
.files-list__short-filter .dropdown__dropdown {
  width: 100%;
}
.files-list._short .files-list__header {
  border-bottom: 1px solid #dedede;
}
.files-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.files-list__short-item._selected {
  background-color: #e3ebff;
}
.files-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.files-list__short-item-content {
  flex-grow: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.files-list__short-el {
  font-size: 1.2rem;
}
.files-list__short-el strong {
  font-weight: 400;
  color: #808080;
}
.files-list__short-filter-select {
  width: 100%;
}
.files-list__cards {
  padding: 1.6rem;
}
.files-list__card {
  min-height: 9rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  background-color: white;
  margin-bottom: 1.4rem;
  font-size: 1.2rem;
}
.files-list__card:last-child {
  margin-bottom: 0;
}
.files-list__card-info {
  padding-right: 3rem;
}
.files-list__card-info-item {
  margin-bottom: 0.3rem;
}
.files-list__card-info-item:last-child {
  margin-bottom: 0;
}
.files-list__card-actions {
  position: absolute;
  padding: 1.1rem;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.files-list__card-actions > * {
  margin-bottom: 1.2rem;
}
.files-list__card-actions > *:last-child {
  margin-bottom: 0;
}
.files-list__card-commodity {
  padding: 1rem 3rem 1rem 0;
  position: relative;
  border-top: 1px dashed #e0e0e0;
}
.files-list__card-commodity-actions {
  position: absolute;
  right: 0;
  top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.files-list__card-commodity-actions > * {
  margin-bottom: 1rem;
}
.files-list__card-commodity._first {
  margin-top: 1rem;
  border-top-style: solid;
}
.files-list__card-commodity:last-child {
  padding-bottom: 0;
}
.files-list__card-commodity-row {
  position: relative;
}
.files-list__card-commodity .files-list__amount {
  right: auto;
  left: 0.2rem;
  top: 0.2rem;
}
.files-list__card-commodity .preview-commodity__photo-wrap {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  .files-list__header-row {
    border-bottom: 0;
    padding: 1rem 1.6rem;
  }
  .files-list__header-content {
    display: block;
  }
  .files-list__selection-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
    margin: 0;
  }
  .files-list__selected {
    order: -1;
    margin-right: auto;
  }
  .files-list__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
  .files-list__drop-menu {
    margin-right: 0;
  }
  .files-list__drop-menu .dropdown__dropdown {
    top: auto;
    bottom: 100%;
    left: auto;
    right: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .files-list__drop-menu .dropdown__dropdown-inner {
    top: auto;
    bottom: 0;
  }
}
.address {
  width: 100%;
}
.address__row {
  display: flex;
  margin-bottom: 2rem;
}
.address__row:last-child {
  margin-bottom: 0;
}
.address__country {
  flex-grow: 1;
  min-width: 12rem;
  width: 20%;
}
.address__input {
  width: 20%;
  flex-grow: 1;
  margin-left: 1rem;
}
.address__input:first-child {
  margin-left: 0;
}
.address__input._code {
  flex-grow: 0;
  width: 10em;
}
.payment-list__table {
  width: 100%;
}
.booking-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-list._short .booking-list__header {
  top: 0;
}
.booking-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.booking-list__search,
.booking-list__head-btn {
  margin-right: 1rem;
}
.booking-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.booking-list__only-booking {
  margin-left: auto;
}
.booking-list__table {
  width: 100%;
  margin: 0;
}
.booking-list__table th:last-child {
  width: 4.4rem;
}
.booking-list__table th:nth-child(6),
.booking-list__table td:nth-child(6),
.booking-list__table th:nth-child(7),
.booking-list__table td:nth-child(7) {
  width: 8.5rem;
}
.booking-list__table tbody tr {
  cursor: pointer;
}
.booking-list__table tbody tr:hover {
  background-color: #f2f2f2;
}
.booking-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.booking-list__info {
  padding-top: 1px;
}
.booking-list__info > * {
  margin-bottom: 0.4rem;
}
.booking-list._short .booking-list__header {
  justify-content: flex-start;
  border-bottom: 1px solid #dedede;
}
.booking-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.booking-list__short-item._selected {
  background-color: #e3ebff;
}
.booking-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.booking-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 50%;
}
.booking-list__short-el {
  font-size: 1.2rem;
}
.booking-list__short-el::after {
  content: '|';
  margin: 0 0.4rem;
  color: #b8b8b8;
}
.booking-list__short-el._path {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.6rem 0;
}
.booking-list__short-el._path > div {
  display: block;
  width: 100%;
  color: black;
}
.booking-list__short-el._path > div strong {
  font-weight: 400;
  color: #808080;
}
.booking-list__short-el._path::after,
.booking-list__short-el._last::after,
.booking-list__short-el:last-child::after,
.booking-list__short-el._path > div:last-child::after {
  display: none;
}
.booking-list__short-el abbr {
  color: #808080;
  font-weight: 600;
}
.booking-list__short-el._full {
  width: 100%;
  padding: 0.6rem 0 0;
}
.booking-list__short-el._last strong {
  font-weight: 400;
  color: #808080;
}
@media (max-width: 980px) {
  .booking-list__header {
    padding: 1rem 1.6rem;
  }
  .booking-list._short {
    display: none !important;
  }
  .booking-list__item {
    padding: 1.1rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    margin-bottom: 1.4rem;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .booking-list__item:last-child {
    margin-bottom: 0;
  }
  .booking-list__item-actions {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
  }
  .booking-list__mob-list {
    padding: 1.6rem;
  }
}
@media (max-width: 749px) {
  .booking-list__header {
    flex-direction: column;
  }
  .booking-list__only-booking {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
}
.commodities-list {
  display: flex;
  flex-direction: column;
}
.commodities-list__header-row {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.commodities-list__header-row._sticky {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
}
.commodities-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.commodities-list__search._full-width {
  width: 100%;
}
.commodities-list__search,
.commodities-list__head-btn {
  margin-right: 1rem;
}
.commodities-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.commodities-list__filter {
  margin-top: -1rem;
  margin-left: -1rem;
}
.commodities-list__filter-item {
  background: transparent;
  border: 1px solid #dedede;
  min-height: 3rem;
  margin: 1rem 0 0 1rem;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.commodities-list__filter-item:hover {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.commodities-list__filter-item._active {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.commodities-list__filter-item > span {
  margin-left: 0.4rem;
}
.commodities-list__main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.commodities-list__groups-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  overflow-y: auto;
}
.commodities-list__groups-list._full {
  width: 100%;
}
.commodities-list__groups-table {
  width: 100%;
  margin: 0;
}
.commodities-list__groups-table th {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  background: white;
}
.commodities-list__groups-table th:last-child {
  text-align: right;
}
.commodities-list__groups-table td {
  background-color: #f2f2f2;
}
.commodities-list__groups-table td:last-child {
  text-align: right;
}
.commodities-list__groups-table tr._active-exact td,
.commodities-list__groups-table tr._current-destination td {
  background-color: #e3ebff;
}
.commodities-list__groups-table tbody tr {
  cursor: pointer;
}
.commodities-list__main-content {
  width: 80%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.commodities-list__main-content._full {
  width: 100%;
}
.commodities-list__table-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.commodities-list__scroll-block {
  flex-grow: 1;
}
.commodities-list__table {
  width: 100%;
  margin: 0;
}
.commodities-list__table._head {
  position: sticky;
  top: 10.5rem;
  z-index: 32;
}
.commodities-list__table tr {
  display: flex;
}
.commodities-list__table th,
.commodities-list__table td {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.commodities-list__table td {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.commodities-list__table tbody tr {
  cursor: pointer;
  transition: 0.15s;
}
.commodities-list__table tbody tr:hover {
  background-color: #f2f2f2;
}
.commodities-list__table tbody tr:hover .hide-particle__placeholder::before {
  opacity: 0;
}
.commodities-list__table tbody tr:hover .hide-particle__placeholder::after {
  opacity: 1;
}
.commodities-list__table th:first-child,
.commodities-list__table td:first-child {
  width: 20rem;
}
.commodities-list__table th:nth-child(2),
.commodities-list__table td:nth-child(2) {
  width: 18rem;
  flex-grow: 1;
}
.commodities-list__table th._doc-status-location,
.commodities-list__table td._doc-status-location {
  width: 16rem;
}
.commodities-list__table th._status-location,
.commodities-list__table td._status-location {
  width: 16rem;
}
.commodities-list__table th._note,
.commodities-list__table td._note {
  width: 6rem;
  align-items: center;
}
.commodities-list__table th._actions,
.commodities-list__table td._actions {
  width: 5.4rem;
}
.commodities-list__table th._destination,
.commodities-list__table td._destination {
  width: 18rem;
}
.commodities-list__main-content:not(._full) .commodities-list__table th._destination,
.commodities-list__main-content:not(._full) .commodities-list__table td._destination {
  display: none;
}
.commodities-list__scroll-block .commodities-list__table th._actions,
.commodities-list__scroll-block .commodities-list__table td._actions {
  width: 5.4rem;
}
.commodities-list__cards {
  padding: 1.6rem;
  display: grid;
  gap: 1.4rem;
}
.commodities-list__card {
  min-height: 9rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
  border: thin solid #e0e0e0;
  border-radius: 0.4rem;
  background-color: white;
  min-width: 0;
}
.commodities-list__card-actions {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
}
.commodities-list__card-actions-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
}
.commodities-list__card-actions-content > * {
  margin: 0;
}
.commodities-list__card-block:not(:first-child) {
  margin-top: 1rem;
}
.commodities-list__card-block._row {
  display: flex;
}
.commodities-list__card-block._top-spacing {
  margin-top: 1rem;
}
.commodities-list__card-block._bottom-spacing {
  margin-bottom: 1rem;
}
.commodities-list__card-block._bottom-spacing-2 {
  margin-bottom: 2rem;
}
.commodities-list__card-block-title {
  color: #626262;
  font-weight: 500;
}
.commodities-list__card-block-inner {
  display: block;
  margin-left: 0;
  padding: 0.4rem 0 0.4rem 1rem;
  border-left: 0.2rem solid #808080;
}
.commodities-list__card-block-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commodities-list__card-image {
  margin-right: 1.5rem;
  width: 7rem;
  height: 5.5rem;
  object-fit: cover;
  background: #d8d8d8;
}
.commodities-list__card-description-row {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.commodities-list__card-description-title {
  color: #808080;
  font-weight: 500;
}
.commodities-list__card-description-value:not(:first-child) {
  margin-left: 0.4em;
}
.commodities-list__card-description-value:not(:last-child, :first-child) {
  margin-right: 0.5em;
}
.commodities-list__card-description-value._inner {
  display: block;
  margin-left: 0;
  padding: 0.4rem 0 0.4rem 1rem;
  border-left: thin solid #808080;
}
.commodities-list__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.commodities-list__help-list li {
  margin: 0.6rem 0;
}
.commodities-list__group-amount {
  display: inline-block;
  min-width: 2.5rem;
  min-height: 2.5rem;
  background: white;
  border-radius: 50%;
  text-align: center;
  padding: 0.4rem 0.3rem 0;
  font-weight: 600;
  color: #616161;
}
.commodities-list__customer-profile {
  display: flex;
  align-items: center;
}
.commodities-list__customer-photo-wrap {
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.commodities-list__customer-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodities-list__descr {
  display: flex;
  align-items: center;
}
.commodities-list__photo-wrap {
  background: #d8d8d8;
  overflow: hidden;
  width: 4.5rem;
  height: 3.4rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.commodities-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodities-list__descr-main {
  flex-grow: 1;
}
.commodities-list__vin {
  margin-top: 0.4rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.commodities-list__status {
  margin-bottom: 0.4rem;
}
.commodities-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commodities-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.commodities-list__info {
  padding-top: 1px;
}
.commodities-list__info > * {
  margin-bottom: 0.4rem;
}
.commodities-list__group-btn {
  background: transparent;
  border: 1px solid #dedede;
  text-transform: none;
}
.commodities-list__group-btn svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: rgba(0, 0, 0, 0.65);
}
.commodities-list__group-btn span {
  color: #333333;
}
.commodities-list__group-btn._open,
.commodities-list__group-btn:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.commodities-list__group-btn._open span,
.commodities-list__group-btn:hover span {
  color: white;
}
.commodities-list__group-btn._open svg,
.commodities-list__group-btn:hover svg {
  fill: white;
}
@media screen and (max-width: 1600px) {
  .commodities-list__customer-photo-wrap {
    display: none;
  }
  .commodities-list__photo-wrap {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .commodities-list__header-row {
    background-color: inherit !important;
    border-bottom: none;
  }
  .commodities-list__search {
    margin-right: unset;
    background-color: white;
  }
  .commodities-list__filter {
    margin: unset;
    width: 100%;
  }
}
.commodity-customer__content {
  position: relative;
  border: 1px dashed #b8b8b8;
  padding: 2rem 2rem 3rem;
  border-radius: 0.3rem;
}
.commodity-customer._inactive .commodity-customer__content {
  border-color: #e7a811;
}
.commodity-customer._deleted .commodity-customer__content {
  border-color: #8f1b2c;
}
.commodity-customer__main-info-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.commodity-customer__main-info-wrap:first-child {
  padding-top: 1rem;
}
.commodity-customer__main-info-row {
  display: flex;
}
.commodity-customer__main-info-row._wrap {
  flex-wrap: wrap;
}
.commodity-customer__main-info-row {
  display: flex;
}
.commodity-customer__customer-photo-outer {
  position: relative;
  margin-right: 2rem;
}
.commodity-customer__customer-photo-wrap {
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commodity-customer__customer-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}
.commodity-customer__customer-photo-placeholder {
  opacity: 0.5;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
}
.commodity-customer__customer-edit-btn.btn {
  position: absolute;
  top: 0;
  right: 0;
}
.commodity-customer__main-info {
  width: 18rem;
  margin-right: 2rem;
}
.commodity-customer__customer-photo-outer,
.commodity-customer__main-info {
  margin-bottom: 3rem;
}
.commodity-customer__status {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #14a75d;
}
.commodity-customer__status._inactive {
  color: #e7a811;
}
.commodity-customer__status._deleted {
  color: #8f1b2c;
}
.commodity-customer__status::before {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  display: inline-block;
  border-radius: 50%;
  background: #14a75d;
  vertical-align: 1px;
  margin-right: 0.6rem;
}
.commodity-customer__status._inactive::before {
  background-color: #e7a811;
}
.commodity-customer__status._deleted::before {
  background-color: #8f1b2c;
}
.commodity-customer__contacts-wrap-row {
  display: flex;
  flex-wrap: wrap;
  width: 15%;
  flex: 1 1 auto;
}
.commodity-customer__contacts-group {
  width: max-content;
  min-width: max-content;
  margin-right: 2rem;
  position: relative;
}
.commodity-customer__contacts-group:not(:last-child) {
  margin-bottom: 2rem;
}
.commodity-customer__options-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}
.commodity-customer__toggle.btn {
  position: absolute;
  left: 0;
  bottom: -1.1rem;
  background: white;
  border: 1px dashed #b8b8b8;
  color: #4778ff;
}
.commodity-customer__toggle.btn svg {
  transform: rotate(90deg);
  fill: #4778ff;
}
.commodity-customer__toggle.btn._open svg {
  transform: rotate(270deg);
}
.commodity-customer__full-info {
  height: auto;
  overflow: hidden;
  padding: 3rem 0 0;
}
.commodity-customer__full-info .person-info__section:first-child {
  border-top: 1px solid #e0e0e0;
}
.commodity-customer__full-info .person-info__section:last-child .person-info__contact-wrap {
  margin-bottom: 0;
}
@media (max-width: 980px) {
  .commodity-customer__options-wrap .page-options-menu .dropdown__btn {
    padding-left: 0;
  }
  .commodity-customer__options-wrap .page-options-menu .dropdown__btn-text {
    display: none;
  }
  .commodity-customer__options-wrap .page-options-menu .dropdown__dropdown-inner {
    min-width: 16rem;
  }
  .commodity-customer__status {
    width: max-content;
  }
}
@media (max-width: 749px) {
  .commodity-customer__main-info-wrap {
    padding-bottom: 3rem;
  }
  .commodity-customer__main-info-row {
    flex-wrap: wrap;
  }
  .commodity-customer__main-info-row._wrap {
    flex-direction: column;
  }
}
.commodity-delivery-details__scroll-to-uploading {
  margin-right: 1rem;
}
.commodity-delivery-details__checkbox-cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.commodity-delivery-details__checkbox {
  margin-right: 3rem;
}
.commodity-delivery-details__footer {
  display: flex;
  margin-top: 2rem;
}
.commodity-delivery-details__selected-wrap {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.commodity-delivery-details__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.commodity-delivery-details__logs {
  padding-top: 1.6rem;
  margin-top: 2rem;
  border-top: 1px dashed #dedede;
}
.commodity-delivery-details__customer {
  padding: 0.8rem 1rem 0.8rem;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
  border-radius: 0.3rem;
  line-height: 1.42857143em;
}
.commodity-delivery-info__spec-row {
  display: flex;
  justify-content: space-between;
}
.commodity-delivery-info__spec-col {
  flex-basis: 47%;
  max-width: none;
}
.commodity-info__customer-row {
  display: flex;
  align-items: center;
}
.commodity-info__vin-wrap {
  display: flex;
  align-items: flex-start;
}
.commodity-info__vin-btn {
  margin-right: 1rem;
}
.commodity-info__vin {
  flex-grow: 1;
}
.commodity-info__other-type-textarea {
  width: 100%;
}
.commodity-info__other-type-textarea textarea {
  min-height: 8.8rem;
}
.commodity-info__document {
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px dashed #dedede;
  border-bottom: 1px dashed #dedede;
}
.commodity-info__document + .commodity-info__document {
  border-top: 0;
  margin-top: 0;
}
.commodity-info__documents-table {
  width: 100%;
  margin-bottom: 3rem;
}
.commodity-info__notes {
  list-style: none;
  margin: 0;
  padding: 0;
}
.commodity-info__notes li {
  margin: 0.6rem 0;
  position: relative;
  padding-left: 1rem;
}
.commodity-info__notes li::before {
  position: absolute;
  content: '•';
  left: 0;
}
.commodity-info .editr {
  border-color: #dedede;
  border-radius: 0.3rem;
  overflow: hidden;
}
.commodity-info .editr--toolbar a svg {
  opacity: 0.7;
  border-bottom: #dedede;
}
.event-edit {
  position: relative;
  padding: 1rem 0;
}
.event-edit__date-row {
  display: flex;
  margin-bottom: 2rem;
}
.event-edit__date-group {
  display: flex;
  flex-grow: 1;
}
.event-edit__date-separator {
  margin: 1.6rem 1rem 0;
  width: 0.8rem;
  height: 1px;
  background: #dedede;
}
.event-edit__date {
  width: 10%;
  flex-grow: 1;
}
.event-edit__with-time-btn {
  padding-top: 0.6rem;
  margin-left: 1rem;
}
.event-edit__input {
  margin-bottom: 2rem;
}
.event-edit__calendar-list {
  margin-bottom: 2rem;
}
.event-edit__current,
.event-edit__calendar-list-option {
  display: flex;
  align-items: center;
}
.event-edit__calendar-color {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}
.event-edit__footer {
  display: flex;
  justify-content: flex-end;
}
.event-edit__btn {
  margin-right: 1rem;
}
.v-lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
  overscroll-behavior-x: contain;
  color: white;
}
.v-lightbox__head {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 0 0 2rem;
  width: 100%;
}
.v-lightbox__show-count {
  margin: 0 3rem 0 0;
  font-weight: 600;
  color: #e3ebff;
}
.v-lightbox__btn,
.v-lightbox__btn-slide {
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  padding: 0;
  min-height: 0;
  border-radius: 0;
  background-color: rgba(71, 120, 255, 0.8);
  cursor: pointer;
  transition: background-color 0.15s, opacity 0.15s;
}
.v-lightbox__btn:not(:disabled):hover,
.v-lightbox__btn-slide:not(:disabled):hover {
  text-decoration: unset;
}
.v-lightbox__btn:not(:disabled):hover,
.v-lightbox__btn-slide:not(:disabled):hover {
  text-decoration: unset;
}
@media (max-width: 980px) {
  .v-lightbox__btn,
  .v-lightbox__btn-slide {
    background-color: rgba(71, 120, 255, 0.95);
    width: 3.6rem;
    height: 3.6rem;
  }
}
.v-lightbox__btn svg,
.v-lightbox__btn-slide svg {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 0.8;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.15s;
}
.v-lightbox__btn._disabled,
.v-lightbox__btn-slide._disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.v-lightbox__btn:not(._disabled):hover,
.v-lightbox__btn-slide:not(._disabled):hover {
  background-color: #4778ff;
}
.v-lightbox__btn:not(._disabled):hover svg,
.v-lightbox__btn-slide:not(._disabled):hover svg {
  color: white;
}
.v-lightbox__btn._lock,
.v-lightbox__btn-slide._lock {
  display: none;
}
.v-lightbox__main {
  position: relative;
  width: 100%;
  max-height: calc(100% - 8rem);
  display: flex;
  flex-grow: 1;
}
.v-lightbox__main._single {
  max-height: 100%;
}
.v-lightbox__btn-slide {
  position: absolute;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  overflow: visible;
}
.v-lightbox__btn-slide._prev {
  left: 0;
}
.v-lightbox__btn-slide._next {
  right: 0;
}
.v-lightbox__btn-slide._prev svg {
  transform: rotate(180deg);
}
.v-lightbox__btn-slide::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300%;
  height: 500%;
}
.v-lightbox__list {
  width: 100%;
  max-height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
.v-lightbox__item {
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: translate3d(0, 0, 0);
}
.v-lightbox__item._zoom-out {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 0;
  min-width: 0;
}
.v-lightbox__item._zoom-out .v-lightbox__img-wrap {
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.v-lightbox__item._zoom-out .v-lightbox__img:not(._video) {
  max-width: 100%;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.v-lightbox__item._zoom-out .v-lightbox__img:not(._video) .file-show__file {
  max-width: 100%;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  width: auto;
  height: auto;
  display: inline-block;
  object-fit: fill;
  overflow: hidden;
}
.v-lightbox__img-wrap {
  width: 100%;
  max-width: 100vw;
  max-width: 100dvw;
  max-height: 100vh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  flex-shrink: 0;
  overflow: auto;
  margin: auto;
  padding: 4rem 2rem;
  overscroll-behavior-x: contain;
}
.v-lightbox__img {
  margin: auto;
  height: auto;
  width: auto;
  transition: opacity 0.3s;
  background: rgba(0, 0, 0, 0.2);
}
.v-lightbox__img._loading {
  opacity: 0;
}
.v-lightbox__img._pdf {
  width: 100% !important;
  height: 100% !important;
}
.v-lightbox__img._pdf .file-show__file {
  width: 100% !important;
  height: 100% !important;
}
.v-lightbox__img._other {
  padding: 2rem;
  border-radius: 1rem;
}
.v-lightbox__img._other svg,
.v-lightbox__img._other .file-show__file-ico {
  width: 13rem;
  height: 16rem;
  fill: white;
  object-fit: cover;
}
.v-lightbox__img._image._zoom .file-show__file {
  cursor: zoom-out;
}
.v-lightbox__img .file-show__file {
  width: auto;
  height: auto;
}
.v-lightbox__img._image .file-show__file {
  cursor: zoom-in;
}
.v-lightbox__title {
  margin: 2rem 0;
  flex: 0 0;
  font-size: 1.4rem;
  color: white;
  font-weight: 400;
}
.v-lightbox__img-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.v-lightbox__footer {
  flex-shrink: 0;
}
.v-lightbox__img-error {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 1.2rem;
  background: #8f1b2c;
  border-radius: 0.3rem;
}
.v-lightbox__img-error > span:last-child {
  font-size: 1.2rem;
  opacity: 0.8;
}
.v-lightbox__sublist {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  transition: opacity 0.15s, visibility 0.15s;
}
.v-lightbox__sublist._zoom-active {
  opacity: 0;
  visibility: hidden;
}
.v-lightbox__sublist-container {
  width: 100%;
  overflow: hidden;
}
.v-lightbox__sublist-list {
  display: flex;
  width: 100%;
}
.v-lightbox__sublist-item {
  flex-shrink: 0;
  height: 8rem;
  width: 8rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  margin-right: 1rem;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}
.v-lightbox__sublist-item:last-child {
  margin-right: 0;
}
.v-lightbox__sublist-item:hover {
  border-color: rgba(255, 255, 255, 0.3);
}
.v-lightbox__sublist-item.swiper-slide-thumb-active {
  border-color: rgba(71, 120, 255, 0.6);
}
.v-lightbox__zoom-btn {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.8;
}
.v-lightbox__zoom-btn svg {
  width: 3rem;
  height: 3rem;
  fill: currentColor;
}
.invoices-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.invoices-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.invoices-list__actions-wrap {
  display: flex;
  align-items: center;
}
.invoices-list .files-list__selected {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 980px) {
  .invoices-list .files-list__selected {
    flex-direction: row;
  }
}
@media (max-width: 980px) {
  .invoices-list .files-list__selected {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0.9rem 1.6rem;
  }
}
.invoices-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.invoices-list__search,
.invoices-list__head-btn {
  margin-right: 1rem;
}
.invoices-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.invoices-list__table-wrap {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.invoices-list__table {
  width: 100%;
  margin: 0;
}
.invoices-list__table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.invoices-list__table th {
  border-bottom: 0;
}
.invoices-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.invoices-list__table td:not(._no-link) {
  cursor: pointer;
  transition: background-color 0.15s;
}
.invoices-list__table th:first-child,
.invoices-list__table td:first-child {
  width: 4.8rem;
}
.invoices-list__table th:last-child,
.invoices-list__table td:last-child {
  width: 5.4rem;
}
.invoices-list__filters {
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 2;
  border: 1px solid #dedede;
  border-left: 0;
  border-right: 0;
  padding: 1.2rem 2rem;
  display: flex;
  gap: 1.5rem 2rem;
  margin-top: auto;
}
.invoices-list__filter-select {
  width: 20rem;
}
.invoices-list__filter-select .select__option._disabled {
  display: none;
}
.invoices-list__dates-range {
  width: 25rem;
}
.invoices-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invoices-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.invoices-list__info {
  padding-top: 1px;
}
.invoices-list__info > * {
  margin-bottom: 0.4rem;
}
.invoices-list._short .invoices-list__header {
  border-bottom: 1px solid #dedede;
  top: 0;
}
.invoices-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  font-size: 1.2rem;
  cursor: pointer;
}
.invoices-list__short-item._selected {
  background-color: #e3ebff;
}
.invoices-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.invoices-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.invoices-list__short-el {
  width: 48%;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.invoices-list__short-el._right {
  text-align: right;
}
.invoices-list__mob-items-wrap {
  padding: 0 1.8rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.invoices-list__mob-item {
  width: 100%;
  max-width: calc(100% / 2 - 0.8rem);
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 1.2rem 4rem 1.2rem 0.8rem;
  margin-top: 1rem;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 749px) {
  .invoices-list__mob-item {
    max-width: none;
  }
}
.invoices-list__mob-item-row {
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.invoices-list__mob-item-actions {
  position: absolute;
  padding: 1.1rem;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invoices-list__mob-item-actions > * {
  margin-bottom: 1.2rem;
}
.invoices-list__mob-item-actions > *:last-child {
  margin-bottom: 0;
}
.invoices-list__unapplied-amount {
  color: #e17d10;
  cursor: help;
}
.invoices-list__short-item-col {
  width: 48%;
  font-size: 1.2rem;
}
.invoices-list__short-item-col._right {
  text-align: right;
}
.invoices-list__short-item-cell {
  margin-bottom: 0.3rem;
}
.invoices-list__short-item-cell:last-child {
  margin-bottom: 0;
}
.rec-invoices-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.rec-invoices-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.rec-invoices-list__search,
.rec-invoices-list__head-btn {
  margin-right: 1rem;
}
.rec-invoices-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.rec-invoices-list__table {
  width: 100%;
  margin: 0;
}
.rec-invoices-list__table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.rec-invoices-list__table th._actions {
  width: 4.4rem;
}
.rec-invoices-list__table thead th {
  background-color: #d2d2d2;
  background-color: #dedede;
  border-bottom: 0;
}
.rec-invoices-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.rec-invoices-list__table td:not(._no-link) {
  cursor: pointer;
  transition: background-color 0.15s;
}
.rec-invoices-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rec-invoices-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.rec-invoices-list__info {
  padding-top: 1px;
}
.rec-invoices-list__info > * {
  margin-bottom: 0.4rem;
}
.rec-invoices-list._short .rec-invoices-list__header {
  border-bottom: 1px solid #dedede;
}
.rec-invoices-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.rec-invoices-list__short-item._selected {
  background-color: #e3ebff;
}
.rec-invoices-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.rec-invoices-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.rec-invoices-list__short-el {
  width: 48%;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.rec-invoices-list__short-el._right {
  text-align: right;
}
.one-customer {
  position: relative;
}
.one-customer__info {
  margin-bottom: 2rem;
}
.one-customer__tabs {
  margin-top: 1.5rem;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.one-customer__tabs._full {
  margin-top: 0;
  height: auto;
}
@media (max-width: 749px) {
  .one-customer__tabs {
    margin-top: 0;
    height: auto;
    overflow: visible;
  }
}
.light-table {
  background: transparent;
}
.light-table th,
.light-table td {
  padding: 1rem 1rem;
  background: transparent;
}
.light-table th:first-child,
.light-table td:first-child {
  padding-left: 0;
}
.light-table th:last-child,
.light-table td:last-child {
  padding-right: 0;
}
.light-table th {
  border-bottom-width: 0.2rem;
}
.commodity-table {
  width: 100%;
}
.commodity-table th._checkbox {
  width: 5rem;
}
.commodity-table th._descr {
  width: 24%;
}
.commodity-table th._note {
  width: 20%;
}
.commodity-table__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.commodity-table__help-list li {
  margin: 0.6rem 0;
}
.commodity-table tr._selected {
  background: rgba(255, 233, 177, 0.4);
}
.commodity-table__group-amount {
  display: inline-block;
  min-width: 2.5rem;
  min-height: 2.5rem;
  background: white;
  border-radius: 50%;
  text-align: center;
  padding: 0.4rem 0.3rem 0;
  font-weight: 600;
  color: #616161;
}
.commodity-table__customer-profile {
  display: flex;
  align-items: center;
}
.commodity-table__customer-photo-wrap {
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1.5rem;
}
.commodity-table__customer-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodity-table__descr {
  display: flex;
  align-items: center;
}
.commodity-table__descr-main {
  width: 20rem;
  flex: 1 1 20rem;
}
.commodity-table__photo-wrap {
  background: #d8d8d8;
  overflow: hidden;
  width: 6.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.commodity-table__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodity-table__vin {
  margin-top: 0.4rem;
}
.commodity-table__name,
.commodity-table__vin {
  width: 100%;
}
.commodity-table__status {
  margin-bottom: 0.4rem;
}
.commodity-table__status:last-child {
  margin-bottom: 0;
}
.commodity-select__search {
  width: 100%;
  display: flex;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
}
.commodity-select__search-input-wrap {
  display: flex;
  flex-grow: 1;
}
.commodity-select__search-ico {
  width: 1.8rem;
  height: 1.8rem;
  align-self: center;
  flex-shrink: 0;
  margin-left: 1rem;
  fill: #dedede;
}
.commodity-select__search-input {
  border: 0;
  border-radius: 0;
  flex-grow: 1;
}
.commodity-select__search-clear {
  position: relative;
  background: transparent !important;
  border-radius: 0;
  flex-shrink: 0;
  padding: 0;
  border: 0 !important;
  width: 2.9rem;
}
.commodity-select__search-clear svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #b8b8b8;
}
.commodity-select__search-clear:hover svg {
  fill: #8f1b2c;
}
.commodity-select__search-clear::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(157, 157, 157, 0.8), rgba(255, 255, 255, 0));
}
.commodity-select__head {
  display: flex;
  padding: 0 0 2rem;
}
.commodity-select__head-separator {
  margin: 0 auto;
}
.commodity-select__head-btn {
  margin-right: 1rem;
}
.commodity-select__head-btn:last-child {
  margin-right: 0;
}
.commodity-select__head-btn._disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.commodity-select__table-wrap {
  position: relative;
}
.commodity-select__table {
  width: 100%;
  margin: 0;
}
.commodity-select__table tbody tr {
  cursor: pointer;
}
.commodity-select__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commodity-select__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.commodity-select__info {
  padding-top: 1px;
}
.commodity-select__info > * {
  margin-bottom: 0.4rem;
}
.upload-files._disabled .upload-files__btn {
  opacity: 0.5;
  pointer-events: none;
}
.upload-files__supported {
  font-size: 1.2rem;
  color: #808080;
  margin-top: 0.5rem;
}
.upload-files__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -1rem 0 0 -1rem;
}
.upload-files._centered .upload-files__list {
  justify-content: center;
}
.upload-files._large .upload-files__list {
  margin: -2rem 0 0 -2rem;
}
.upload-files__item,
.upload-files__btn {
  margin: 1rem 0 0 1rem;
}
.upload-files__item {
  flex: 0;
}
.upload-files__item:first-child:last-child {
  flex: 0 1 auto;
}
.upload-files__item._error .upload-files__item-content {
  border-color: #8f1b2c;
}
.upload-files__item .preloader {
  z-index: 4;
}
.upload-files__item-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.6rem;
  z-index: 6;
}
.upload-files__item-progress-inner {
  width: 0;
  height: 100%;
  transition: width 0.15s;
  background-color: #14a75d;
}
.upload-files__item._loading .upload-files__checkbox,
.upload-files__item._loading .upload-files__name,
.upload-files__item._loading .upload-files__name-edit {
  opacity: 0.5;
  pointer-events: none;
}
.upload-files__item-content,
.upload-files__btn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 0.3rem;
  border: 1px solid #dedede;
  overflow: hidden;
}
.upload-files._large .upload-files__item-content,
.upload-files._large .upload-files__btn {
  width: 12rem;
  height: 12rem;
}
.upload-files._large .upload-files__item,
.upload-files._large .upload-files__btn {
  margin: 2rem 0 0 2rem;
}
.upload-files__img {
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}
.upload-files input {
  display: none;
}
.upload-files__file-placeholder {
  width: 3.4rem;
  height: 3.4rem;
  opacity: 0.4;
  margin-top: 0.3rem;
}
.upload-files__file-name {
  margin: 0.6rem auto 0;
  width: calc(100% - 1rem);
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  min-width: 12rem;
}
.upload-files__btn {
  cursor: pointer;
  padding: 0.4rem 0.2rem 0.2rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.33333333em;
  color: #808080;
  transition: 0.15s;
}
.upload-files__btn:hover {
  border-color: #4778ff;
  color: #4778ff;
}
.upload-files__btn svg {
  width: 1rem;
  height: 1.6rem;
  margin-bottom: 0.4rem;
  fill: #808080;
}
.upload-files__btn:hover svg {
  fill: #4778ff;
}
.upload-files__btn._drop-area {
  flex: 1 1 100%;
}
.upload-files__delete {
  position: absolute;
  z-index: 5;
  top: 0.2rem;
  right: 0.2rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  padding: 0;
  background: #b8b8b8;
  cursor: pointer;
  transition: 0.15s;
}
.upload-files__delete svg {
  fill: white;
}
.upload-files__delete:hover {
  opacity: 1;
  border-color: #aa0300;
  background-color: #aa0300;
}
.upload-files__checkbox {
  position: absolute;
  z-index: 2;
  top: 0.2rem;
  left: 0.2rem;
  opacity: 0.8;
  transition: opacity 0.15s;
}
.upload-files__checkbox._checked {
  opacity: 1;
}
.upload-files__checkbox .checkbox__mark {
  border-color: white;
  background: rgba(51, 51, 51, 0.2);
}
.upload-files__name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.4rem;
  width: 100%;
  font-size: 1.2rem;
  color: #1f1f1f;
  white-space: normal;
  word-break: break-word;
  transition: opacity 0.15s;
  min-width: 12rem;
}
.upload-files__name-edit-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  width: 1.8rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
}
.upload-files__name-edit-btn:not(:disabled):hover {
  text-decoration: unset;
}
.upload-files__name-edit-btn:not(:disabled):hover {
  text-decoration: unset;
}
.upload-files__name-edit-btn svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: #616161;
  transition: 0.15s;
}
.upload-files__name-edit-btn:hover svg {
  fill: #4778ff;
}
.upload-files__name-edit-btn._accept svg {
  width: 1.2rem;
}
.upload-files__name-edit-btn._accept:hover svg {
  fill: #14a75d;
}
.upload-files__name-edit-btn._cancel svg {
  width: 1rem;
}
.upload-files__name-edit-btn._cancel:hover svg {
  fill: #8f1b2c;
}
.upload-files__name-edit {
  margin-top: 0.4rem;
  display: flex;
  align-items: flex-start;
  transition: opacity 0.15s;
  min-width: 12rem;
  flex: 1;
}
.upload-files__name-edit-input {
  width: 50%;
  flex-grow: 1;
  padding: 0.5rem;
}
.inventor-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.inventor-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.inventor-list__search,
.inventor-list__head-btn {
  margin-right: 1rem;
}
.inventor-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.inventor-list__filter {
  display: flex;
  margin-top: -1rem;
  margin-left: auto;
}
.inventor-list__filter-item {
  background: transparent;
  border: 1px solid #dedede;
  min-height: 3rem;
  margin: 1rem 1.5rem 0 0;
  color: #333333;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: none;
}
.inventor-list__filter-item:hover {
  border-color: #14a75d;
  background: transparent;
  color: #14a75d;
}
.inventor-list__filter-item:last-child {
  margin-right: 0;
}
.inventor-list__filter-item._active-exact {
  background-color: #14a75d;
  border-color: #14a75d;
  color: white;
}
.inventor-list__table {
  width: 100%;
  margin: 0;
}
.inventor-list__table thead {
  position: sticky;
  top: 12.4rem;
  z-index: 32;
}
.inventor-list__table th._price {
  width: 10rem;
}
.inventor-list__table th._notify {
  width: 4.4rem;
}
.inventor-list__table td:first-child {
  width: 5rem;
}
.inventor-list__table td:nth-child(2) {
  width: 7.5rem;
  padding-right: 0;
}
.inventor-list__table td:last-child {
  width: 4.4rem;
}
.inventor-list__table td._vendor {
  border-left: 1px dashed #dedede;
}
.inventor-list__table td:nth-last-child(-n + 3) {
  border-bottom: 0;
}
.inventor-list__table td {
  border-bottom: 1px dashed #dedede;
}
.inventor-list__table._used td {
  border-bottom: 0;
}
.inventor-list__table tr:last-child td {
  border-bottom: 1px solid #dedede;
}
.inventor-list__photo-wrap {
  position: relative;
  background: #d8d8d8;
  overflow: hidden;
  width: 6.5rem;
  height: 4.8rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.inventor-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inventor-list__light-btn {
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
}
.inventor-list__light-btn:hover {
  text-decoration: none;
  color: #4778ff;
}
.inventor-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inventor-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.inventor-list__info {
  padding-top: 1px;
}
.inventor-list__info > * {
  margin-bottom: 0.4rem;
}
.inventory-edit__input {
  margin-bottom: 2rem;
}
.inventory-edit__footer {
  display: flex;
  justify-content: flex-end;
}
.inventory-edit__btn {
  margin-right: 1rem;
}
.inventory-edit__btn:last-child {
  margin-right: 0;
}
.inventory-edit__alert {
  margin-bottom: 3rem;
}
.inventory-edit__img-wrap {
  display: block;
  width: 8.5rem;
  background: #f2f2f2;
  position: relative;
  margin-right: 1rem;
  flex-shrink: 0;
}
.inventory-edit__img-wrap::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 100%;
}
a.inventory-edit__img-wrap:hover {
  opacity: 0.8;
  cursor: zoom-in;
}
.inventory-edit__img-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.inventory-edit__main {
  display: flex;
  align-items: flex-start;
}
.inventory-edit__main-inputs {
  flex: 1;
}
.booking-edit {
  position: relative;
  min-height: 50rem;
  padding-top: 20px;
}
.booking-edit__head-btn {
  border-color: #b8b8b8;
}
.booking-edit__copy-from-prev-wrap {
  display: flex;
  align-items: center;
}
.booking-edit__copy-from-prev-title {
  margin: 0 1rem 0 0;
  font-size: 1.2rem;
  color: #333333;
  text-transform: none;
}
.booking-edit__copy-from-prev {
  width: 40rem;
}
.booking-edit__footer {
  padding: 4.5rem 2rem 3rem;
  border-top: 1px solid #dedede;
}
.booking-edit__logs {
  margin-bottom: 2.4rem;
}
.booking-edit__error {
  margin-bottom: 2.5rem;
}
.booking-edit__footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-edit__footer-separator {
  margin: 0 auto;
  width: 1rem;
}
.booking-edit__block {
  margin-bottom: 1px;
}
.booking-edit__btn {
  margin-left: 1rem;
}
.booking-edit__btn-left {
  margin-right: 1rem;
}
.file-commodity {
  width: 100%;
  min-width: 104rem;
  table-layout: fixed;
}
.file-commodity__table-section:last-child > tr > td {
  border-bottom: 0;
}
.file-commodity._editable .file-commodity__cell._customer {
  width: 26rem;
}
.file-commodity__toggle-cell {
  width: 5.2rem;
  vertical-align: top;
}
.file-commodity > tbody > tr > td {
  background-color: #f2f2f2;
}
.file-commodity__separator-row > td {
  background-color: white !important;
  padding: 0.5rem;
}
.file-commodity > tbody:last-child .file-commodity__separator-row {
  display: none;
}
.file-commodity__commodity .hide-particle__placeholder::before {
  background: linear-gradient(to left, #f2f2f2, rgba(242, 242, 242, 0));
}
.file-commodity__cell {
  vertical-align: top;
}
.file-commodity__cell._commodity {
  padding: 0;
}
.file-commodity__head-cell._customer,
.file-commodity__cell._customer {
  width: 20rem;
}
.file-commodity__head-cell._commodity-info,
.file-commodity__cell-content._commodity-info {
  width: auto;
  flex-grow: 1;
}
.file-commodity__cell-content._commodity-info {
  width: 10rem;
}
.file-commodity__cell-content._commodity-info:first-child {
  margin-left: 1rem;
}
.file-commodity__head-cell._docs,
.file-commodity__cell-content._docs {
  width: 15.7rem;
}
.file-commodity._editable .file-commodity__head-cell._docs,
.file-commodity._editable .file-commodity__cell-content._docs {
  width: 20rem;
}
.file-commodity__head-cell._status-location,
.file-commodity__cell-content._status-location {
  width: 16.4rem;
}
.file-commodity._editable .file-commodity__head-cell._status-location,
.file-commodity._editable .file-commodity__cell-content._status-location {
  width: 16.4rem;
}
.file-commodity__head-cell._note,
.file-commodity__cell-content._note {
  width: 5rem;
}
.file-commodity._editable .file-commodity__head-cell._note,
.file-commodity._editable .file-commodity__cell-content._note {
  width: 6rem;
}
.file-commodity__head-cell._options,
.file-commodity__cell-content._options {
  width: 5.2rem;
  flex-shrink: 0;
}
.file-commodity._editable .file-commodity__head-cell._options,
.file-commodity._editable .file-commodity__cell-content._options {
  width: 9.7rem;
}
.file-commodity__commodity-row {
  position: relative;
  display: flex;
  padding: 0;
}
.file-commodity__commodity-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  right: 2rem;
  height: 1px;
  border-bottom: 1px dashed #dbdbdb;
  opacity: 0;
  transition: 0.15s;
}
.file-commodity__commodity-row._main::before {
  display: none;
}
.file-commodity__table-section._open .file-commodity__commodity-row::before {
  opacity: 1;
}
.file-commodity__cell-content {
  padding: 1.2rem 1rem;
  flex-shrink: 0;
}
.file-commodity__cell-content:last-child {
  padding-right: 2rem;
}
.file-commodity__cell-content._options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-commodity__amount {
  display: inline-block;
  min-width: 1.7rem;
  height: 1.7rem;
  background: white;
  box-shadow: 0 0 0.7rem rgba(162, 162, 162, 0.5);
  border-radius: 0.3rem;
  opacity: 1;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  transition: 0.15s;
}
.file-commodity__amount._absolute {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.file-commodity__commodity {
  cursor: pointer;
}
.file-commodity__table-section._open .file-commodity__amount {
  opacity: 0;
}
.file-commodity__cell-content {
  position: relative;
}
.file-commodity__cell-list .file-commodity__cell-content {
  height: 5.9rem;
  padding-top: 1.3rem;
}
.file-commodity__toggle {
  padding: 0;
  min-width: 2.2rem;
  min-height: 2.2rem;
  background: transparent;
  box-shadow: 0 0 0.6rem rgba(187, 187, 187, 0.5);
}
.file-commodity__toggle svg {
  width: 0.8rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: #333333;
}
.file-commodity__toggle:hover {
  background-color: #4778ff;
}
.file-commodity__toggle:hover svg {
  fill: white;
}
.file-commodity__table-section._open .file-commodity__toggle,
.file-commodity__card._open .file-commodity__toggle {
  background: #4778ff;
}
.file-commodity__table-section._open .file-commodity__toggle svg,
.file-commodity__card._open .file-commodity__toggle svg {
  transform: rotate(270deg);
  fill: white;
}
.file-commodity__table-tabs-row > td {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom-width: 0;
}
.file-commodity__table-section._open .file-commodity__table-tabs-row > td {
  border-bottom-width: 1px;
}
.file-commodity__tabs-wrap {
  padding-bottom: 2rem;
}
.file-commodity__tabs {
  padding: 1rem 0 0;
  background: transparent;
  border-color: #dedede;
}
.file-commodity__tabs-block {
  padding: 1rem 0 0rem;
}
.file-commodity__estimates-table {
  width: 100%;
  margin: 0;
}
.file-commodity__light-btn {
  padding: 0;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  margin-right: 2.2rem;
  background: transparent;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
}
.file-commodity__light-btn:hover {
  text-decoration: none;
  color: #4778ff;
}
.file-commodity__row {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
}
.file-commodity__row-val {
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  margin-right: 2rem;
}
.file-commodity__row-btn {
  margin-right: 1rem;
}
.file-commodity__card {
  display: block;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  background-color: white;
  margin-bottom: 1.4rem;
  font-size: 1.2rem;
}
.file-commodity__card:last-child {
  margin-bottom: 0;
}
.file-commodity__card-head {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 1.5rem 1rem 1.5rem;
  background-color: #f2f2f2;
}
.file-commodity__card-commodity {
  padding: 1rem;
}
.file-commodity__card-commodity:not(._first) {
  border-top: 1px dashed #e0e0e0;
}
.file-commodity__card-commodity .file-commodity__amount {
  right: auto;
  left: 0.2rem;
}
.file-commodity__card-commodity .hide-particle__placeholder:before {
  display: none;
}
.file-commodity__card-commodity .hide-particle__overlay {
  display: none;
}
.file-commodity__card-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1rem;
}
.file-commodity__card-row:last-child {
  margin-bottom: 0;
}
.file-commodity__card-row:nth-child(1),
.file-commodity__card-row:nth-child(2) {
  padding-right: 4rem;
}
.file-commodity__card-row-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-commodity__card .file-commodity__tabs-wrap {
  padding: 0;
  background-color: #f2f2f2;
}
.file-commodity__card .file-commodity__tabs {
  padding: 0 1rem;
  font-size: 1.2rem;
}
.file-commodity__card .file-customer-documents__content {
  margin-bottom: 0;
  padding-bottom: 0;
}
.file-commodity__card .file-commodity__tabs-block {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (max-width: 1100px) {
  .file-commodity {
    display: block;
    min-width: auto;
    max-width: none;
  }
}
.file-documents {
  position: relative;
  padding: 1rem 2rem;
}
.file-documents__content {
  padding: 2rem 1rem;
  margin: -2rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.file-documents__item {
  padding: 1rem;
}
.file-documents__add,
.file-documents__file {
  width: 9rem;
  cursor: pointer;
}
.file-documents__add-main,
.file-documents__file-main {
  width: 9rem;
  height: 9rem;
  border-radius: 0.3rem;
  border: 1px dashed #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background: white;
  transition: 0.15s;
}
.file-documents__add:hover .file-documents__add-main,
.file-documents__file:hover .file-documents__file-main {
  border-color: #b8b8b8;
}
.file-documents__add-btn {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.3rem;
  background: #4778ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-documents__add-btn svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: white;
}
.file-documents__add-name,
.file-documents__file-name {
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
}
.file-documents__file-main {
  position: relative;
  border-style: solid;
  padding: 2.2rem 1rem 0.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2em;
  color: #808080;
}
.file-documents__file-main-ico {
  width: 2.8rem;
  height: 2.8rem;
  fill: #4778ff;
  margin-bottom: 0.5rem;
}
.file-documents__file-check-wrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
.file-documents__error {
  margin-top: 1rem;
}
.document-versions__list-wrap {
  overflow: hidden;
}
.document-versions__list {
  display: flex;
  width: 100%;
}
.document-versions__item {
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: zoom-in;
  border: 1px dashed #dedede;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background: white;
  padding: 1rem 0.5rem;
  transition: 0.15s;
}
.document-versions__item:last-child {
  margin-right: 0;
}
.document-versions__item:hover {
  text-decoration: underline;
  background-color: transparent;
}
.document-versions__item svg {
  width: 2rem;
  height: 3rem;
  fill: #e17d10;
  margin-bottom: 0.2rem;
  transition: 0.15s;
}
.document-versions__item:hover svg {
  fill: #ff7813;
}
.document-versions__item-name {
  text-align: center;
  line-height: 1.2em;
}
.aes-itn-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.aes-itn-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.aes-itn-list__search,
.aes-itn-list__head-btn {
  margin-right: 1rem;
}
.aes-itn-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.aes-itn-list__only-booking {
  margin-left: auto;
}
.aes-itn-list__table {
  width: 100%;
  margin: 0;
}
.aes-itn-list__table th:last-child {
  width: 4.4rem;
}
.aes-itn-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aes-itn-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.aes-itn-list__info {
  padding-top: 1px;
}
.aes-itn-list__info > * {
  margin-bottom: 0.4rem;
}
.files-attach-document__select,
.files-attach-document__file {
  margin-bottom: 2rem;
}
.files-attach-document__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.files-attach-document__btn {
  margin-right: 1rem;
}
.files-attach-document__btn:last-child {
  margin-right: 0;
}
.files-all-documents__table {
  width: 100%;
  margin: 0;
}
.files-all-documents__table col._edit {
  text-align: right;
}
.files-all-invoices__table {
  width: 100%;
}
.shipping-file-reports {
  padding: 2rem;
}
.shipping-file-reports__btn {
  margin-right: 2rem;
}
.shipping-file-reports__btn:last-child {
  margin-right: 0;
}
.shipping-file-expenses {
  position: relative;
}
.shipping-file-expenses .expenses-table thead {
  top: 0;
}
.steps {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  counter-reset: counter;
}
.steps::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background: #4778ff;
  transform: translateY(-1px);
}
.steps__step {
  position: relative;
  counter-increment: counter;
  border: 0;
  padding: 0;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  box-shadow: inset 0 0 0 1px #dedede;
  transition: 0.15s;
}
.steps__step:last-child {
  margin-right: 0;
}
.steps__step._filled {
  background-color: #4778ff;
  box-shadow: none;
  color: white;
}
.steps__step:hover,
.steps__step._active {
  box-shadow: inset 0 0 0 1px #4778ff;
}
.steps__step::before {
  content: counter(counter);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 1.5rem;
  background: #dedede;
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  transition: 0.15s;
}
.steps__step:hover::before {
  box-shadow: inset 0 0 0 1px #4778ff;
}
.steps__step._active::before {
  box-shadow: none;
}
.steps__step._filled::before,
.steps__step._active::before {
  background-color: #4778ff;
  color: white;
}
.steps__step._filled::before {
  box-shadow: inset 0 0 0 1px white;
}
.steps__step._active::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 0.5rem solid #4778ff;
  border-bottom: 0;
  border-left-color: transparent;
  border-right-color: transparent;
}
.steps__step-value {
  padding: 0.4rem 1.4rem 0.6rem 0.8rem;
  text-align: left;
  font-size: 1.2rem;
}
.aes-itn-edit__steps {
  margin-bottom: 1.6rem;
}
.aes-itn-edit__step-block {
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  background: white;
  transition: 0.15s;
}
.add-new-document-type__input {
  margin-bottom: 2rem;
}
.add-new-document-type__footer {
  display: flex;
  justify-content: flex-end;
}
.add-new-document-type__btn {
  margin-right: 1rem;
}
.add-new-document-type__btn:last-child {
  margin-right: 0;
}
.photo-edit__info {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.photo-edit__photo-wrap {
  width: 8rem;
  height: 8rem;
  border: 1px solid #dedede;
  flex-shrink: 0;
  margin-right: 2rem;
}
.photo-edit__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.photo-edit__current-name {
  font-size: 1.4rem;
}
.photo-edit__input {
  margin-bottom: 2rem;
}
.photo-edit__footer {
  display: flex;
  justify-content: flex-end;
}
.photo-edit__btn {
  margin-right: 1rem;
}
.photo-edit__btn:last-child {
  margin-right: 0;
}
.contacts-creator {
  background: white;
}
.contacts-creator__item {
  position: relative;
  padding: 0 2.4rem;
  margin: 0 0 2.4rem;
}
.contacts-creator__item:not(:first-child) {
  margin-top: 3rem;
}
.contacts-creator__item._contains {
  border: 1px dashed #dedede;
  border-radius: 0.3rem;
  margin: 3.5rem 1rem 3rem;
  padding: 0 1.2rem;
}
.contacts-creator__item._contains:first-child {
  margin-top: 0;
}
.contacts-creator__item-add-wrap {
  position: relative;
  margin-bottom: -1rem;
}
.contacts-creator__item-add-wrap::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -2.4rem;
  width: calc(100% + 4.8rem);
  border-bottom: 1px dashed #b8b8b8;
}
.contacts-creator__item:first-child .contacts-creator__item-main:first-child {
  margin-top: 1.4rem;
}
.contacts-creator__item._contains .contacts-creator__item-add-wrap::before {
  display: none;
}
.contacts-creator__item-add-btn {
  position: relative;
  z-index: 2;
  background: white;
  padding: 0 0.6rem;
  margin-left: -0.6rem;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
  transition: 0.15s;
}
.contacts-creator__item-add-btn:hover {
  color: #4778ff;
}
.contacts-creator__item-main {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 1.4rem 0;
}
.contacts-creator__item-main:not(:first-child) {
  margin-top: 2.4rem;
}
.contacts-creator__item-main:first-child {
  margin-top: 4rem;
}
.contacts-creator__item-main._error {
  margin-top: -1rem;
}
.contacts-creator__item-main:not(:first-child):not(.contacts-creator__item-main._error):before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px dotted #d8d8d8;
  top: -1.2rem;
}
.contacts-creator__item-select {
  width: 11.2rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.contacts-creator__item-input-wrap {
  position: relative;
  flex-grow: 1;
}
.contacts-creator__item-input-title {
  margin: 0.5rem 0 1.8rem;
  font-size: 1.2rem;
}
.contacts-creator__item._address .contacts-creator__item-input-wrap {
  padding-left: 1rem;
  border-left: 1px solid #dedede;
}
.contacts-creator__item-input {
  width: 100%;
}
.contacts-creator__item-input .input__input {
  padding-right: 3.2rem;
}
.contacts-creator__ex-date {
  width: 15.6rem;
  margin-left: 1rem;
}
.contacts-creator__ex-date .date-time-picker .flex {
  justify-content: flex-end;
}
.contacts-creator__item-additional {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  flex-shrink: 0;
  width: 15rem;
  min-height: 3.4rem;
}
.contacts-creator__item-delete-btn.btn {
  border: 0 !important;
  background: #f2f2f2;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 0.5rem;
  margin-right: 1rem;
}
.contacts-creator__item-delete-btn.btn svg {
  width: 1.2rem;
  height: 1.2rem;
}
.contacts-creator__item-delete-btn.btn:hover {
  background-color: #aa0300 !important;
}
.contacts-creator__birthday {
  font-size: 1.2rem;
  line-height: 1.2em;
}
.contacts-creator__item-check-props {
  display: flex;
  align-items: center;
  margin: 1px 1rem 0 -0.5rem;
}
.contacts-creator__item-check-prop {
  position: relative;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.contacts-creator__item-check-prop input {
  display: none;
}
.contacts-creator__item-check-prop-ico {
  display: flex;
}
.contacts-creator__item-check-prop-ico svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: #b8b8b8;
  transition: fill 0.15s;
}
.contacts-creator__item-check-prop-check {
  position: absolute;
  z-index: 5;
  top: -0.4rem;
  right: -0.4rem;
  background: #14a75d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.15s;
}
.contacts-creator__item-check-prop-check svg {
  width: 0.7rem;
  height: 0.7rem;
  fill: white;
}
.contacts-creator__item-check-prop._checked .contacts-creator__item-check-prop-check {
  opacity: 1;
}
.contacts-creator__sub-accounts {
  margin-top: 0;
  width: 100%;
}
.contacts-creator__sub-accounts td:first-child,
.contacts-creator__sub-accounts th:first-child {
  width: 2.4rem;
  padding-right: 0;
}
.employees-list__header {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 5;
}
.employees-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.employees-list__search,
.employees-list__head-btn {
  margin-right: 1rem;
}
.employees-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.employees-list__add-btn {
  margin-left: auto;
  font-size: 2rem;
  line-height: 1em;
}
.employees-list__table {
  width: 100%;
  margin: 0;
}
.employees-list__table thead {
  position: sticky;
  top: 5.4rem;
  z-index: 4;
}
.employees-list__table th:last-child {
  width: 4.4rem;
}
.employees-list__table tbody tr {
  cursor: pointer;
}
.employees-list__table tbody tr:hover {
  background-color: #f2f2f2;
}
.employees-list__table td._checkbox {
  width: 4.8rem;
}
.employees-list__table td._profile {
  width: 23%;
}
.employees-list__table td._actions {
  width: 5.4rem;
}
.employees-list__table td:first-child {
  width: 4.5rem;
}
.employees-list__table td:last-child {
  width: 5.5rem;
}
.employees-list__profile {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.employees-list__photo-wrap {
  background: #dedede;
  border-radius: 50%;
  overflow: hidden;
  width: 4.5rem;
  min-width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.employees-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.employees-list__photo-placeholder {
  opacity: 0.5;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.employees-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.employees-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.employees-list__info {
  padding-top: 1px;
}
.employees-list__info > * {
  margin-bottom: 0.4rem;
}
.employees-list._short .employees-list__header {
  border-bottom: 1px solid #dedede;
}
.employees-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.employees-list__short-item._selected {
  background-color: #e3ebff;
}
.employees-list__short-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 25rem;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
}
.employees-list-mob {
  padding: 0 18px;
}
.employees-list-mob__item {
  position: relative;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 20px 12px 8px;
  margin-top: 10px;
}
.employees-list-mob__item-row {
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.time-log-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.time-log-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.time-log-list__header-row {
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.time-log-list__header-row._sticky {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background-color: white;
}
.time-log-list__employee-select {
  width: 25rem;
  margin-right: 1rem;
  min-width: 0;
}
@media (max-width: 980px) {
  .time-log-list__employee-select {
    width: auto;
    flex: 1;
  }
}
.time-log-list .files-list__selected {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 980px) {
  .time-log-list .files-list__selected {
    flex-direction: row;
  }
}
@media (max-width: 980px) {
  .time-log-list .files-list__selected {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0.9rem 1.6rem;
  }
}
.time-log-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.time-log-list__search,
.time-log-list__head-btn {
  margin-right: 1rem;
}
.time-log-list__search {
  max-width: 45%;
  min-width: 0;
}
@media (max-width: 980px) {
  .time-log-list__search {
    flex: 1;
    max-width: none;
  }
}
.time-log-list__search .search {
  width: 100%;
}
.time-log-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.time-log-list__table-wrap {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.time-log-list__table-wrap > .time-log-list__table > thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.time-log-list__table {
  margin: 0;
  width: 100%;
}
.time-log-list__table > colgroup > col._checkbox {
  width: 4.8rem;
}
.time-log-list__table > colgroup > col._amount {
  width: 15rem;
}
.time-log-list__table > colgroup > col._actions {
  width: 4rem;
}
.time-log-list__table > tbody > tr > td:last-child,
.time-log-list__table > tbody > tr > th:last-child {
  text-align: right;
}
.time-log-list__filters {
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 2;
  border: 1px solid #dedede;
  border-left: 0;
  border-right: 0;
  padding: 1.2rem 2rem;
  display: flex;
  gap: 1.5rem 2rem;
  margin-top: auto;
}
.time-log-list__filter-select {
  width: 20rem;
}
.time-log-list__filter-select .select__option._disabled {
  display: none;
}
.time-log-list__dates-range {
  width: 25rem;
}
@media (max-width: 980px) {
  .time-log-list__table-wrap {
    padding: 0 1.8rem;
  }
  .time-log-list__table,
  .time-log-list__table > tbody {
    display: block;
  }
  .time-log-list__table > colgroup,
  .time-log-list__table > thead {
    display: none;
  }
  .time-log-list__table > tbody > tr {
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    margin-top: 1.4rem;
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    position: relative;
  }
  .time-log-list__table > tbody > tr > td {
    border: 0;
    padding: 0;
    text-align: left !important;
    margin-bottom: 1rem;
    vertical-align: baseline;
  }
  .time-log-list__table > tbody > tr > td::before {
    content: attr(data-title);
    font-weight: 600;
  }
  .time-log-list__table > tbody > tr > td._actions {
    position: absolute;
    padding: 1.1rem;
    top: 0;
    right: 0;
  }
  .time-log-list .preview-person-with-photo {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.time-log-list__deductions-table {
  margin: 0;
}
.time-log-list__deductions-table th,
.time-log-list__deductions-table td {
  padding: 0.3rem 0;
  background: 0;
}
.time-log-list__deductions-table th:first-child,
.time-log-list__deductions-table td:first-child {
  padding-right: 1rem;
}
.time-log-list__deductions-table tr:first-child:last-child td {
  border-bottom: 0;
}
.time-log-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time-log-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.time-log-list .files-list__selected {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 980px) {
  .time-log-list .files-list__selected {
    flex-direction: row;
  }
}
@media (max-width: 980px) {
  .time-log-list .files-list__selected {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0.9rem 1.6rem;
  }
}
.time-log-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.time-log-edit {
  background: white;
}
.time-log-edit__employee-remark {
  margin: -1rem 0 2.5rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.time-log-edit__input {
  margin-bottom: 2rem;
}
.time-log-edit__time-range {
  display: flex;
  margin-bottom: 2rem;
}
.time-log-edit__time-range-input {
  width: 10%;
  flex-grow: 1;
  margin-right: 1rem;
}
.time-log-edit__time-range-input:last-child {
  margin-right: 0;
}
.time-log-edit__sub-title {
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 0 2.2rem;
  font-size: 1.2rem;
  color: #808080;
  font-weight: 600;
  text-transform: uppercase;
}
.time-log-edit__sub-title > span {
  position: relative;
  padding-right: 0.5rem;
  background: white;
}
.time-log-edit__sub-title::before {
  content: '';
  position: absolute;
  top: 0.7rem;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed #dedede;
}
.time-log-edit__sub-title .time-log-edit__sub-title-value {
  padding: 0;
  border-radius: 0.3rem;
  display: inline-block;
  font-weight: 600;
  text-transform: none;
  color: #1f1f1f;
}
.time-log-edit__sub-title .time-log-edit__sub-title-value._less {
  color: #e17d10;
}
.time-log-edit__sub-title .time-log-edit__sub-title-value._same {
  color: #14a75d;
}
.time-log-edit__sub-title .time-log-edit__sub-title-value._more {
  color: #aa0300;
}
.time-log-edit__separator {
  margin: 2rem 0;
  border-bottom: 1px dashed #dedede;
}
.time-log-edit__total {
  margin: 0 auto 0 0;
  padding: 0 2rem 0 0;
  font-size: 1.8rem;
}
.time-log-edit__total-main {
  color: #14a75d;
}
.time-log-edit__total-left {
  font-size: 1.4rem;
}
.time-log-edit__total-left._less {
  color: #e17d10;
}
.time-log-edit__total-left._same {
  color: #14a75d;
}
.time-log-edit__total-left._more {
  color: #aa0300;
}
.time-log-edit__pin-wrap {
  margin-bottom: 4rem;
}
.time-log-edit__radio-list {
  margin-bottom: 2rem;
}
.time-log-edit__pin {
  border-bottom: 1px solid #dedede;
  padding: 2rem 0;
  display: flex;
  align-items: center;
}
.time-log-edit__pin._add {
  padding: 1rem 0;
}
.time-log-edit__pin-content {
  width: 50%;
  flex-grow: 1;
}
.time-log-edit__pin-duration {
  line-height: 1.1;
  flex: 0 0 8rem;
  text-align: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 3.4rem;
  font-weight: 500;
}
.time-log-edit__sub-title + .time-log-edit__pin {
  padding-top: 0;
}
.time-log-edit__pin-row {
  display: flex;
  margin-bottom: 1rem;
}
.time-log-edit__pin-type {
  width: 16rem;
  flex-shrink: 0;
  margin-right: 1rem;
}
.time-log-edit__pin-input {
  width: 50%;
  flex-grow: 1;
  margin-right: 1rem;
}
.time-log-edit__pin-input:last-child {
  margin-right: 0;
}
.time-log-edit__pin-input._time {
  flex-shrink: 0;
  width: 7rem;
}
.time-log-edit__delete-pin-wap {
  align-self: stretch;
  flex-shrink: 0;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #dedede;
}
.time-log-edit__delete-pin {
  position: sticky;
  top: 6rem;
}
.time-log-edit__delete-pin svg {
  width: 1.2rem;
  height: 1.2rem;
}
.time-log-edit__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0 2.5rem;
  margin-bottom: -2.5rem;
  margin-top: 1rem;
  background: white;
  position: sticky;
  bottom: -1rem;
  z-index: 2;
  box-shadow: 0 -1rem 1rem -1.2rem rgba(140, 140, 140, 0.5);
}
.time-log-edit__btn {
  margin-right: 1rem;
}
.time-log-edit__btn:last-child {
  margin-right: 0;
}
.users-list__table {
  width: 100%;
  margin: 0;
}
.users-list__table td._date {
  width: 10rem;
}
.users-list__table td._name {
  width: 12rem;
}
.users-list__access-list {
  margin: -0.5rem 0 0 -0.5rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.users-list__access {
  margin: 0.5rem 0 0 0.5rem;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  background: #808080;
  border-radius: 0.3rem;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}
.users-list__access svg {
  fill: white;
  margin: -0.6rem 0.4rem -0.6rem 0;
}
.users-list__access._primary-admin {
  background-color: #14a75d;
}
.users-list__access._admin {
  background-color: #4778ff;
}
.users-list__access._see {
  background-color: #e17d10;
}
.access-config {
  padding: 2rem 0;
}
.access-config__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.access-config__disable-primary {
  color: #808080;
}
.access-config__disable-primary svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.6rem;
  fill: #808080;
  transition: 0.15s;
}
.access-config__disable-primary:hover {
  color: #aa0300;
}
.access-config__disable-primary:hover svg {
  fill: #aa0300;
}
.access-config__primary-admin-title {
  margin: 0;
  display: flex;
  align-items: center;
  color: #14a75d;
}
.access-config__primary-admin-title svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  fill: #14a75d;
}
.access-config__table {
  width: 100%;
  margin: 0;
}
.access-config__table td:first-child {
  padding-left: 2.4rem;
}
.access-config__table td:last-child {
  padding-right: 2.4rem;
}
.access-config__table._sticky {
  position: sticky;
  top: 5rem;
  left: 0;
  z-index: 2;
  background: white;
  box-shadow: 0 1px 0 0 #dedede;
}
.access-config__table:not(._list) td {
  border-bottom: 0;
}
.access-config .radio {
  font: inherit;
}
.access-config__item {
  color: #333333;
}
.access-config__item._no-access {
  color: rgba(51, 51, 51, 0.6);
}
.access-config__name {
  word-break: break-all;
}
.access-config__toggle {
  width: 100%;
}
.access-config__toggle svg {
  width: 1.2rem;
  height: 1.2rem;
  transform: rotate(90deg);
  transition: 0.15s;
}
.access-config__toggle._active svg {
  transform: rotate(270deg);
}
.over-btn {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(71, 120, 255, 0.8);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: opacity 0.15s;
}
.over-btn svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  fill: white;
}
.status {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  margin: 0;
  padding: 0.4rem 0.8rem 0.4rem;
  display: inline-flex;
  align-items: center;
  background-color: #808080;
  border-radius: 0.3rem;
  transition: background-color 0.15s, color 0.15s;
  color: white;
  font-size: 1rem;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.status:not(:disabled):hover {
  text-decoration: unset;
}
.status:not(:disabled):hover {
  text-decoration: unset;
}
.status svg {
  fill: currentColor;
  width: 1.3em;
  height: 1.3em;
  margin-right: 0.5em;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  flex-shrink: 0;
}
.status._editable {
  cursor: pointer;
}
.status._editable:hover {
  background-color: #b8b8b8;
}
.status._blue {
  background-color: #4778ff;
}
.status._blue._editable:hover {
  background-color: #478aff;
}
.status._green {
  background-color: #14a75d;
}
.status._green._editable:hover {
  background-color: #00bb5d;
}
.status._yellow {
  background-color: #e7a811;
}
.status._yellow._editable:hover {
  background-color: #fbb304;
}
.status._orange {
  background-color: #e17d10;
}
.status._orange._editable:hover {
  background-color: #ff7813;
}
.status._red {
  background-color: #8f1b2c;
}
.status._red._editable:hover {
  background-color: #aa0300;
}
.statuses-list {
  display: flex;
  flex-wrap: wrap;
}
.statuses-list__list {
  margin: -0.5rem 0 0 -0.5rem;
}
.statuses-list__status {
  margin: 0.5rem 0 0 0.5rem;
}
.ribbon {
  position: absolute;
  top: -0.5rem;
  z-index: 5;
  left: -0.5rem;
  overflow: hidden;
  width: 9.6rem;
  height: 9.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
}
.ribbon__inner {
  text-align: center;
  color: #fff;
  top: 2.4rem;
  left: -3.1rem;
  width: 13.5rem;
  padding: 0.3rem;
  position: relative;
  transform: rotate(-45deg);
  background-color: #808080;
  border-color: #676767;
}
.ribbon__inner::before,
.ribbon__inner::after {
  content: '';
  border-top: 0.5rem solid transparent;
  border-left: 0.5rem solid;
  border-left-color: inherit;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid;
  border-bottom-color: inherit;
  position: absolute;
  top: 2rem;
  transform: rotate(-45deg);
}
.ribbon__inner::before {
  border-left: 0.2rem solid transparent;
  left: 0;
}
.ribbon__inner::after {
  right: -0.2rem;
  border-bottom: 0.3rem solid transparent;
}
.ribbon__inner._blue {
  background-color: #4778ff;
  border-color: #1453ff;
}
.ribbon__inner._green {
  background-color: #14a75d;
  border-color: #0f7944;
}
.ribbon__inner._yellow {
  background-color: #e7a811;
  border-color: #b7850e;
}
.edit-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.edit-form__photo-row {
  display: flex;
  margin-bottom: 2rem;
}
.edit-form__photo-row:last-child {
  margin-bottom: 0;
}
.edit-form__photo-row-right-col {
  flex-grow: 1;
  width: 50%;
}
.edit-form__photo-container {
  max-width: 35%;
  width: 8.2rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.edit-form__photo-container._error .edit-form__photo-wrap {
  outline: 1px solid #8f1b2c;
}
.edit-form__photo-container._company {
  width: 20rem;
}
.edit-form__photo-container._company .edit-form__photo-wrap {
  border-radius: 0;
  background: none;
  border: 1px dashed #dedede;
}
.edit-form__photo-container._company .edit-form__photo-wrap::before {
  padding-top: 30%;
}
.edit-form__photo-container._company .edit-form__photo-wrap img {
  object-fit: contain;
}
.edit-form__photo-container._project {
  width: 18rem;
}
.edit-form__photo-container._project img {
  object-fit: contain;
}
.edit-form__photo-placeholder-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-form__photo-placeholder-frame svg {
  fill: #dedede;
}
.edit-form__photo-outer {
  position: relative;
}
.edit-form__photo-wrap {
  position: relative;
  width: 100%;
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 1rem;
}
.edit-form__photo-wrap._square {
  border-radius: 0;
}
.edit-form__photo-wrap::before {
  content: '';
  padding-top: 100%;
  display: block;
  width: 100%;
}
.edit-form__photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.edit-form__delete-photo,
.edit-form__crop-photo {
  border-radius: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  position: absolute;
  z-index: 5;
  right: 0.3rem;
  top: 0.3rem;
  padding: 0;
  background: #8f1b2c;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.edit-form__delete-photo:not(:disabled):hover,
.edit-form__crop-photo:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__delete-photo:not(:disabled):hover,
.edit-form__crop-photo:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__delete-photo svg,
.edit-form__crop-photo svg {
  fill: white;
}
.edit-form__delete-photo:hover,
.edit-form__crop-photo:hover {
  background-color: #aa0300;
}
.edit-form__crop-photo {
  right: auto;
  left: 0.3rem;
  background: #808080;
}
.edit-form__crop-photo:hover {
  background-color: #4778ff;
}
.edit-form__photo-placeholder {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 61%;
  height: 68%;
  opacity: 0.25;
}
.edit-form__photo-wrap._square .edit-form__photo-placeholder {
  bottom: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 68%;
}
.edit-form__upload,
.edit-form__file-upload {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 4.6rem;
  border: 1px dashed #d8d8d8;
  border-radius: 0.3rem;
  cursor: pointer;
  text-align: center;
}
.edit-form__upload:not(:disabled):hover,
.edit-form__file-upload:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__upload:not(:disabled):hover,
.edit-form__file-upload:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__upload input,
.edit-form__file-upload input {
  display: none;
}
.edit-form__upload-wrap {
  display: flex;
  justify-content: space-between;
}
.edit-form__upload-wrap .edit-form__upload {
  flex-grow: 1;
}
.edit-form__upload-wrap .edit-form__upload:not(:last-child) {
  margin-right: 0.4rem;
}
.edit-form__upload-ico,
.edit-form__file-upload-ico {
  width: 1.8rem;
  height: 1.8rem;
  fill: #d8d8d8;
}
.edit-form__upload-note,
.edit-form__file-upload-note {
  margin: 0.4rem 0 0;
  font-size: 1rem;
  line-height: 1.33333333em;
  color: #808080;
}
.edit-form__main-info {
  padding: 1rem 0 3.5rem;
}
.edit-form__input-group {
  display: flex;
  align-items: flex-start;
}
.edit-form__col-input {
  width: 10%;
  flex-grow: 1;
  margin-right: 1rem;
}
.edit-form__col-input:last-child {
  margin-right: 0;
}
.edit-form__col-input.checkbox {
  flex-grow: 0;
  width: auto;
  margin-top: 0.6rem;
}
.edit-form__input-group,
.edit-form__input,
.edit-form__input-wrap,
.edit-form__checkbox-list-wrap {
  margin-bottom: 1.8rem;
  flex-grow: 1;
}
.edit-form__input-group:last-child,
.edit-form__input:last-child,
.edit-form__input-wrap:last-child,
.edit-form__checkbox-list-wrap:last-child {
  margin-bottom: 0;
}
.edit-form__input-wrap {
  display: flex;
}
.edit-form__checkbox-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.5rem;
  margin-left: -2rem;
}
.edit-form__checkbox {
  margin-top: 0.5rem;
  margin-left: 2rem;
}
.edit-form__checkbox:last-child {
  margin-bottom: 0;
}
.edit-form__input-wrap .edit-form__input {
  margin-bottom: 0;
}
.edit-form__input-delete-btn.btn,
.edit-form__input-add-btn.btn {
  border: 0 !important;
  background: #f2f2f2;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.edit-form__input-delete-btn.btn svg,
.edit-form__input-add-btn.btn svg {
  width: 1.2rem;
  height: 1.2rem;
}
.edit-form__input-delete-btn.btn:hover,
.edit-form__input-add-btn.btn:hover {
  background-color: #aa0300 !important;
}
.edit-form__input-add-btn.btn svg {
  width: 1rem;
  height: 1rem;
}
.edit-form__input-add-btn.btn:hover {
  background-color: #00bb5d !important;
}
.edit-form__section {
  margin: 1rem -2.4rem 0.2rem;
}
.edit-form__main-info + .edit-form__section,
.edit-form__section + .edit-form__section {
  margin-top: 0;
}
.edit-form__section-head {
  padding: 1.3rem 2.4rem 1.2rem;
  background: #4778ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-form__section-title {
  margin: 0;
  width: 50%;
  flex-grow: 1;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: uppercase;
  color: white;
}
.edit-form__section-btn-toggle {
  margin: -1rem 0 -1rem auto;
}
.edit-form__section-btn-toggle svg {
  width: 1.2rem;
  height: 1.2rem;
  transform: rotate(90deg);
  transition: 0.15s;
}
.edit-form__section._open .edit-form__section-btn-toggle svg {
  transform: rotate(270deg);
}
.edit-form__section-delete-btn {
  background: transparent;
  border-color: white;
  margin-top: -0.6rem;
  margin-bottom: -0.6rem;
}
.edit-form__section-delete-btn:hover {
  background-color: #8f1b2c;
  border-color: #8f1b2c;
}
.edit-form__section-content {
  padding: 3rem 2.4rem;
}
.edit-form__contacts {
  padding: 2rem 0;
}
.edit-form__alert {
  margin-bottom: 2rem;
}
.edit-form__footer {
  display: flex;
  justify-content: flex-end;
}
.edit-form__btn {
  min-width: 9rem;
  margin-right: 1rem;
}
.edit-form__btn:last-child {
  margin-right: 0;
}
.edit-form__footer-separator {
  margin: auto;
  padding: 0 1rem;
}
.edit-form__create-password {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted #4778ff;
  cursor: pointer;
  font-size: 1.1rem;
  line-height: 1.2em;
  color: #4778ff;
  transition: 0.15s;
}
.edit-form__create-password:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__create-password:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__create-password:hover {
  border-bottom: rgba(71, 120, 255, 0);
}
.edit-form__addition-section {
  min-height: 1px;
  position: relative;
  margin: 2.5rem 0 2rem;
}
.edit-form__addition-section::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -2.4rem;
  width: calc(100% + 4.8rem);
  border-bottom: 1px dashed #e3e3e3;
}
.edit-form__addition-section._open::before {
  display: none;
}
.edit-form__addition-btn-toggle {
  position: absolute;
  top: -0.8rem;
  left: -0.6rem;
  border-radius: 0;
  z-index: 2;
  background: white;
  padding: 0 0.6rem;
  min-height: 0;
  color: #808080;
}
.edit-form__addition-btn-toggle:hover {
  background: white;
  color: #4778ff;
}
.edit-form__addition-btn-toggle svg {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.5rem;
  fill: #808080;
}
.edit-form__addition-section._open .edit-form__addition-btn-toggle {
  left: 1.6rem;
}
.edit-form__addition-section._open .edit-form__addition-btn-toggle:hover {
  color: #8f1b2c;
}
.edit-form__addition-section._open .edit-form__addition-btn-toggle:hover svg {
  fill: #8f1b2c;
}
.edit-form__addition-content {
  padding: 2.5rem 2rem;
  border: 1px dashed #dedede;
  border-radius: 0.3rem;
}
.edit-form__group-title {
  margin: 1rem 0 1.5rem;
  font-size: 1.4rem;
}
.edit-form__placeholders-title {
  font-size: 1.8rem;
  margin: 0 0 0.8rem;
}
.edit-form__placeholders-group {
  margin: 0.5rem -0.3rem;
}
.edit-form__placeholders-group:first-child {
  margin-top: -0.3rem;
}
.edit-form__placeholders-group:last-child {
  margin-bottom: -0.3rem;
}
.edit-form__placeholders-group > * {
  margin: 0.3rem;
}
.edit-form__placeholders-btn {
  text-transform: none;
  overflow: visible;
}
.edit-form__placeholders-btn .copied {
  position: absolute;
  white-space: nowrap;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  bottom: 100%;
  margin-bottom: 0.5rem;
  color: #333333;
  pointer-events: none;
  transform: translateY(50%);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
}
.edit-form__placeholders-btn .copied::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 0.4rem solid white;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}
.edit-form__placeholders-btn._copied .copied {
  opacity: 1;
  transform: none;
}
.edit-form__confirmed {
  font-size: 1.2rem;
}
.edit-form__confirmed:not(:last-child) {
  margin-bottom: 0.5rem;
}
.edit-form__confirmed-ico {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #aa0300;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.edit-form__confirmed-ico._confirmed {
  background-color: #14a75d;
}
.edit-form__confirmed-ico svg {
  width: 1rem;
  height: 1rem;
  fill: white;
}
.edit-form__confirmed > span {
  display: inline-block;
}
.edit-form__confirmed-edit {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  padding: 0.3rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  color: #4778ff;
}
.edit-form__confirmed-edit:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__confirmed-edit:not(:disabled):hover {
  text-decoration: unset;
}
.edit-form__confirmed-edit:hover {
  color: #478aff;
}
.edit-form__confirmed-edit svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}
.edit-form__confirmed .date-input {
  display: inline-flex;
}
.inner-nav__list,
.inner-nav__sub-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
a.inner-nav__link,
a.inner-nav__sub-link,
button.inner-nav__link,
button.inner-nav__sub-link {
  transition: 0.15s;
  cursor: pointer;
}
a.inner-nav__link:hover,
a.inner-nav__sub-link:hover,
button.inner-nav__link:hover,
button.inner-nav__sub-link:hover {
  background-color: #f2f2f2;
  text-decoration: none;
  color: inherit;
}
.inner-nav__link,
.inner-nav__sub-link {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem 0.9rem 2rem;
  border-top: 1px solid #dedede;
  text-decoration: none;
}
.inner-nav__link:not(:disabled):hover,
.inner-nav__sub-link:not(:disabled):hover {
  text-decoration: unset;
}
.inner-nav__link:not(:disabled):hover,
.inner-nav__sub-link:not(:disabled):hover {
  text-decoration: unset;
}
a.inner-nav__link._active,
a.inner-nav__sub-link._active,
button.inner-nav__link._active,
button.inner-nav__sub-link._active {
  background: #f2f2f2;
  color: #8f1b2c;
}
.inner-nav__link {
  font-weight: 600;
}
.inner-nav__item:first-child .inner-nav__link {
  border-top: 0;
}
.inner-nav__sub-link {
  padding-left: 4rem;
  font-size: 1.2rem;
}
.inner-nav__sub-link:not(a):not(button) {
  font-weight: 600;
}
.inner-nav__arrow {
  width: 0.8rem;
  height: 1rem;
  opacity: 0.3;
  flex-shrink: 0;
  transition: 0.15s;
  margin-left: 0.5rem;
}
.inner-nav__link._active .inner-nav__arrow,
.inner-nav__sub-link._active .inner-nav__arrow {
  fill: #8f1b2c;
  opacity: 1;
}
.inner-nav__sub-list .inner-nav__sub-list .inner-nav__sub-link {
  padding-left: 6rem;
}
.inner-nav__search-wrap {
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
}
.inner-nav__search {
  width: 100%;
}
@media (max-width: 1600px) {
  .inner-nav__sub-link {
    padding-left: 3rem;
  }
  .inner-nav__sub-list .inner-nav__sub-list .inner-nav__sub-link {
    padding-left: 4rem;
  }
}
.deposit-edit table.deposit-edit__payments-received {
  width: 100%;
  margin-top: 3rem;
}
.deposits-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
}
.deposits-list .files-list__selected {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
@media (max-width: 980px) {
  .deposits-list .files-list__selected {
    flex-direction: row;
  }
}
@media (max-width: 980px) {
  .deposits-list .files-list__selected {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    padding: 0.9rem 1.6rem;
  }
}
.deposits-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.deposits-list__search,
.deposits-list__head-btn {
  margin-right: 1rem;
}
.deposits-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.deposits-list__table-wrap {
  position: relative;
}
.deposits-list__table {
  width: 100%;
  margin: 0;
}
.deposits-list__table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.deposits-list__table thead th {
  background-color: #dedede;
  border-bottom: 0;
}
.deposits-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.deposits-list__table td:not(._no-link) {
  cursor: pointer;
  transition: background-color 0.15s;
}
.deposits-list__table th:first-child,
.deposits-list__table td:first-child {
  width: 4.8rem;
}
.deposits-list__table th:last-child,
.deposits-list__table td:last-child {
  width: 5.4rem;
}
.deposits-list__photo-wrap {
  position: relative;
  background: #dedede;
  width: 5.5rem;
  height: 4.5rem;
  flex-shrink: 0;
}
.deposits-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.deposits-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deposits-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.deposits-list__info {
  padding-top: 1px;
}
.deposits-list__info > * {
  margin-bottom: 0.4rem;
}
.deposits-list__unapplied-amount {
  color: #e17d10;
  cursor: help;
}
.deposits-list._short .deposits-list__header {
  border-bottom: 1px solid #dedede;
  top: 0;
}
@media (max-width: 980px) {
  .deposits-list._short {
    display: none;
  }
}
.deposits-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.deposits-list__short-item._selected {
  background-color: #e3ebff;
}
.deposits-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.deposits-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.deposits-list__short-item-col {
  width: 48%;
  font-size: 1.2rem;
}
.deposits-list__short-item-col._right {
  text-align: right;
}
.deposits-list__short-item-cell {
  margin-bottom: 0.3rem;
}
.deposits-list__short-item-cell:last-child {
  margin-bottom: 0;
}
.deposits-list__mob-items-wrap {
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.deposits-list__mob-item {
  width: 100%;
  max-width: calc(100% / 2 - 8px);
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 20px 12px 8px;
  margin-top: 10px;
  position: relative;
}
@media screen and (max-width: 749px) {
  .deposits-list__mob-item {
    max-width: none;
  }
}
.deposits-list__item-row {
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.deposits-list__item-row p {
  margin: 0;
}
.deposits-list__item-row ._image {
  width: 5.5rem;
  height: 5.5rem;
}
.deposits-list__item-row--btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 0;
}
.deposits-list__item-row--btn .actions,
.deposits-list__item-row--btn .checkbox {
  padding: 6px 12px;
}
.persons-list {
  position: relative;
  background: white;
}
.persons-list__header {
  position: sticky;
  z-index: 32;
  top: 5rem;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
}
.persons-list._no-offset .persons-list__header,
.persons-list._short .persons-list__header {
  top: 0;
}
.persons-list._short .persons-list__table {
  table-layout: fixed;
}
.persons-list._no-offset .persons-list__table thead {
  top: 5.5rem;
}
.persons-list__drop-menu {
  margin-right: 1rem;
}
.persons-list__drop-menu .dropdown__dropdown-inner {
  width: 15rem;
}
.persons-list__search {
  width: 21.8rem;
  margin-right: 1rem;
}
.persons-list__search-toggle {
  background: transparent;
  margin-right: 1rem;
  border: 1px solid #dedede;
}
.persons-list__search-toggle svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: rgba(0, 0, 0, 0.65);
}
.persons-list__search-toggle._open,
.persons-list__search-toggle:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.persons-list__search-toggle._open svg,
.persons-list__search-toggle:hover svg {
  fill: white;
}
.persons-list__main-search-short-wrap {
  padding: 2rem;
  border-bottom: 1px solid #dedede;
}
.persons-list__main-search-short {
  width: 100%;
}
.persons-list__sort {
  max-width: 60%;
  width: 18rem;
  margin-left: auto;
}
.persons-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.persons-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.persons-list__head-btn {
  margin-right: 1rem;
}
.persons-list__table-wrap {
  position: relative;
}
.persons-list__table {
  width: 100%;
  margin: 0;
}
.persons-list__table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 2;
}
.persons-list__table thead._no-sticky {
  position: static;
}
.persons-list__table th {
  background: #f2f2f2;
}
.persons-list__table td._checkbox {
  width: 4.8rem;
}
.persons-list__table td._actions {
  width: 5.4rem;
}
.persons-list__person {
  cursor: pointer;
  transition: background-color 0.15s;
}
.persons-list__person:hover {
  background-color: #f2f2f2;
}
.persons-list._short .persons-list__person._selected {
  background-color: #e3ebff;
}
.persons-list__person-profile {
  display: flex;
  align-items: center;
}
.persons-list__photo-wrap {
  background: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.persons-list__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.persons-list__photo-placeholder {
  opacity: 0.5;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
}
.persons-list__profile-info {
  width: 50%;
  flex-grow: 1;
}
.persons-list._short {
  border-right: 1px solid #dedede;
}
.persons-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.persons-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.persons-list__info {
  padding-top: 1px;
}
.persons-list__info > * {
  margin-bottom: 0.4rem;
}
@media (max-width: 749px) {
  .persons-list__header {
    margin-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    position: static;
    background-color: transparent;
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .persons-list__mob-list {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .persons-list__photo-wrap {
    margin-top: 0;
    margin-bottom: 0;
    width: 4rem;
    height: 4rem;
  }
  .persons-list__info-name {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
  }
  .persons-list__info {
    padding-top: 0;
  }
  .persons-list__info > *:last-child {
    margin-bottom: 0;
  }
  .persons-list__info-company {
    color: #888888;
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 1.4;
  }
  .persons-list__mob-person {
    padding: 1.2rem;
    padding-right: 4rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    margin-bottom: 0.8rem;
    position: relative;
  }
  .persons-list__mob-person:last-child {
    margin-bottom: 0;
  }
  .persons-list__mob-person-contacts {
    display: flex;
    margin-top: 0.8rem;
  }
  .persons-list__mob-person-contacts svg {
    width: 1.3rem;
    height: 1.3rem;
    flex-shrink: 0;
    margin-right: 1rem;
    margin-top: 0.2rem;
    transition: fill 0.15s;
  }
  .persons-list__mob-person-contacts ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  .persons-list__mob-person-contacts ul li {
    margin-bottom: 0.3rem;
  }
  .persons-list__mob-person-contacts ul li:last-child {
    margin-bottom: 0;
  }
  .persons-list__mob-person-checkbox {
    padding: 1.2rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  .persons-list__mob-person-checkbox._checked ~ .persons-list__mob-person-contacts svg {
    fill: #14a75d;
  }
  .persons-list__selection-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
  }
  .persons-list__selected {
    order: -1;
    margin-right: auto;
  }
  .persons-list__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
  .persons-list__head-btn {
    background-color: #808080 !important;
    border-color: #808080 !important;
  }
  .persons-list__head-btn svg {
    fill: white !important;
  }
  .persons-list__drop-menu {
    margin-right: 0;
  }
  .persons-list__drop-menu .dropdown__dropdown {
    top: auto;
    bottom: 100%;
    left: auto;
    right: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .persons-list__drop-menu .dropdown__dropdown-inner {
    top: auto;
    bottom: 0;
  }
  .persons-list__search {
    margin-right: 0;
    width: 100%;
  }
}
.upload-file-small-prev {
  position: relative;
  width: 3.4rem;
  height: 3.4rem;
}
.upload-file-small-prev__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upload-file-small-prev__btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  cursor: pointer;
  overflow: hidden;
}
.upload-file-small-prev__btn:not(:disabled):hover {
  text-decoration: unset;
}
.upload-file-small-prev__btn:not(:disabled):hover {
  text-decoration: unset;
}
.upload-file-small-prev__btn input {
  display: none;
}
.upload-file-small-prev__btn svg {
  width: 0.7rem;
  height: 1.4rem;
  fill: #4778ff;
}
.upload-file-small-prev__btn._img {
  cursor: zoom-in;
}
.upload-file-small-prev__delete {
  border-radius: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  position: absolute;
  z-index: 5;
  right: -0.8rem;
  top: -0.8rem;
  padding: 0;
  background: #8f1b2c;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.upload-file-small-prev__delete:not(:disabled):hover {
  text-decoration: unset;
}
.upload-file-small-prev__delete:not(:disabled):hover {
  text-decoration: unset;
}
.upload-file-small-prev__delete svg {
  fill: white;
}
.upload-file-small-prev__delete:hover {
  background-color: #aa0300;
}
.settings-person {
  position: relative;
}
.settings-person__section-title {
  margin: 0 0 1.5rem;
  font-size: 1.8rem;
}
.settings-person__referrer {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.settings-person__referrer p {
  margin: 0;
}
.settings-person__main-info-table {
  width: 100%;
  margin: 0 0 3rem;
  font-weight: 600;
}
.settings-person__main-info-table td:first-child {
  width: 30%;
  color: #808080;
  font-weight: 400;
}
.settings-person__main-info-table tr:first-child td {
  border-top: 1px solid #dedede;
}
.settings-person__contacts-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.settings-person__contact {
  width: 46%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px dashed #dedede;
}
.settings-person__contact:nth-child(-n + 2) {
  border-top: 1px dashed #dedede;
}
.settings-person__contact-title {
  margin: 0 0 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.settings-person__contact-item {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.settings-person__contact-item:last-child {
  margin-bottom: 0;
}
.settings-person__contact-table {
  width: 100%;
  margin: 0;
}
.settings-person__contact-table td:first-child {
  font-weight: 400;
  color: #808080;
}
.settings-person__contact-table tr:first-child td {
  padding-top: 0;
}
.settings-person__contact-table tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0;
}
.information-for-documents__toggle {
  margin-bottom: 2rem;
}
.information-for-documents__editor {
  font-size: 1.2rem;
}
.information-for-documents__editor-result {
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  padding: 1.2rem 0.8rem;
  font-size: 1.2rem;
}
.hide-particle {
  position: relative;
  min-width: 0;
  pointer-events: none;
}
.hide-particle__placeholder {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  transition: opacity 0.3s;
  pointer-events: auto;
}
.hide-particle__placeholder * {
  min-width: 0;
}
.hide-particle__overlay {
  position: absolute;
  z-index: 21;
  top: -0.2rem;
  left: -0.5rem;
  background: white;
  padding: 0.2rem 0.5rem;
  box-shadow: 0 0 1rem rgba(31, 31, 31, 0.2);
  border-radius: 0.3rem;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: normal;
  word-break: break-word;
  pointer-events: none;
}
.hide-particle._wrap-overlay .hide-particle__overlay {
  white-space: normal;
  word-break: break-word;
}
.hide-particle:hover .hide-particle__overlay {
  opacity: 1;
  pointer-events: auto;
}
.list-footer {
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-footer__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.upload-files-light__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -1rem 0 0 -1rem;
}
.upload-files-light__item,
.upload-files-light__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  border: 1px solid #dedede;
  margin: 1rem 0 0 1rem;
  padding: 0.4rem 1.4rem;
  min-height: 3rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.upload-files-light__item {
  padding-right: 1.1rem;
}
.upload-files-light input {
  display: none;
}
.upload-files-light__btn {
  cursor: pointer;
  text-align: center;
  line-height: 1.33333333em;
  color: #808080;
  transition: 0.15s;
}
.upload-files-light__btn:hover {
  border-color: #4778ff;
  color: #4778ff;
}
.upload-files-light__btn svg {
  position: relative;
  width: 1rem;
  height: 1.6rem;
  margin-right: 0.8rem;
  fill: #808080;
}
.upload-files-light__btn:hover svg {
  fill: #4778ff;
}
.upload-files-light__delete {
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  padding: 0;
  background: #b8b8b8;
  cursor: pointer;
  margin-left: 0.8rem;
  transition: 0.15s;
}
.upload-files-light__delete svg {
  fill: white;
}
.upload-files-light__delete:hover {
  opacity: 1;
  border-color: #aa0300;
  background-color: #aa0300;
}
.search-table-col {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.search-table-col__btn {
  background: transparent;
  border-radius: 0;
  padding: 0 0.2rem 0 0;
  min-height: 0;
  border: 0;
  width: 2.4rem;
  height: 2.8rem;
  overflow: visible;
  flex-shrink: 0;
  justify-content: flex-start;
  transition: 0.15s;
}
.search-table-col__btn:hover {
  background-color: transparent;
}
.search-table-col__btn svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: #808080;
}
.search-table-col__btn:hover svg {
  fill: #14a75d;
}
.search-table-col__search {
  background-color: white;
  border-color: white;
  max-width: calc(100% + 1.8rem);
  margin: auto -0.9rem;
}
.search-table-col__search .search__ico {
  display: none;
}
.search-table-col__content {
  max-width: 100%;
}
.search-table-col .search__input {
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  padding-right: 0;
  padding-left: 0.5rem;
}
.search-table-col .search__ico {
  margin-left: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
}
.search-table-col .search__btn {
  width: 1.8rem;
}
.search-table-col .search__btn::before {
  display: none;
}
.search-table-col .search__btn svg {
  width: 0.8rem !important;
  height: 0.8rem !important;
}
.notes-list__group {
  margin-bottom: 2rem;
}
.notes-list__group:last-child {
  margin-bottom: 0;
}
.notes-list__group-title {
  margin: 0 0 1rem;
  font-size: 1.6rem;
}
.notes-list__list ul {
  padding-left: 1.3rem;
}
.notes-list__date {
  margin-left: 1rem;
  display: inline;
  float: right;
}
.email {
  font-size: 1.2rem;
}
.email__subject {
  margin: 0 0 1.2rem;
  font-size: 1.8rem;
}
.email__group {
  display: flex;
  margin-bottom: 0.5rem;
}
.email__group-title {
  margin: 0 0.6rem 0 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 700;
  text-transform: none;
}
.email__group-content {
  line-height: 1.42857143em;
}
.email__message,
.email__files {
  margin-top: 2rem;
}
.email__files-title,
.email__message-title {
  margin: 0 0 1rem;
  font-size: 1.4rem;
  text-transform: none;
}
.email__files-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.email__file {
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid #dedede;
  background: #f2f2f2;
  max-width: 12rem;
  margin: 0.5rem 0 0 0.5rem;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.email__file svg {
  width: 1.2rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  fill: #b8b8b8;
}
.email__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.email__agent,
.email__time {
  display: flex;
  align-items: center;
}
.email__agent svg,
.email__time svg {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.6rem;
  fill: #808080;
}
.vendor-overview {
  padding: 1rem 2rem;
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
}
@media (max-width: 749px) {
  .vendor-overview {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    height: auto;
    overflow: visible;
  }
}
.vendor-notes {
  position: relative;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.vendor-notes__scroll-block {
  height: 20rem;
  flex-grow: 1;
  overflow-y: auto;
}
.vendor-notes__alert {
  flex-shrink: 0;
  margin: 1rem 2rem;
}
.vendor-notes__table {
  width: 100%;
  margin: 0;
}
.vendor-notes__table th:nth-child(1),
.vendor-notes__table td:nth-child(1) {
  width: 15.5rem;
}
.vendor-notes__table th:nth-child(3),
.vendor-notes__table td:nth-child(3) {
  width: 20%;
}
.vendor-notes__table th:last-child,
.vendor-notes__table td:last-child {
  width: 10.1rem;
  text-align: right;
}
.vendor-notes__scroll-block tr:last-child td {
  border-bottom: 0;
}
.vendor-notes__scroll-pusher {
  padding: 0;
  width: 0.8rem !important;
}
.vendor-notes a {
  text-decoration: none;
}
.vendor-notes__input {
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  border-radius: 0.3rem;
}
.vendor-notes__input .editr--toolbar {
  display: none;
}
.vendor-notes__input .editr--content {
  min-height: 3.2rem;
}
.vendor-notes__input .editor__content {
  padding: 0.7rem 1rem 0.8rem;
}
.vendor-notes__input .ProseMirror {
  min-height: auto;
}
.vendor-notes__note {
  margin: 0;
}
.vendor-notes__btn {
  margin-right: 0.5rem;
}
.vendor-notes__btn:last-child {
  margin-right: 0;
}
.vendor-notes__btn-save {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
@media (max-width: 980px) {
  .vendor-notes__btn {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .vendor-notes__btn:last-child {
    margin-bottom: 0;
  }
  .vendor-notes__table {
    display: block;
  }
  .vendor-notes__table tbody,
  .vendor-notes__table thead {
    display: block;
  }
  .vendor-notes__table thead {
    background-color: #dedede;
  }
  .vendor-notes__table tr {
    display: flex;
  }
  .vendor-notes__table th:last-child {
    font-size: 0;
  }
  .vendor-notes__table td,
  .vendor-notes__table th {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .vendor-notes__table td:first-child,
  .vendor-notes__table th:first-child {
    width: 24%;
    flex-shrink: 0;
    max-width: 13rem;
  }
  .vendor-notes__table td:nth-child(2),
  .vendor-notes__table th:nth-child(2) {
    width: 40%;
    flex-grow: 1;
  }
  .vendor-notes__table td:nth-child(3),
  .vendor-notes__table th:nth-child(3) {
    width: 18%;
  }
  .vendor-notes__table td:last-child,
  .vendor-notes__table th:last-child {
    width: auto;
    flex-basis: 5rem;
  }
  .vendor-notes__table td:first-child:last-child,
  .vendor-notes__table th:first-child:last-child {
    flex-basis: 100%;
    max-width: none;
    width: 100%;
  }
  .vendor-notes > .vendor-notes__table tbody tr td {
    width: auto;
  }
  .vendor-notes > .vendor-notes__table tbody tr td:first-child,
  .vendor-notes > .vendor-notes__table tbody tr td:nth-child(3) {
    display: none;
  }
  .vendor-notes > .vendor-notes__table tbody tr td:nth-child(2) {
    padding-left: 1.6rem;
  }
  .vendor-notes > .vendor-notes__table tbody tr td:last-child {
    width: 8rem;
  }
}
@media (max-width: 749px) {
  .vendor-notes {
    height: auto;
  }
  .vendor-notes__scroll-block {
    height: auto;
    overflow: visible;
  }
}
.vendor-expenses {
  flex: 1;
  height: 20rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.vendor-expenses__content-wrap {
  flex: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  flex-direction: column;
}
.vendor-expenses__table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}
@media (max-width: 749px) {
  .vendor-expenses__content-wrap {
    overflow: visible;
    display: block;
  }
}
.vendor-logs {
  position: relative;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.vendor-logs__scroll-block {
  flex-grow: 1;
}
.vendor-logs__table-wrap {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.vendor-logs__table {
  width: 100%;
  margin: 0;
}
.vendor-logs__table tr:last-child td {
  border-bottom: 0;
}
.vendor-logs__table th:nth-child(1),
.vendor-logs__table td:nth-child(1) {
  width: 14rem;
}
.vendor-logs a {
  text-decoration: none;
}
ul.vendor-logs__log-group {
  padding-left: 1.3rem;
}
ul.vendor-logs__log-group li::before {
  background-color: #808080;
}
.vendor-logs__log-group._added li::before {
  background-color: #14a75d;
}
.vendor-logs__log-group._changed li::before {
  background-color: #e17d10;
}
.vendor-logs__log-group._removed li::before {
  background-color: #8f1b2c;
}
@media (max-width: 980px) {
  .vendor-logs__table th:nth-child(1),
  .vendor-logs__table td:nth-child(1) {
    width: 13rem;
  }
}
@media (max-width: 749px) {
  .vendor-logs__scroll-block {
    height: auto;
    overflow: auto;
  }
}
@media (max-width: 450px) {
  .vendor-logs__table th:nth-child(1),
  .vendor-logs__table td:nth-child(1) {
    width: 9rem;
  }
}
.logs-list {
  width: 100%;
}
.logs-list__table {
  width: 100%;
  margin: 0;
}
.logs-list__table tr:last-child td {
  border-bottom: 0;
}
.logs-list__table th:nth-child(1),
.logs-list__table td:nth-child(1) {
  width: 15.5rem;
}
.logs-list a {
  text-decoration: none;
}
ul.logs-list__log-group {
  padding-left: 1.4rem;
}
ul.logs-list__log-group li::before {
  background-color: #808080;
}
.logs-list__log-group._added li::before {
  background-color: #14a75d;
}
.logs-list__log-group._changed li::before {
  background-color: #e17d10;
}
.logs-list__log-group._removed li::before {
  background-color: #8f1b2c;
}
.notes {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.notes__alert {
  flex-shrink: 0;
  margin: 1rem 2rem;
}
.notes__table {
  width: 100%;
  margin: 0;
}
.notes__table th:nth-child(1),
.notes__table td:nth-child(1) {
  width: 15.5rem;
}
.notes__table th:nth-child(3),
.notes__table td:nth-child(3) {
  width: 20%;
}
.notes__table th:last-child,
.notes__table td:last-child {
  width: 10.2rem;
}
.notes__table th:last-child:not(:first-child),
.notes__table td:last-child:not(:first-child) {
  text-align: right;
}
.notes a {
  text-decoration: none;
}
.notes__input {
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  border-radius: 0.3rem;
}
.notes__input .editr--toolbar {
  display: none;
}
.notes__input .editr--content {
  min-height: 3.2rem;
}
.notes__input .editor__content {
  padding: 0.7rem 1rem 0.8rem;
}
.notes__input .ProseMirror {
  min-height: auto;
}
.notes__note {
  margin: 0;
}
.notes__btn {
  margin-right: 0.5rem;
}
.notes__btn:last-child {
  margin-right: 0;
}
.notes__btn-save {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.c-settings-persons {
  position: relative;
}
.c-settings-persons__table-wrap {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.c-settings-persons__scroll-block {
  flex-grow: 1;
}
.c-settings-persons__table {
  margin: 0;
  display: block;
}
.c-settings-persons__table thead,
.c-settings-persons__table tbody {
  display: block;
}
.c-settings-persons__table td._checkbox {
  width: 4.8rem;
}
.c-settings-persons__table td._actions {
  width: 5.4rem;
}
.c-settings-persons__table tr {
  display: flex;
}
.c-settings-persons__table th,
.c-settings-persons__table td {
  flex-shrink: 0;
  flex-grow: 1;
}
.c-settings-persons__table tbody tr {
  transition: background-color 0.15s;
}
.c-settings-persons__table th:nth-child(1),
.c-settings-persons__table td:nth-child(1) {
  width: 18rem;
}
.c-settings-persons__table th:nth-child(2),
.c-settings-persons__table td:nth-child(2) {
  width: 14rem;
}
.c-settings-persons__table th:nth-child(3),
.c-settings-persons__table td:nth-child(3) {
  width: 16rem;
}
.c-settings-persons__table th:nth-child(4),
.c-settings-persons__table td:nth-child(4) {
  width: 16rem;
}
.c-settings-persons__table th:nth-child(5),
.c-settings-persons__table td:nth-child(5) {
  width: 14rem;
}
.c-settings-persons__table th:last-child,
.c-settings-persons__table td:last-child {
  width: 8.3rem;
  flex-grow: 0;
}
.c-settings-persons__scroll-block th:last-child,
.c-settings-persons__scroll-block td:last-child {
  width: 8.3rem;
}
@media (max-width: 980px) {
  .c-settings-persons__content-mob {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .c-settings-persons__content-mob .list-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .c-settings-persons__actions-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -1rem 0 -1rem 1rem;
  }
  .c-settings-persons__list-mob {
    position: relative;
  }
  .c-settings-persons__list-mob-item {
    padding: 1.1rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    margin-bottom: 0.8rem;
    position: relative;
    font-size: 1.2rem;
  }
  .c-settings-persons__list-mob-item:last-child {
    margin-bottom: 0;
  }
  .c-settings-persons__list-mob-item-params {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
  }
  .c-settings-persons__list-mob-item-params > div {
    flex-grow: 1;
    width: 40%;
    margin-top: 1rem;
    margin-right: 1rem;
    flex-basis: 28rem;
  }
  .c-settings-persons__list-mob-item-top {
    display: flex;
    justify-content: space-between;
  }
}
.select-vendor {
  display: flex;
}
.select-vendor__select {
  width: 50%;
  flex: 1 1 auto;
}
.select-vendor__select:not(:last-child) .select__container {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-vendor__select._open ~ .select-vendor__btn,
.select-vendor__input:hover ~ .select-vendor__btn,
.select-vendor__select:not(._disabled):not(._error):hover ~ .select-vendor__btn,
.select-vendor._add:hover .select-vendor__input .input__input {
  border-color: #cdcccc;
  cursor: pointer;
}
.select-vendor__input,
.select-vendor__input .input__input {
  width: 50%;
  flex: 1 1 auto;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-vendor__input .input__input {
  width: 100%;
}
.select-vendor__btn {
  flex-shrink: 0;
  padding: 0.2rem;
  width: 2.9rem;
  height: 3.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: transparent !important;
  border: 1px solid #dedede;
  border-left-width: 0;
  overflow: visible;
}
.select-vendor__btn svg {
  fill: #4778ff;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s;
}
.select-vendor__btn._rotate svg {
  transition: transform 0.45s;
}
.select-vendor__btn:hover svg {
  transform: scale(1.1);
}
.select-vendor__btn._rotate:hover svg {
  transform: rotate(180deg);
}
.select-vendor__btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  min-width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.35), rgba(31, 31, 31, 0));
}
.select-vendor__select._error ~ .select-vendor__btn {
  border-color: #aa0300;
}
.select-vendor__select._disabled ~ .select-vendor__btn {
  opacity: 0.6;
  cursor: not-allowed;
}
.select-vendor._add .select-vendor__btn:before {
  display: none;
}
.select-customer {
  display: flex;
}
.select-customer__select {
  width: 50%;
  flex: 1 1 auto;
}
.select-customer__select:not(:last-child) .select__container {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-customer__select:not(:last-child) .select__container:after {
  content: '';
  position: absolute;
  top: 3.3rem;
  bottom: 0;
  right: 0;
  border-right: 1px solid;
  border-color: inherit;
}
.select-customer__select._open ~ .select-customer__btn,
.select-customer__input:hover ~ .select-customer__btn,
.select-customer__select:not(._disabled):not(._error):hover ~ .select-customer__btn,
.select-customer._add:hover .select-customer__input .input__input {
  border-color: #cdcccc;
  cursor: pointer;
}
.select-customer__input,
.select-customer__input .input__input {
  width: 50%;
  flex: 1 1 auto;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-customer__input .input__input {
  width: 100%;
}
.select-customer__btn {
  flex-shrink: 0;
  padding: 0.2rem;
  width: 2.9rem;
  height: 3.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: white !important;
  border: 1px solid #dedede;
  border-left-width: 0;
  overflow: visible;
}
.select-customer__btn svg {
  fill: #4778ff;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s;
}
.select-customer__btn._rotate svg {
  transition: transform 0.45s;
}
.select-customer__btn:hover svg {
  transform: scale(1.1);
}
.select-customer__btn._rotate:hover svg {
  transform: rotate(180deg);
}
.select-customer__btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  min-width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.35), rgba(31, 31, 31, 0));
}
.select-customer__select._error ~ .select-customer__btn {
  border-color: #aa0300;
}
.select-customer__select._disabled ~ .select-customer__btn {
  opacity: 0.6;
  cursor: not-allowed;
}
.select-customer._add .select-customer__btn:before {
  display: none;
}
.select-customer__option {
  display: flex;
  align-items: center;
}
.select-customer__option-img-wrap {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #dedede;
  margin-top: -0.5rem;
  margin-bottom: -0.5em;
  margin-right: 1rem;
  flex-shrink: 0;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-customer__option-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.select-customer__photo-placeholder {
  opacity: 0.5;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #333333;
}
.select-customer__option .select-customer__option-img-wrap .select-customer__photo-placeholder {
  color: #333333 !important;
}
.select-customer__option-main {
  width: 50%;
  flex-grow: 1;
  min-width: 0;
}
.select-customer__option-name {
  display: flex;
  align-items: center;
}
.select-customer__option-name:last-child {
  margin-bottom: 0 !important;
}
.select-customer__option-name div {
  min-width: 0;
}
.select-customer__status {
  flex-shrink: 0;
  padding-left: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #e7a811;
}
.select-customer__status::before {
  content: '';
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  background-color: #f2f2f2;
  border: 1px solid #dedede;
}
.select-customer__status._inactive::before {
  background-color: #e7a811;
  border-color: #e7a811;
}
.select-shipping-file {
  display: flex;
}
.select-shipping-file__select {
  width: 50%;
  flex: 1 1 auto;
}
.select-shipping-file__select:not(:last-child) .select__container {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-shipping-file__select._open ~ .select-shipping-file__btn,
.select-shipping-file__input:hover ~ .select-shipping-file__btn,
.select-shipping-file__select:not(._disabled):not(._error):hover ~ .select-shipping-file__btn,
.select-shipping-file._add:hover .select-shipping-file__input .input__input {
  border-color: #cdcccc;
  cursor: pointer;
}
.select-shipping-file__input,
.select-shipping-file__input .input__input {
  width: 50%;
  flex: 1 1 auto;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-shipping-file__input .input__input {
  width: 100%;
}
.select-shipping-file__btn {
  flex-shrink: 0;
  padding: 0.2rem;
  width: 2.9rem;
  height: 3.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: white !important;
  border: 1px solid #dedede;
  border-left-width: 0;
  overflow: visible;
}
.select-shipping-file__btn svg {
  fill: #4778ff;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s;
}
.select-shipping-file__btn._rotate svg {
  transition: transform 0.45s;
}
.select-shipping-file__btn:hover svg {
  transform: scale(1.1);
}
.select-shipping-file__btn._rotate:hover svg {
  transform: rotate(180deg);
}
.select-shipping-file__btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  min-width: 1px;
  height: 1.8rem;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.35), rgba(31, 31, 31, 0));
}
.select-shipping-file__select._error ~ .select-shipping-file__btn {
  border-color: #aa0300;
}
.select-shipping-file__select._disabled ~ .select-shipping-file__btn {
  opacity: 0.6;
  cursor: not-allowed;
}
.select-shipping-file._add .select-shipping-file__btn:before {
  display: none;
}
.page-options-menu {
  font-size: 1.2rem;
}
.page-options-menu .dropdown__btn-text {
  text-transform: uppercase;
}
.page-options-menu .dropdown__dropdown-inner {
  min-width: 15rem;
}
.page-options-menu .dropdown__item {
  text-align: left !important;
}
.commodity-show-info__documents-table {
  max-width: none;
  margin: 0 -1.5rem;
  background: #f2f2f2;
}
.commodity-show-info__documents-table td {
  padding: 1rem 1.5rem !important;
}
.commodity-show-info__documents-table tfoot td {
  padding: 1rem 1.5rem !important;
  background: #f2f2f2;
}
.commodity-show-info__documents-table tfoot td > * {
  margin-bottom: 0.4rem;
}
.commodity-show-info__documents-table tfoot td > *:last-child {
  margin-bottom: 0;
}
.commodity-show-info__additional-commodity {
  font-size: 1.2rem;
}
.show-gallery__previews {
  margin-top: 1rem;
}
.show-gallery__footer {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gallery-previews {
  position: relative;
  width: 100%;
}
.gallery-previews__carousel {
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  overflow: hidden;
}
.gallery-previews__list {
  width: 100%;
  display: flex;
}
.gallery-previews__item {
  position: relative;
  flex-shrink: 0;
  width: 24%;
  height: 7rem;
  margin-right: 1.33333333%;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  overflow: hidden;
}
.gallery-previews__item:last-child {
  margin-right: 0;
}
.gallery-previews__item._no-items {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #808080;
}
.gallery-previews__item.swiper-slide-thumb-active {
  border-color: #4778ff;
}
.gallery-previews__no-items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: 600;
  color: #808080;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-align: center;
}
.gallery-previews__item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery-previews__checkbox {
  position: absolute;
  z-index: 2;
  top: 0.2rem;
  left: 0.2rem;
  opacity: 0.8;
}
.gallery-previews__checkbox._checked {
  opacity: 1;
}
.gallery-previews__checkbox .checkbox__mark {
  border-color: white;
  background: rgba(51, 51, 51, 0.2);
}
.gallery-previews__nav.btn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  min-width: 4rem;
  min-height: 4rem;
  background-color: rgba(255, 255, 255, 0.8);
}
.gallery-previews__nav.btn._disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.gallery-previews__nav.btn._lock {
  display: none;
}
.gallery-previews__nav.btn:not(._disabled):hover {
  background-color: #ffffff;
}
.gallery-previews__nav.btn._left {
  left: 0;
  border-radius: 0 0.3rem 0.3rem 0;
}
.gallery-previews__nav.btn._right {
  right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}
.gallery-previews__nav.btn svg {
  fill: #808080;
  width: 1rem;
  height: 1.4rem;
}
.gallery-previews__nav.btn:not(._disabled):hover svg {
  fill: #4778ff;
}
.gallery-previews__nav.btn._left svg {
  transform: rotate(180deg);
}
.gallery-previews__nav.btn.swiper-button-lock {
  display: none;
}
.gallery-main {
  position: relative;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
}
.gallery-main__carousel {
  position: relative;
  width: 100%;
  height: 29.7rem;
  border-radius: 0.3rem;
  overflow: hidden;
}
.gallery-main__list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.gallery-main__item {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAYAAAACqyaBAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAgFJREFUSIm1ls1x2zAQhT9qVABP5pWpwEoFoTqQKwhUQaSjebFzwZV2BZYqEFOBmAqiDqIrTmEHzmEXQ4jmmOSIejOcBYgdvF3sDxDdPb4TYAGsmBals5y6FuYqY+AAZBMTA9TQTR6p50clPumXAelE5FUwPgN7Z+VfdPf4vgD+KOkeKCYi/Qw7Z1nPaWL8S4lrYAvshuzi7HDGJGcFvAEmydnPgrV7la9DicfCWUrEMYAsJI9VVrcgDnD2g9knSh4ZUglH4HlKK+Y96waJUWjIPfAwZPMkxwDfgK2z1O31Ps+fVG6BL0hFrJBm1Ee8QBLYBPuMIk9VvqA1qvO4S7mFt0Bvk+QfG1gfue9MBeLxd52fvUKSc0hy/qmn/l+BnM6JJkRh+AaR+7LYIEm3oDmF0KgYOCY5sdbyBukXay2vEkiT/DJh+xKuQmJtdH6m6QEmIKnUiCNNqH4GF8oaSdYnglLuI/eEz61/Ppn8xpWS+qMvneXFKztLneQ80Lq4htR5G/4GjBHvSi2jJRLjWg26gLNUzo479i4cEC9LghNRA76O2WiGJNAS+D1Av6C5ej94NxYzmoTpgyHIYpVXkw9BO8E6XybXkHtPsg69Ak0wJNbXIPWDKHhApsBfHW+59O5HQH4tsXdkGbVer4aONngDrJ1l1y61HZJ85obElX9A/gf/YI66TWjQqQAAAABJRU5ErkJggg=='), zoom-out;
}
.gallery-main__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gallery-main._open-in-lightbox .gallery-main__item {
  cursor: zoom-in;
}
.gallery-main._disabled .gallery-main__item {
  cursor: not-allowed;
}
.gallery-main__no-items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #808080;
}
.gallery-main__item-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery-main__item-group-name {
  position: absolute;
  left: 0;
  bottom: 3rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4rem 1rem;
}
.gallery-main__nav.btn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  min-width: 4rem;
  min-height: 4rem;
  background-color: rgba(255, 255, 255, 0.8);
}
.gallery-main__nav.btn._disabled {
  opacity: 0.5;
  pointer-events: auto;
  cursor: not-allowed;
}
.gallery-main__nav.btn:not(._disabled):hover {
  background-color: #ffffff;
}
.gallery-main__nav.btn._left {
  left: 0;
  border-radius: 0 0.3rem 0.3rem 0;
}
.gallery-main__nav.btn._right {
  right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}
.gallery-main__nav.btn svg {
  fill: #616161;
  width: 1rem;
  height: 1.4rem;
}
.gallery-main__nav.btn._left svg {
  transform: rotate(180deg);
}
.gallery-main__nav.btn.swiper-button-lock {
  display: none;
}
.commodity-show-section {
  background: white;
}
.commodity-show-section__content {
  border: 1px solid #dedede;
  border-top: 0;
  padding: 1.5rem;
}
.commodity-show-section__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.commodity-show-section__section:last-child {
  margin-bottom: 0;
}
.commodity-show-section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4778ff;
  min-height: 4rem;
  padding: 0.6rem 2rem;
  color: white;
}
.commodity-show-section__header-title {
  padding-top: 0.2rem;
  margin: 0 2rem 0 0;
  flex-grow: 1;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: uppercase;
}
.commodity-show-section__header-toggle svg {
  transition: transform 0.15s !important;
}
.commodity-show-section__header-toggle svg {
  transform: rotate(90deg);
}
.commodity-show-section__header-toggle._open svg {
  transform: rotate(270deg);
}
.commodity-show-section__sub-title {
  margin: 0 0 1.5rem;
  font-size: 1.6rem;
}
.commodity-show-section__main-info-table {
  width: 100%;
  margin: -0.5rem 0 0;
}
.commodity-show-section__main-info-table td:first-child {
  width: 50%;
}
.commodity-show-section._canceled .commodity-show-section__main-info-table {
  color: #808080;
}
.commodity-show-section__notes {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}
.commodity-show-section__notes li {
  margin: 0.6rem 0;
  position: relative;
  padding-left: 1rem;
}
.commodity-show-section__notes li:first-child {
  margin-top: 0;
}
.commodity-show-section__notes li:last-child {
  margin-bottom: 0;
}
.commodity-show-section__notes li::before {
  position: absolute;
  content: '•';
  left: 0;
}
.commodity-show-section__actions {
  background: transparent;
}
.commodity-show-section__actions .actions__btn {
  background: transparent;
}
.commodity-show-section__actions .actions__btn:hover {
  background: transparent;
}
.commodity-show-section__actions .actions__btn:hover svg {
  fill: white;
}
.commodity-show-section__actions .actions__btn svg {
  fill: white;
}
.commodity-show-section__canceled {
  margin: 0;
  width: auto;
  align-self: flex-start;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  border-radius: 0.3rem;
  background: #e17d10;
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: white;
  font-weight: 600;
}
.commodity-show-section__canceled b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: white;
  margin-right: 0.8rem;
  color: #e17d10;
}
.commodity-show-section-estimates__table {
  margin: 0 -1.5rem;
  width: auto;
  max-width: none;
}
.commodity-show-section-estimates__table:last-child {
  margin-bottom: -1.5rem;
}
.commodity-show-section-estimates__table:last-child tr:last-child td {
  border-bottom: 0;
}
.commodity-show-section-estimates__table th._actions {
  width: 5.4rem;
}
.commodity-show-section-estimates__table td {
  transition: 0.15s;
}
.commodity-show-section-estimates__table tr {
  cursor: pointer;
}
.commodity-show-section-estimates__table tr:hover td {
  background-color: #f2f2f2;
}
.commodity-show-section-estimates__pagination {
  margin-top: 1.5rem;
}
.commodity-show-documents {
  position: relative;
  font-size: 1.2rem;
}
.commodity-show-documents__manipulation {
  margin-bottom: 2rem;
}
.commodity-show-documents__content {
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.commodity-show-documents__item {
  padding: 1rem;
}
.commodity-show-documents__add,
.commodity-show-documents__file {
  width: 9rem;
  cursor: pointer;
}
.commodity-show-documents__add-main,
.commodity-show-documents__file-main {
  width: 9rem;
  height: 9rem;
  border-radius: 0.3rem;
  border: 1px dashed #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background: white;
  transition: 0.15s;
}
.commodity-show-documents__add:hover .commodity-show-documents__add-main,
.commodity-show-documents__file:hover .commodity-show-documents__file-main {
  border-color: #b8b8b8;
}
.commodity-show-documents__add-btn {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.3rem;
  background: #4778ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commodity-show-documents__add-btn svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: white;
}
.commodity-show-documents__add-name,
.commodity-show-documents__file-name {
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
}
.commodity-show-documents__file-main {
  position: relative;
  border-style: solid;
  padding: 2.2rem 1rem 0.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2em;
  color: #808080;
}
.commodity-show-documents__file-main-ico {
  width: 2.8rem;
  height: 2.8rem;
  fill: #4778ff;
  margin-bottom: 0.5rem;
}
.commodity-show-documents__file-check-wrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
.commodity-show-documents__error {
  margin-top: 1rem;
}
.commodity {
  position: relative;
}
.commodity__head {
  padding: 3rem 2rem 0;
}
.commodity__title {
  margin: 0;
  font-size: 2.4rem;
}
.commodity__main-wrap {
  display: flex;
  padding: 0 2rem 3rem;
}
.commodity__gallery {
  width: 40rem;
  margin-right: 2rem;
  flex-shrink: 0;
}
.commodity__main {
  width: 40%;
  flex-grow: 1;
}
.commodity__rows-group {
  border: 1px solid #dedede;
  padding: 0.5rem;
  margin-bottom: 2rem;
  background: #f2f2f2;
}
.commodity__rows-group:last-child {
  margin-bottom: 0;
}
.commodity__rows-group-title {
  display: block;
  margin: -0.5rem -0.5rem 1rem;
  padding: 1.3rem 2.5rem;
  background: #dedede;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.commodity__row {
  display: flex;
  margin-bottom: 2rem;
}
.commodity__row:last-child {
  margin-bottom: 0;
}
.commodity__row-item {
  width: 20%;
  flex-grow: 1;
  margin-right: 2rem;
}
.commodity__row-item:last-child {
  margin-right: 0;
}
@media (max-width: 1100px) {
  .commodity__head {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .commodity__gallery {
    margin-bottom: 3rem;
    width: 100%;
  }
  .commodity__main-wrap {
    flex-wrap: wrap;
  }
  .commodity__main {
    width: unset;
  }
}
@media (max-width: 749px) {
  .commodity__row {
    flex-wrap: wrap;
  }
  .commodity__row-item {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
}
.full-gallery {
  height: 50%;
  flex-grow: 1;
}
.full-gallery__inner {
  padding: 3rem 2.4rem 6rem;
}
.full-gallery__manipulation {
  position: sticky;
  z-index: 11;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  bottom: 1rem;
  left: 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  border-radius: 0 0.3rem 0.3rem 0;
  transition: 0.3s;
}
.full-gallery__short-btn {
  margin-right: 1rem;
}
.full-gallery__short-btn.btn {
  border-color: #b8b8b8;
}
.full-gallery__short-btn._print svg {
  fill: #e17d10;
}
.full-gallery__short-btn._print:hover {
  border-color: #e17d10;
  background-color: #e17d10;
}
.full-gallery__short-btn._email svg {
  fill: #4778ff;
}
.full-gallery__short-btn._email:hover {
  border-color: #4778ff;
  background-color: #4778ff;
}
.full-gallery__short-btn._viber svg {
  fill: #665cac;
}
.full-gallery__short-btn._viber:hover {
  border-color: #665cac;
  background-color: #665cac;
}
.full-gallery__short-btn._whatsapp svg {
  fill: #25d366;
}
.full-gallery__short-btn._whatsapp:hover {
  border-color: #25d366;
  background-color: #25d366;
}
.full-gallery__selected {
  color: #808080;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.full-gallery__unselected-all {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.full-gallery__unselected-all svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #808080;
  transition: 0.15s;
}
.full-gallery__unselected-all:hover svg {
  fill: #8f1b2c;
}
.full-gallery__main-gallery {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto 2rem;
}
.full-gallery__main-gallery .gallery-main__carousel {
  padding-top: 80%;
}
.full-gallery__main-gallery._open-in-lightbox .gallery-main__carousel {
  padding-top: 0;
  height: 37.2rem;
}
.full-gallery__previews-gallery {
  margin-bottom: 4rem;
}
.full-gallery__previews-gallery .gallery-previews__carousel,
.full-gallery__section-gallery .gallery-previews__carousel {
  display: flex;
  justify-content: center;
}
.full-gallery__previews-gallery .gallery-previews__list,
.full-gallery__section-gallery .gallery-previews__list {
  max-width: 100%;
  width: auto;
}
.full-gallery__previews-gallery .gallery-previews__item,
.full-gallery__section-gallery .gallery-previews__item {
  width: 10rem;
  margin-right: 1rem;
  padding-top: 7.3rem;
}
.full-gallery__previews-gallery .gallery-previews__item:last-child,
.full-gallery__section-gallery .gallery-previews__item:last-child {
  margin-right: 0;
}
.full-gallery__section {
  position: relative;
  margin-bottom: 4.3rem;
  padding: 1rem;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
}
.full-gallery__section:last-child {
  margin-bottom: 0;
}
.full-gallery__section-title {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #dedede;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem 0.3rem 0 0;
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.full-gallery__section-title a {
  color: inherit;
  text-decoration: none;
}
.full-gallery__section-title a:hover {
  text-decoration: underline;
}
.full-gallery__section-title-side-cols {
  width: 0;
  flex-grow: 1;
}
.full-gallery__section-title-side-cols._left {
  text-align: left;
  padding-right: 2rem;
}
.full-gallery__section-title-side-cols._right {
  text-align: left;
  padding-left: 2rem;
}
.full-gallery__section-jump-to {
  margin-right: auto;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 400;
}
.commodity-edit {
  position: relative;
}
.commodity-edit.info-block {
  margin: -2rem;
}
.commodity-edit__sub-section {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px dashed #dedede;
}
.commodity-edit__customer {
  padding: 0.8rem 1rem 0.8rem;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
  border-radius: 0.3rem;
  line-height: 1.42857143em;
}
.commodity-edit__canceled {
  margin: 0;
  width: auto;
  align-self: flex-start;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  border-radius: 0.3rem;
  background: #e17d10;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: white;
  font-weight: 600;
}
.commodity-edit__canceled b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: white;
  margin-right: 0.8rem;
  color: #e17d10;
}
.commodity-edit__input-preloader-wrap {
  position: relative;
}
.commodity-edit__input-preloader {
  width: auto;
  right: 1.8rem;
  left: auto;
}
.commodity-confirm-delete__select {
  margin-bottom: 2rem;
}
.commodity-confirm-delete__input {
  margin-bottom: 2rem;
}
.commodity-confirm-delete__cancel-btn {
  margin-right: 1.5rem;
}
.commodity-confirm-delete__error {
  margin-bottom: 2rem;
}
.commodity-confirm-delete__footer {
  display: flex;
  justify-content: flex-end;
}
.commodity-sort-files {
  position: relative;
}
.commodity-sort-files__list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}
.commodity-sort-files__item {
  width: 20%;
  padding: 0.5rem;
}
.commodity-sort-files__item-inner {
  border: 1px solid #dedede;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
}
.commodity-sort-files__error {
  margin-top: 3rem;
}
.commodity-sort-files__footer {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}
.commodity-sort-files__btn {
  margin-right: 1rem;
}
.commodity-sort-files__btn:last-child {
  margin-right: 0;
}
.add-commodity-to-loading {
  position: relative;
  min-height: 8rem;
  padding: 3rem;
}
.add-commodity-to-loading__container-title {
  margin: 2rem 0 0;
  padding: 1rem 2rem;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-bottom: 0;
  font-size: 1.4rem;
}
.add-commodity-to-loading__commodity {
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-top: 0;
  padding: 1rem 2rem;
}
.add-commodity-to-loading__commodity-main {
  margin-right: auto;
  font-size: 1.2rem;
}
.add-commodity-to-loading__commodity-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.add-commodity-to-loading__loading-right-note {
  font-size: 1.2rem;
  color: #e17d10;
}
.add-commodity-to-loading__alert {
  margin-top: 1rem;
}
.confirm-add-commodity-to-loading__content {
  margin-bottom: 2rem;
}
.confirm-add-commodity-to-loading__container-number {
  margin: 0 0 2rem;
  font-size: 1.6rem;
}
.confirm-add-commodity-to-loading__alert {
  margin-bottom: 2rem;
}
.confirm-add-commodity-to-loading__footer {
  display: flex;
  justify-content: flex-end;
}
.confirm-add-commodity-to-loading__btn {
  min-width: 9rem;
  margin-right: 1rem;
}
.confirm-add-commodity-to-loading__btn:last-child {
  margin-right: 0;
}
.confirm-container-loading {
  position: relative;
}
.confirm-container-loading__container-number {
  margin: 0 0 2rem;
  font-size: 1.6rem;
}
.confirm-container-loading__input {
  margin-bottom: 2rem;
}
.confirm-container-loading__alert {
  margin-bottom: 2rem;
}
.confirm-container-loading__footer {
  display: flex;
  justify-content: flex-end;
}
.confirm-container-loading__btn {
  min-width: 9rem;
  margin-right: 1rem;
}
.confirm-container-loading__btn:last-child {
  margin-right: 0;
}
.confirm-number__input {
  margin-bottom: 2rem;
}
.confirm-number__footer {
  display: flex;
  justify-content: flex-end;
}
.confirm-number__btn {
  min-width: 9rem;
  margin-right: 1rem;
}
.confirm-number__btn:last-child {
  margin-right: 0;
}
.add-expense {
  position: relative;
}
.add-expense__footer {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}
.add-expense__btn {
  min-width: 9rem;
  margin-right: 1rem;
}
.add-expense__btn:last-child {
  margin-right: 0;
}
.add-expense .expenses-table thead {
  top: 0;
}
.add-expense .expenses-table col._checkbox {
  width: 4.8rem;
}
.add-expense .expenses-table col._category {
  width: 18rem;
}
.add-expense .expenses-table col._memo {
  width: 32.5rem;
}
.add-expense .expenses-table col._status {
  width: 13rem;
}
.add-expense .expenses-table col._payments {
  width: 20rem;
}
.add-expense .expenses-table col._vendor {
  width: 20rem;
}
.add-expense .expenses-table col._agent {
  width: 12rem;
}
.add-expense .expenses-table col._date {
  width: 13rem;
}
.add-expense .expenses-table col._toggle {
  width: 5.3rem;
}
.message-modal-content {
  position: relative;
}
.message-modal-content__footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.message-modal-content__footer:first-child {
  margin-top: 0;
}
.message-modal-content__btn {
  min-width: 12rem;
  margin-right: 2rem;
}
.message-modal-content__btn:last-child {
  margin-right: 0;
}
.message-modal-content .text {
  text-align: center;
  white-space: pre-line;
}
.manipulation {
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.manipulation__btn {
  margin-right: 1rem;
}
.manipulation__btn.btn {
  border-color: #b8b8b8;
}
.manipulation__btn._pdf svg {
  fill: #f14e16;
}
.manipulation__btn._pdf:hover {
  border-color: #f14e16;
  background-color: #f14e16;
}
.manipulation__btn._print svg {
  fill: #e17d10;
}
.manipulation__btn._print:hover {
  border-color: #e17d10;
  background-color: #e17d10;
}
.manipulation__btn._email svg {
  fill: #4778ff;
}
.manipulation__btn._email:hover {
  border-color: #4778ff;
  background-color: #4778ff;
}
.manipulation__btn._viber svg {
  fill: #665cac;
}
.manipulation__btn._viber:hover {
  border-color: #665cac;
  background-color: #665cac;
}
.manipulation__btn._whatsapp svg {
  fill: #25d366;
}
.manipulation__btn._whatsapp:hover {
  border-color: #25d366;
  background-color: #25d366;
}
.manipulation__btn._trash svg {
  fill: #8f1b2c;
}
.manipulation__btn._trash:hover {
  border-color: #aa0300;
  background-color: #aa0300;
}
.manipulation__selected {
  color: #808080;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.manipulation__unselected-all {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.manipulation__unselected-all svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #808080;
  transition: 0.15s;
}
.manipulation__unselected-all:hover svg {
  fill: #8f1b2c;
}
.chat-preview {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  background: #f2f2f2;
  border: 1px solid #dedede;
  height: 4.8rem;
  padding: 0.4rem 4rem 0.4rem 0.5rem;
  cursor: pointer;
  transition: border-color 0.15s;
}
.chat-preview:hover {
  border-color: #b8b8b8;
}
.chat-preview__ico {
  width: 3.1rem;
  height: 3.1rem;
  fill: #b8b8b8;
  flex-shrink: 0;
  margin-right: 0.5rem;
}
.chat-preview__main {
  width: 50%;
  flex-grow: 1;
}
.chat-preview__time {
  color: #808080;
}
.chat-preview__message {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
  width: 100%;
}
.chat-preview__unread-messages-quantity {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  min-width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 0.2rem;
  border-radius: 1.1rem;
  background: #4778ff;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2em;
  font-weight: 700;
}
.chat {
  height: 60rem;
  max-height: calc(90vh - 6rem);
  display: flex;
  flex-direction: column;
}
.chat__list {
  overflow-y: auto;
  height: 10rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
}
.chat__no-messages {
  margin: auto 0;
  text-align: center;
  color: #808080;
}
.chat__message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  max-width: calc(100% - 4rem);
}
.chat__message:first-child {
  margin-top: auto;
}
.chat__message:last-child {
  margin-bottom: 0;
}
.chat__message._my {
  align-self: flex-end;
  max-width: calc(100% - 8rem);
}
.chat__message-person-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background: #f2f2f2 no-repeat center;
  background-size: cover;
  margin-right: 0.8rem;
  flex-shrink: 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: #808080;
}
.chat__message-head {
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  color: #808080;
}
.chat__message-head b {
  font-weight: 600;
}
.chat__message._my .chat__message-head {
  text-align: right;
}
.chat__message-text {
  background-color: #f2f2f2;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
}
.chat__message._my .chat__message-text {
  background-color: #ddeffe;
}
.chat__input-wrap {
  position: relative;
  border-top: 1px solid #dedede;
  padding: 0 1.5rem;
}
.chat__input p {
  margin: 0;
}
.chat__input .editor-for-chat__content > .ProseMirror {
  padding: 1.3rem 0;
}
.chat__btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  bottom: 0;
  transform: translateY(-50%);
  width: 2.9rem;
  height: 3.2rem;
  border-radius: 0.3rem;
  background: #4778ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s;
}
.chat__btn:not(:disabled):hover {
  text-decoration: unset;
}
.chat__btn:not(:disabled):hover {
  text-decoration: unset;
}
.chat__btn svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: white;
}
.chat__btn:hover {
  background-color: #478aff;
}
.editor-for-chat__content {
  width: 100%;
}
.section {
  display: flex;
  flex-direction: column;
}
.section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4778ff;
  min-height: 4rem;
  padding: 0.6rem 2rem;
  color: white;
}
.section._wrap > .section__header {
  background: #dedede;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  color: inherit;
}
.section__header-title {
  padding-top: 0.2rem;
  margin: 0 2rem 0 0;
  flex-grow: 1;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  text-transform: uppercase;
}
.section__content {
  border: 1px solid #dedede;
  border-top: 0;
  padding: 1.5rem;
}
.section._wrap > .section__content {
  padding: 1rem 0.5rem 0.5rem;
}
.commodity-need-delivery-information {
  position: relative;
}
.commodity-need-delivery-information__commodity {
  margin-bottom: 3rem;
}
.commodity-need-delivery-information__section {
  margin-bottom: 2rem;
}
.commodity-need-delivery-information__radio-list-wrap {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.commodity-need-delivery-information__footer {
  display: flex;
  justify-content: flex-end;
}
.commodity-need-delivery-information__btn {
  margin-right: 1rem;
}
.commodity-need-delivery-information__btn:last-child {
  margin-right: 0;
}
.actions-buttons {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.actions-buttons__btn {
  margin-right: 1rem;
}
.actions-buttons__btn:last-child {
  margin-right: 0;
}
.actions-buttons__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.actions-buttons__unselected-all {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.actions-buttons__unselected-all svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #808080;
  transition: 0.15s;
}
.actions-buttons__unselected-all:hover svg {
  fill: #8f1b2c;
}
.commodity-list-table-waiting-list th._in-file,
.commodity-list-table-waiting-list td._in-file {
  width: 10rem;
}
.commodity-list-table-need-to-be-dispatch th:first-child,
.commodity-list-table-need-to-be-dispatch td:first-child {
  width: 17rem;
}
.commodity-list-table-need-to-be-dispatch th._origin-destination,
.commodity-list-table-need-to-be-dispatch td._origin-destination {
  width: 20rem;
}
.commodity-list-table-need-to-be-dispatch th._trailer-type,
.commodity-list-table-need-to-be-dispatch td._trailer-type {
  width: 14rem;
}
.commodity-list-table-need-to-be-dispatch th:nth-child(6),
.commodity-list-table-need-to-be-dispatch td:nth-child(6) {
  width: 12rem;
}
.commodity-list-table-need-to-be-dispatch th:nth-child(7),
.commodity-list-table-need-to-be-dispatch td:nth-child(7) {
  width: 6rem;
}
@media screen and (max-width: 1920px) {
  .commodity-list-table-need-to-be-dispatch th._origin-destination,
  .commodity-list-table-need-to-be-dispatch td._origin-destination {
    width: 18rem;
  }
  .commodity-list-table-need-to-be-dispatch th._trailer-type,
  .commodity-list-table-need-to-be-dispatch td._trailer-type {
    width: 10rem;
  }
}
@media screen and (max-width: 1600px) {
  .commodity-list-table-need-to-be-dispatch th._origin-destination,
  .commodity-list-table-need-to-be-dispatch td._origin-destination {
    width: 14rem;
  }
  .commodity-list-table-need-to-be-dispatch th._trailer-type,
  .commodity-list-table-need-to-be-dispatch td._trailer-type {
    width: 9rem;
  }
}
.commodity-list-table-pending-delivery th:nth-child(4),
.commodity-list-table-pending-delivery td:nth-child(4) {
  width: 14rem;
}
.commodity-list-table-pending-delivery th:nth-child(5),
.commodity-list-table-pending-delivery td:nth-child(5) {
  width: 10rem;
}
.commodity-list-table-pending-delivery th:nth-child(6),
.commodity-list-table-pending-delivery td:nth-child(6) {
  width: 10rem;
}
.commodity-list-table-dispatch-canceled th._status-is-set,
.commodity-list-table-dispatch-canceled td._status-is-set {
  width: 10rem;
}
.commodity-list-table-in-yard th:nth-child(4),
.commodity-list-table-in-yard td:nth-child(4) {
  width: 16rem;
}
.commodity-list-table-in-yard th:nth-child(5),
.commodity-list-table-in-yard td:nth-child(5) {
  width: 16rem;
  flex-grow: 1;
}
.commodity-list-table-in-yard th:nth-child(6),
.commodity-list-table-in-yard td:nth-child(6) {
  width: 12.5rem;
}
.commodity-list-table-in-file th:nth-child(4),
.commodity-list-table-in-file td:nth-child(4) {
  width: 12rem;
  text-align: left;
  align-items: flex-start;
}
.commodity-list-table-deleted th:nth-child(4),
.commodity-list-table-deleted td:nth-child(4) {
  width: 16rem;
}
.commodity-list-table-deleted th:nth-child(5),
.commodity-list-table-deleted td:nth-child(5) {
  width: 16rem;
}
.commodity-list-table-deleted th:nth-last-child(2),
.commodity-list-table-deleted td:nth-last-child(2) {
  width: 10rem;
}
.commodity-full-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.commodity-full-edit__section {
  margin-bottom: 1px;
}
.commodity-full-edit__btn-save {
  position: sticky;
  z-index: 71;
  bottom: 2rem;
  min-width: 10rem;
  align-self: center;
}
.commodity-full-edit__btn-save:disabled {
  background-color: #ade0c6;
  opacity: 1;
}
.invoice-edit {
  min-height: 3rem;
}
.invoice-edit__items-forms-wrap {
  border-top: 1px dashed #dedede;
  padding: 3rem 2rem 2rem;
}
.invoice-edit__items-form-variant-select {
  width: 30rem;
  max-width: 100%;
  margin-bottom: 2rem;
}
.invoice-edit__items-form {
  width: 100%;
  margin: 0;
  border: 1px solid #dedede;
}
.invoice-edit__items-form > table {
  width: 100%;
  margin: 0;
}
.invoice-edit__items-form td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0;
  vertical-align: top;
}
.invoice-edit__items-form tbody tr:first-child td {
  padding-top: 2rem;
  border-top: 1px dashed #dedede;
}
.invoice-edit__items-form thead + tbody tr:first-child td {
  padding-top: 2.5rem;
  border-top: 0;
}
.invoice-edit__items-form thead + tbody tr:first-child td:last-child {
  padding-top: 2.9rem;
}
.invoice-edit__items-form td:first-child,
.invoice-edit__items-form th:first-child {
  width: 28%;
}
.invoice-edit__items-form td:last-child,
.invoice-edit__items-form th:last-child {
  width: 5.4rem;
}
.invoice-edit__items-form td:last-child {
  padding-top: 2rem;
}
.invoice-edit__items-form td:nth-last-child(2),
.invoice-edit__items-form th:nth-last-child(2) {
  width: 16rem;
}
.invoice-edit__items-form tfoot td {
  padding-bottom: 2.4rem;
  padding-top: 1rem;
}
.invoice-edit__items-form tr._has-contract td {
  padding-bottom: 1rem;
}
.invoice-edit__items-editor .ProseMirror {
  min-height: 1rem;
}
.invoice-edit__result {
  margin-top: 1.2rem;
  padding: 2.4rem 2rem;
  border-top: 1px solid #dedede;
}
.invoice-edit__result-inner {
  display: flex;
  align-items: flex-start;
  grid-gap: 1.6rem;
}
.invoice-edit__attach {
  display: flex;
  flex-direction: column;
  grid-gap: 1.6rem;
}
.invoice-edit__attach-group {
  position: relative;
}
.invoice-edit__attach-group-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.invoice-edit__attach-group-item {
  margin-bottom: 0.5rem;
}
.invoice-edit__attach-group-item:last-child {
  margin-bottom: 0;
}
.invoice-edit__result-table {
  margin: 0;
  width: 60rem;
  max-width: 100%;
  margin-left: auto;
}
.invoice-edit__result-table td:first-child {
  width: 45%;
}
.invoice-edit__result-table tfoot td {
  background-color: #f2f2f2;
}
.invoice-edit__result-table td:nth-last-child(2) {
  width: 10rem;
}
.invoice-edit__result-table td:last-child {
  width: 5.4rem;
}
.invoice-edit__main-section-footer {
  margin-top: 1.5rem;
}
.invoice-edit__taxes {
  width: 20rem;
}
.invoice-edit__alert {
  margin-top: 2rem;
}
.invoice-edit .contract-item td {
  padding-top: 0;
  text-align: right;
}
.auth-form {
  position: relative;
  width: 40rem;
  max-width: 100%;
  padding: 3rem 2rem;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  background: white;
}
.auth-form__input {
  margin-bottom: 2rem;
}
.auth-form__error {
  margin-bottom: 2rem;
}
.auth-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.auth-form__title {
  font-size: 2rem;
  margin: 0 0 2rem;
}
.preview-person-with-photo {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.preview-person-with-photo__photo-wrap {
  position: relative;
  background: #dedede;
  border-radius: 50%;
  width: 5.5rem;
  height: 5.5rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-right: 1rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-person-with-photo._without-photo-flattening .preview-person-with-photo__photo-wrap {
  margin-top: 0;
  margin-bottom: 0;
}
.preview-person-with-photo__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.preview-person-with-photo__photo-placeholder {
  opacity: 0.5;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.preview-person-with-photo__info {
  width: 50%;
  flex-grow: 1;
  padding-top: 1px;
}
.preview-person-with-photo__info > * {
  margin-bottom: 0.4rem;
}
.preview-person-with-photo__info > *:last-child {
  margin-bottom: 0;
}
.preview-person-with-photo__status {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #b8b8b8;
  border: 1px solid #b8b8b8;
}
.preview-person-with-photo__status._active {
  background-color: #14a75d;
  border-color: #14a75d;
}
.preview-person-with-photo__status._inactive {
  background-color: #e7a811;
  border-color: #e7a811;
}
.preview-person-with-photo__status._deleted {
  background-color: #8f1b2c;
  border-color: #8f1b2c;
}
.preview-person-with-photo__status-reg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  color: #f2f2f2;
}
.preview-commodity {
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.preview-commodity:hover {
  color: inherit;
}
.preview-commodity__content {
  width: 100%;
  display: flex;
  align-items: center;
}
.preview-commodity__photo-wrap {
  margin: -0.5rem 1rem -0.5rem 0;
}
.preview-commodity__descr-main {
  width: 50%;
  flex-grow: 1;
}
.preview-commodity__descr-main .status:not(:first-child) {
  margin-top: 0.2rem;
}
.preview-commodity__vin:first-child {
  margin-top: 0;
}
.preview-commodity__name + .preview-commodity__vin {
  margin-top: 0.2rem;
}
.document-creations-options__main {
  position: relative;
  min-height: 9rem;
}
.document-creations-options__type-select {
  width: 56rem;
  max-width: 100%;
}
.document-creations-options__type-select .select__dropdown {
  max-height: 20.5rem;
}
.document-creations-options__commodities {
  width: 100%;
  margin: 2rem 0 0;
}
.document-creations-options__commodities th:first-child {
  width: 5.4rem;
}
.document-creations-options__error {
  margin-top: 3rem;
}
.document-creations-options__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}
.document-creations-options__btn {
  margin-right: 2rem;
}
.document-creations-options__btn:last-child {
  margin-right: 0;
}
.document-creations-edit {
  position: relative;
}
.document-creations-edit__name {
  margin-bottom: 2rem;
}
.document-creations-edit__error {
  margin-top: 3rem;
}
.document-creations-edit__footer {
  display: flex;
  margin-top: 3rem;
}
.document-creations-edit__separator {
  margin: 0 auto;
}
.document-creations-edit__btn {
  margin-right: 2rem;
}
.document-creations-edit__btn:last-child {
  margin-right: 0;
}
.document-creations-edit__document-wrap {
  min-width: 0;
  overflow: auto;
  padding: 2rem 2.4rem;
  margin: -2rem -2.4rem;
}
@media (max-width: 749px) {
  .document-creations-edit__document-wrap {
    padding: 2rem 1.6rem;
    margin: -2rem -1.6rem;
  }
}
.file-customer-documents {
  position: relative;
}
.file-customer-documents__content {
  padding: 2rem 1rem;
  margin: -2rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.file-customer-documents__item {
  padding: 1rem;
}
.file-customer-documents__add,
.file-customer-documents__file {
  width: 9rem;
  cursor: pointer;
}
.file-customer-documents__add-main,
.file-customer-documents__file-main {
  width: 9rem;
  height: 9rem;
  border-radius: 0.3rem;
  border: 1px dashed #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background: white;
  transition: 0.15s;
}
.file-customer-documents__add:hover .file-customer-documents__add-main,
.file-customer-documents__file:hover .file-customer-documents__file-main {
  border-color: #b8b8b8;
}
.file-customer-documents__add-btn {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.3rem;
  background: #4778ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-customer-documents__add-btn svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: white;
}
.file-customer-documents__add-name,
.file-customer-documents__file-name {
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
}
.file-customer-documents__file-main {
  position: relative;
  border-style: solid;
  padding: 2.2rem 1rem 0.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2em;
  color: #808080;
}
.file-customer-documents__file-main-ico {
  width: 2.8rem;
  height: 2.8rem;
  fill: #4778ff;
  margin-bottom: 0.5rem;
}
.file-customer-documents__file-check-wrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
.file-customer-documents__error {
  margin-top: 1rem;
}
.editor-for-doc {
  position: relative;
}
.editor-for-doc b,
.editor-for-doc strong {
  font-weight: bold;
}
.editor-for-doc p {
  margin: 0;
}
.editor-for-doc .ProseMirror {
  background: #e4eefb;
}
.editor-for-doc .menububble {
  position: absolute;
  z-index: 5;
  transform: translate(-50%, -20px);
  display: flex;
  background: #333333;
  padding: 0.5rem;
  border-radius: 0.3rem;
  visibility: hidden;
  opacity: 0;
}
.editor-for-doc .menububble.is-active {
  visibility: visible;
  opacity: 1;
}
.editor-for-doc .ProseMirror {
  min-height: 18px;
}
.editor-for-doc__btn {
  padding: 0.4rem;
  min-height: 2.6rem;
  min-width: 2.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  background: transparent;
}
.editor-for-doc__btn:last-child {
  margin-right: 0;
}
.editor-for-doc__btn svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: #808080;
}
.editor-for-doc__btn._active svg,
.editor-for-doc__btn:hover svg {
  fill: white;
}
.notes-list-simple {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}
.notes-list-simple li {
  margin: 0.6rem 0;
  position: relative;
  padding-left: 1rem;
}
.notes-list-simple li:first-child {
  margin-top: 0;
}
.notes-list-simple li:last-child {
  margin-bottom: 0;
}
.notes-list-simple li::before {
  position: absolute;
  content: '•';
  left: 0;
}
.root-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: padding-left 0.3s;
}
.root-page__main {
  flex-grow: 1;
  width: calc(100% - 25rem);
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.root-page._less .root-page__main {
  width: calc(100% - 5rem);
}
.root-page._full-width .root-page__main {
  width: 100%;
}
.root-page__main-header {
  position: sticky;
  z-index: 83;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.15);
}
.root-page__content-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.root-page__content {
  background: white;
  flex-grow: 1;
}
@media (max-width: 1600px) {
  .root-page__content-wrap {
    padding-right: 0;
  }
}
@media (max-width: 1340px) {
  .root-page__content-wrap {
    padding: 0;
  }
  .root-page._less .root-page__main,
  .root-page__main {
    width: 100%;
  }
}
.single-page {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: auto;
  width: calc(100% - 30rem);
  padding: 0 0 1rem;
  flex: 1 1 0;
  min-height: 20rem;
}
@media (max-width: 980px) {
  .single-page {
    margin-left: 0;
    width: auto;
  }
}
.single-page__back-wrap {
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-page__add-btn {
  padding: 0 0.4rem;
  width: 3rem;
  min-height: 3rem;
  font-size: 2.4rem;
  line-height: 1em;
}
.single-page__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem 0;
}
@media (max-width: 1100px) {
  .single-page__head {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0;
  }
  .single-page__head:first-child {
    padding-top: 1.6rem;
  }
}
.single-page__head-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.single-page__head-group .single-page__back-btn {
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 1100px) {
  .single-page__head-group .single-page__back-btn {
    margin-top: 0;
  }
}
@media (max-width: 980px) {
  .single-page {
    margin-left: 0;
  }
}
.single-page__btns-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: sticky;
  left: 0;
  z-index: 2;
}
.single-page__btns-separator {
  display: block;
  flex: 1;
}
.single-page__btn {
  min-width: 11rem;
  margin-right: 1rem;
}
.single-page__btn:last-child {
  margin-right: 0;
}
.single-page__edit-btn {
  margin-left: auto;
}
.single-page__info {
  margin-bottom: 2rem;
}
.single-page__info-table {
  margin: 0;
}
.single-page__info-table td {
  padding: 0 0 0.8rem;
  border: 0;
}
.single-page__info-table td:first-child {
  padding-right: 1rem;
  color: #808080;
}
.single-page__tab-block {
  padding: 2rem 2rem 2rem;
}
.single-page__tab-block .customer-reports {
  overflow: visible;
}
.single-page__tab-block._no-offset {
  padding: 0;
}
.single-page__back-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
  align-items: center;
  font-weight: 600;
  color: #1f1f1f;
}
.single-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.single-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.single-page__back-btn svg {
  width: 0.6rem;
  height: 1rem;
  transform: scaleX(-1);
  fill: currentColor;
  margin-right: 0.9rem;
  flex-shrink: 0;
}
.single-page__table,
.single-page__logs-table,
.single-page__payments-table {
  width: 100%;
  margin: 0;
}
.single-page__logs-table th:first-child,
.single-page__logs-table td:first-child {
  width: 12rem;
}
.single-page__logs-table .text ul {
  padding: 0 1.8rem;
}
.single-page__table td._photo {
  width: 6rem;
}
.single-page__table td._photo:first-child {
  width: 7rem;
}
.single-page__comments {
  list-style: none;
  margin: 0;
  padding: 0;
}
.single-page__comments li {
  margin: 0.6rem 0;
  font-size: 1.2rem;
}
.single-page__comments li::before {
  content: '•';
  margin-right: 0.8rem;
}
.single-page__add-comment {
  margin-top: 1.4rem;
}
.single-page__blank-wrap {
  padding: 2rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
}
@media (max-width: 1100px) {
  .single-page__blank-wrap:not(:first-child) {
    padding-top: 0;
  }
}
.single-page__blank-container {
  position: relative;
  margin: 0 auto;
}
.single-page__blank {
  position: relative;
}
.single-page__blank .ribbon {
  display: block !important;
}
.single-page__table-photo-wrap {
  display: block;
  width: 4rem;
  height: 4rem;
  background: #f2f2f2;
  line-height: 0;
}
.single-page__table-photo-wrap:hover {
  opacity: 0.8;
  cursor: zoom-in;
}
.single-page__table-photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.single-page__no-elements {
  text-align: center;
}
.single-page__actions {
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
}
.single-page__drop-menu {
  margin-left: 1rem;
}
.single-page__drop-menu .dropdown__item {
  white-space: nowrap;
}
.single-page__title {
  margin: 0 2rem 0 0;
  font-size: 2.4rem;
}
.single-page__files-list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px 0 0 -20px;
}
.single-page__file-wrap {
  width: 120px;
  margin: 20px 0 0 20px;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  cursor: zoom-in;
}
.single-page__file {
  width: 100%;
}
.single-page__files-empty {
  padding: 1.1rem 2rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.single-page__not-found {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.single-page__not-found-content {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal .single-page {
  overflow: visible;
  width: auto;
  padding: 0;
  flex: 0 1 auto;
  margin: 0;
}
.side-nav + .customers-page._full-width .customers-page__main {
  padding-left: 0;
}
.customers-page._full-width .customers-page__main {
  padding-left: 2rem;
}
.customers-page._full-width .customers-page__one-person {
  width: 100%;
}
.customers-page__main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 38rem;
  flex-grow: 1;
  background: white;
}
.customers-page__persons-list {
  width: 100%;
}
.customers-page__persons-list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
}
.customers-page__one-person {
  margin-left: auto;
  width: calc(100% - 30rem);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.customers-page__status {
  margin-right: auto;
  flex-shrink: 1;
  max-width: 40%;
}
.customers-page__status .dropdown__dropdown {
  width: 14rem;
}
.customers-page__status .dropdown__btn {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  min-height: 2.8rem;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  font-weight: 600;
  color: #1f1f1f;
}
.customers-page__status .dropdown__arrow svg {
  fill: #333333 !important;
}
@media screen and (max-width: 1600px) {
  .customers-page__persons-list._short {
    width: 26rem;
  }
  .customers-page__one-person {
    width: calc(100% - 26rem);
  }
}
@media screen and (max-width: 1340px) {
  .customers-page__main {
    padding: 0 !important;
  }
}
@media screen and (max-width: 749px) {
  .customers-page__main {
    background: transparent;
  }
  .customers-page .person-info__header {
    padding-top: 0;
    border-bottom: 0;
  }
  .customers-page__persons-list {
    background: transparent;
  }
  .customers-page__persons-list._short {
    display: none;
  }
  .customers-page__one-person {
    margin-left: 0;
    width: 100%;
    height: auto;
  }
  .customers-page._show-one-customer .customers-page__head {
    display: none;
  }
  .customers-page__back-wrap {
    padding: 1.6rem;
  }
  .customers-page__back-btn {
    border-radius: 0;
    border: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    display: inline-flex;
    font: inherit;
    text-align: inherit;
    color: inherit;
    text-decoration: none;
    font-size: 1.2rem;
    align-items: center;
    font-weight: 600;
    color: #1f1f1f;
  }
  .customers-page__back-btn:not(:disabled):hover {
    text-decoration: unset;
  }
  .customers-page__back-btn:not(:disabled):hover {
    text-decoration: unset;
  }
  .customers-page__back-btn svg {
    width: 0.6rem;
    height: 1rem;
    transform: scaleX(-1);
    fill: currentColor;
    margin-right: 0.9rem;
    flex-shrink: 0;
  }
}
@media screen and (max-width: 1100px) {
  .commodities-page__main-content {
    background-color: transparent;
  }
}
.commodity-page__main-content {
  background: white;
}
.commodity-page__photos-wrap {
  padding: 2.4rem 2rem;
}
.new-commodity-page__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.new-commodity-page__main-content {
  position: relative;
  background: white;
  flex-grow: 1;
}
.new-commodity-page__head {
  padding: 1.5rem 2rem 0;
}
.new-commodity-page__back-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
  align-items: center;
  font-weight: 600;
  color: #1f1f1f;
}
.new-commodity-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.new-commodity-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.new-commodity-page__back-btn svg {
  width: 0.6rem;
  height: 1rem;
  transform: scaleX(-1);
  fill: currentColor;
  margin-right: 0.9rem;
  flex-shrink: 0;
}
@media (max-width: 1100px) {
  .new-commodity-page__main {
    padding-right: 0;
  }
}
.expenses-page__main-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.expenses-page__list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
  border-right: 1px solid #dedede;
}
@media (max-width: 1100px) {
  .expenses-page__list._short {
    display: none;
  }
}
.expense-page__btns-wrap {
  padding: 2rem 2rem 0;
}
.expense-page__section-title {
  margin: 0;
  padding: 0 2rem;
  font-size: 2rem;
}
.expense-page__expense {
  padding: 0 0 2rem;
}
.expense-page__main-param {
  margin: 0;
  font-size: 1.6rem;
}
.expense-page__main-param._balance {
  font-size: 1.4rem;
}
.expense-page__photos-list {
  display: flex;
  flex-wrap: wrap;
}
.expense-page__photo-wrap {
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #dedede;
  cursor: zoom-in;
  overflow: hidden;
}
.expense-page__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1100px) {
  .expense-page {
    margin-left: 0;
    width: auto;
  }
}
.new-expense-page__main-content {
  position: relative;
  min-height: 8rem;
}
.new-expense-page__form-section {
  padding: 3rem 2rem;
  border-bottom: 1px solid #dedede;
}
.new-expense-page__row {
  margin-bottom: 3rem;
  align-items: flex-start;
}
.new-expense-page__row:last-child {
  margin-bottom: 0;
}
.new-expense-page__scan {
  display: flex;
  align-items: center;
}
.new-expense-page__scan-title {
  font-size: 1.4rem;
  margin: 0 0 1rem;
}
.new-expense-page__scanned-file {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 0.3rem;
  border: 1px solid #dedede;
  overflow: hidden;
  margin-right: 2rem;
}
.new-expense-page__scanned-file-delete {
  position: absolute;
  z-index: 5;
  top: 0.2rem;
  right: 0.2rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  padding: 0;
  background: #b8b8b8;
  cursor: pointer;
  transition: 0.15s;
}
.new-expense-page__scanned-file-delete svg {
  fill: white;
}
.new-expense-page__scanned-file-delete:hover {
  opacity: 1;
  border-color: #aa0300;
  background-color: #aa0300;
}
.new-expense-page__select-wrap {
  display: flex;
  align-items: center;
}
.new-expense-page__light-btn {
  padding: 0;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  margin-right: 2.2rem;
  background: transparent;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
}
.new-expense-page__light-btn:disabled {
  cursor: not-allowed;
}
.new-expense-page__light-btn:hover {
  text-decoration: none;
  color: #4778ff;
}
.new-expense-page__result-info-wrap {
  padding: 0 2rem;
}
.new-expense-page__result-info {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: auto;
  width: 30rem;
}
.new-expense-page__footer {
  padding: 3rem 2rem;
  border-top: 1px solid #dedede;
}
.new-expense-page__logs {
  margin-bottom: 2.4rem;
}
.new-expense-page__footer-main {
  display: flex;
  justify-content: space-between;
}
.new-expense-page__footer-btn {
  margin-right: 1rem;
}
.new-expense-page__footer-btn:last-child {
  margin-right: 0;
}
.new-expense-page__add-bills {
  position: relative;
  margin-right: 3rem;
}
.new-expense-page__add-bills-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.new-expense-page__add-bills-item {
  margin-bottom: 0.5rem;
}
.new-expense-page__add-bills-item:last-child {
  margin-bottom: 0;
}
.new-expense-page__uploading-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.new-expense-page p {
  margin: 0;
}
.new-expense-page__print-checkbox {
  width: 30rem;
}
.new-expense-page__print-checkbox .text {
  margin-top: 1rem;
}
.new-expense-page__total:first-child .new-expense-page__total-value {
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 600;
}
.bookings-page__main-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.bookings-page__booking-list {
  min-width: 30rem;
  flex: 1 1 40rem;
  border-right: 1px solid #dedede;
}
.bookings-page .booking-list__table thead {
  position: sticky;
  top: 10.4rem;
  z-index: 32;
}
.bookings-page__booking-list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
}
.booking-page .single-page__blank-wrap._modal-block-content {
  margin-left: auto;
  margin-right: auto;
}
.booking-page__drop-menu {
  margin-left: 1rem;
}
.booking-page__drop-menu .dropdown__item {
  white-space: nowrap;
}
.new-booking-page {
  flex-grow: 1;
}
.new-booking-page__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.new-booking-page__main-content {
  background: white;
  flex-grow: 1;
}
.new-file-page {
  position: relative;
  display: flex;
  flex-direction: column;
}
.new-file-page__main {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.new-file-page__main._new-file {
  padding-right: 0;
}
.new-file-page__booking-section {
  margin: 0;
  width: 100%;
  border: none;
  margin-bottom: 3rem;
}
.new-file-page__booking-section .info-block__main {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.new-file-page__booking-section .info-block__row {
  margin-top: 2rem;
}
.new-file-page__booking-section .info-block__row:first-child {
  margin-top: 0;
}
@media (max-width: 1100px) {
  .new-file-page__booking-section {
    margin-top: 2rem;
  }
}
.new-file-page__main-content {
  flex-grow: 1;
  background: white;
  padding-bottom: 3rem;
}
.new-file-page__booking-table {
  width: 100%;
  margin: 0 0 4rem;
}
.new-file-page__booking {
  width: 100%;
}
.new-file-page__trucking-section {
  margin-bottom: 3rem;
  position: relative;
}
.new-file-page__trucking {
  margin-top: 2rem;
}
.new-file-page__tab-block {
  padding: 2rem;
}
.new-file-page__tabs .tabs__tab {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
.new-file-page__trucking-info-title {
  margin: 0 0 0.6rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
  font-weight: 400;
}
.new-file-page__trucking-info {
  margin: 0;
}
.new-file-page._short .expenses-table col:first-child,
.new-file-page._short .expenses-table th:first-child,
.new-file-page._short .expenses-table td:first-child,
.new-file-page._short .expenses-table col:nth-child(2),
.new-file-page._short .expenses-table th:nth-child(2),
.new-file-page._short .expenses-table td:nth-child(2),
.new-file-page._short .expenses-table col:nth-last-child(2),
.new-file-page._short .expenses-table th:nth-last-child(2),
.new-file-page._short .expenses-table td:nth-last-child(2) {
  display: none;
}
.new-file-page._short .expenses-table th:nth-last-child(3),
.new-file-page._short .expenses-table td:nth-last-child(3) {
  padding-right: 2rem;
}
.new-file-page._short .expenses-table th:nth-child(3),
.new-file-page._short .expenses-table td:nth-child(3) {
  padding-left: 2rem;
}
.new-file-page._short .expenses-table .expenses-table__cell-content {
  max-width: 10rem;
}
.new-file-page__add-booking {
  padding: 2rem;
}
.new-file-page__add-icon {
  margin-right: 1rem !important;
}
.new-file-page__number-edit {
  position: relative;
  display: flex;
  align-items: center;
  margin: -0.5rem 0;
}
.new-file-page__button-edit {
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem !important;
  min-width: 2.5rem !important;
  margin-left: 2rem;
}
.new-file-page__header-new {
  justify-content: initial;
}
.new-file-page__save-file {
  padding: 0 2rem 0 2rem;
  display: flex;
  justify-content: flex-end;
}
.new-file-page__error {
  margin: 0 2rem 2rem;
}
.files-page__main,
.files-page__main-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.files-page__files-list {
  min-width: 29rem;
  flex: 1 1 29rem;
  border-right: 1px solid #dedede;
}
.files-page .files-list__table thead {
  position: sticky;
  top: 10.5rem;
  z-index: 32;
}
.files-page__files-list._short,
.files-page__one-file {
  height: 45rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.files-page__files-list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
}
.files-page__one-file {
  margin-left: auto;
  width: calc(100% - 30rem);
  height: 45rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1100px) {
  .files-page__files-list._short {
    display: none;
  }
  .files-page__one-file {
    width: 100%;
    overflow: visible;
    height: auto;
    min-height: auto;
    background-color: white;
  }
}
.file-page {
  position: relative;
  min-height: 30rem;
  padding-bottom: 3rem;
}
.file-page__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem;
}
.file-page__title-wrap {
  display: flex;
  align-items: center;
  margin: 0 2rem 0 0;
}
.file-page__title-wrap .file-page__title {
  margin-right: 1rem;
}
.file-page__title-wrap .file-page__title:last-child {
  margin-right: 0;
}
.file-page__title {
  margin: 0 2rem 0 0;
  font-size: 2.4rem;
}
.file-page__booking-section {
  width: 100%;
  margin: 0 0 4rem;
}
.file-page__booking {
  width: 100%;
  margin: 0 0 3rem;
}
.file-page__booking th {
  background-color: #f2f2f2;
  border-top: 1px solid #dedede;
}
.file-page__trucking-section {
  margin-bottom: 1rem;
}
.file-page__container {
  margin-top: 0;
  margin-bottom: 2rem;
}
.file-page__back-wrap {
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-page__add-btn {
  padding: 0 0.4rem;
  width: 3rem;
  min-height: 3rem;
  font-size: 2.4rem;
  line-height: 1em;
}
.file-page__back-btn {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
  align-items: center;
  font-weight: 600;
  color: #1f1f1f;
}
.file-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.file-page__back-btn:not(:disabled):hover {
  text-decoration: unset;
}
.file-page__back-btn svg {
  width: 0.6rem;
  height: 1rem;
  transform: scaleX(-1);
  fill: currentColor;
  margin-right: 0.9rem;
  flex-shrink: 0;
}
@media (max-width: 1100px) {
  .file-page__head {
    padding: 0 1.6rem 1.6rem;
  }
  .file-page__head:first-child {
    padding-top: 1.6rem;
  }
  .file-page__title {
    font-size: 2rem;
  }
}
.dashboard-page__menu-btn {
  padding: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0;
  background: transparent;
  margin-right: 1rem;
}
.dashboard-page__menu-btn:hover {
  background-color: #dedede;
}
.dashboard-page__menu-btn svg {
  fill: #333333;
  width: 2rem;
}
.dashboard-page__menu-btn:hover svg {
  fill: #478aff;
}
.dashboard-page__title {
  margin-right: auto;
}
.dashboard-page__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-page__main-content {
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.invoices-page__main-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.invoices-page__list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  border-right: 1px solid #dedede;
}
@media (max-width: 980px) {
  .invoices-page .invoices-list._short {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .invoices-page__invoices-list {
    min-width: 32rem;
    flex-basis: 32rem;
  }
  .invoices-page__one-invoice {
    flex: 1 0 calc(100% - 32rem);
  }
}
@media screen and (max-width: 1340px) {
  .invoices-page__invoices-list {
    min-width: 29rem;
    flex-basis: 29rem;
  }
  .invoices-page__one-invoice {
    flex: 1 0 calc(100% - 29rem);
  }
}
.new-estimate-page__main-content {
  background: white;
}
.invoices-generators-page__main-content {
  position: relative;
  display: flex;
}
.invoices-generators-page__invoices-list {
  min-width: 40rem;
  flex: 1 1 40rem;
  border-right: 1px solid #dedede;
}
@media screen and (max-width: 980px) {
  .invoices-generators-page__invoices-list {
    display: none;
  }
}
.invoices-generators-page__one-invoice {
  flex: 1 0 calc(100% - 40rem);
}
.invoices-generators-page .single-page__back-btn {
  margin-bottom: 2rem;
}
@media screen and (max-width: 1600px) {
  .invoices-generators-page__invoices-list {
    min-width: 32rem;
    flex-basis: 32rem;
  }
  .invoices-generators-page__one-invoice {
    flex: 1 0 calc(100% - 32rem);
  }
}
@media screen and (max-width: 1340px) {
  .invoices-generators-page__invoices-list {
    min-width: 29rem;
    flex-basis: 29rem;
  }
  .invoices-generators-page__one-invoice {
    flex: 1 0 calc(100% - 29rem);
  }
}
.new-rec-invoices-page__main-content {
  background: white;
}
.payments-received-page__main-content {
  background: white;
  display: flex;
}
.payments-received-page__payments-list {
  min-width: 29rem;
  flex: 1 1 29rem;
  border-right: 1px solid #dedede;
}
.payments-received-page__one-payment {
  flex: 1 0 calc(100% - 29rem);
}
.payment-received-edit-result-info {
  border: 1px dashed #dedede;
  border-radius: 0.3rem;
  padding: 1.5rem 2rem;
}
.payment-received-edit-result-info__item {
  display: flex;
  margin-bottom: 1rem;
  text-align: right;
}
.payment-received-edit-result-info__item:last-child {
  margin-bottom: 0;
}
.payment-received-edit-result-info__item._warning {
  color: #e17d10;
}
.payment-received-edit-result-info__item._error {
  color: #8f1b2c;
}
.payment-received-edit-result-info__item-title {
  width: 20%;
  flex-grow: 1;
  margin-right: 2rem;
}
.payment-received-edit-result-info__item-value {
  width: 10rem;
  flex-shrink: 0;
}
.payment-received-edit {
  display: flex;
  flex-direction: column;
  min-height: 30rem;
}
.payment-received-edit__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.payment-received-edit__main-content {
  background: white;
  flex-grow: 1;
}
.payment-received-edit__pay-full-amount {
  margin-top: 0.6rem;
}
.payment-received-edit__invoices-head {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin: 2rem 0 2rem;
}
.payment-received-edit__invoices-title {
  margin: 0 2rem 0 0;
  font-size: 2rem;
}
.payment-received-edit__clear-link {
  border: 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  font-size: 1.2rem;
  color: #8f1b2c;
}
.payment-received-edit__clear-link:hover {
  color: #aa0300;
}
.payment-received-edit__invoices-table {
  width: 100%;
  margin: 0 0 2rem;
}
.payment-received-edit__invoices-table th:last-child {
  width: 13rem;
}
.payment-received-edit__invoices-table td._payment {
  width: 12rem;
}
.payment-received-edit__invoices-table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.payment-received-edit__invoice-pay {
  width: 10rem;
  margin-top: -0.5rem;
}
.payment-received-edit__pay-in-full,
.payment-received-edit__apply-full-amount {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
  display: block;
  margin-bottom: -0.6rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  color: #4778ff;
}
.payment-received-edit__pay-in-full:not(:disabled):hover,
.payment-received-edit__apply-full-amount:not(:disabled):hover {
  text-decoration: unset;
}
.payment-received-edit__pay-in-full:not(:disabled):hover,
.payment-received-edit__apply-full-amount:not(:disabled):hover {
  text-decoration: unset;
}
.payment-received-edit__apply-full-amount._disabled {
  color: #b8b8b8;
  cursor: not-allowed;
}
.payment-received-edit__amount-received-btns {
  display: flex;
  justify-content: space-between;
}
@supports (text-decoration-style: dotted) {
  .payment-received-edit__pay-in-full,
  .payment-received-edit__apply-full-amount {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}
.inventory-page__main-content {
  background: white;
}
.payment-received-page__additional-information {
  margin: 5rem auto 0;
  zoom: var(--blank-preview-zoom, 1);
  width: 8.5in;
}
.payment-received-page__additional-information-title {
  margin: 0 0 1.5rem;
}
.payment-received-page__additional-information-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.payment-received-page__additional-information-list dt,
.payment-received-page__additional-information-list dd {
  margin: 0 0 0.8rem;
}
.payment-received-page__additional-information-list dt:last-child,
.payment-received-page__additional-information-list dd:last-child,
.payment-received-page__additional-information-list dt:nth-last-child(2),
.payment-received-page__additional-information-list dd:nth-last-child(2) {
  margin-bottom: 0;
}
.payment-received-page__additional-information-list dt {
  width: 15rem;
  color: #808080;
}
.payment-received-page__additional-information-list dd {
  width: calc(100% - 16rem);
}
.payment-received-page__files-list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px 0 0 -20px;
}
.payment-received-page__file-wrap {
  width: 120px;
  margin: 20px 0 0 20px;
  border: 1px solid #dedede;
  border-radius: 0.3rem;
  cursor: zoom-in;
}
.payment-received-page__file {
  width: 100%;
}
.payment-received-page__files-empty {
  padding: 1.1rem 2rem;
  font-size: 1.2rem;
  line-height: 1.33333333em;
}
.aes-itn-page__main-content {
  background: white;
}
.employees-page__main-content {
  display: flex;
}
.employees-page__employees-list {
  min-width: 29rem;
  flex: 1 1 29rem;
  border-right: 1px solid #dedede;
}
.employees-page__one-employee {
  flex: 1 0 calc(100% - 29rem);
}
.settings-page__content-head {
  padding: 2rem;
}
.settings-page__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.settings-page__main-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-grow: 1;
}
.settings-page__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 40rem;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.settings-page .inner-nav__list {
  height: 10rem;
  flex-grow: 1;
  overflow-y: auto;
}
.settings-page__main {
  width: calc(100% - 40rem);
  margin-left: auto;
  height: 10rem;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.settings-page__mob-header {
  padding: 1rem 2rem 1rem;
  background-color: white;
  z-index: 10;
  position: sticky;
  top: 5rem;
}
@media (max-width: 749px) {
  .settings-page__mob-header {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
@media (max-width: 1600px) {
  .settings-page__nav {
    width: 30rem;
  }
  .settings-page__main {
    width: calc(100% - 30rem);
  }
}
@media (max-width: 1100px) {
  .settings-page__main {
    width: 100%;
    height: auto;
  }
}
.one-setting-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.one-setting-page__header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  .one-setting-page__header {
    padding-top: 1rem;
  }
}
@media (max-width: 749px) {
  .one-setting-page__header {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
.one-setting-page__title {
  margin: 0;
  font-size: 2rem;
  color: #808080;
}
.one-setting-page__add-btn {
  margin-left: 2rem;
  flex-shrink: 0;
}
.one-setting-page__add-btn svg {
  width: 1rem;
}
@media (max-width: 1100px) {
  .one-setting-page__add-btn {
    padding: 0 1.1rem;
  }
  .one-setting-page__add-btn svg {
    margin: 0;
  }
  .one-setting-page__add-btn span {
    display: none;
  }
}
.one-setting-page__select-referrer {
  margin-bottom: 2rem;
}
.one-setting-page__note {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.one-setting-page__note._accent {
  color: #14a75d;
}
.one-setting-page__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
}
@media (max-width: 749px) {
  .one-setting-page__main {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
.one-setting-page__alert {
  margin-bottom: 2rem;
}
.one-setting-page__table-wrap {
  flex-grow: 1;
  position: relative;
}
.one-setting-page__table {
  width: 100%;
  margin: 0;
  border: 1px solid #dedede;
  border-top: 0;
}
.one-setting-page__table td._actions {
  width: 5.4rem;
}
.one-setting-page__table._items td:first-child {
  width: 14rem;
}
.one-setting-page__table._items td:nth-child(2) {
  width: 9rem;
}
.one-setting-page__table-mark {
  margin-top: 0.4rem;
  fill: #14a75d;
}
.one-setting-page__table-mark._not {
  fill: #b8b8b8;
}
.one-setting-page__img-wrap {
  display: block;
  width: 4rem;
  height: 4rem;
  background: #f2f2f2;
  line-height: 0;
}
a.one-setting-page__img-wrap:hover {
  opacity: 0.8;
  cursor: zoom-in;
}
.one-setting-page__img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.one-setting-page__actions-wrap {
  display: flex;
}
.one-setting-page__see {
  margin-right: 0.5rem;
}
.one-setting-page__no-content {
  text-align: center;
}
.profile-page__content {
  position: relative;
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  min-height: 38rem;
  background: white;
}
.profile-page__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 25rem;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  overflow-y: auto;
}
@media screen and (max-width: 749px) {
  .profile-page__nav {
    position: static;
    width: auto;
    height: auto;
  }
}
.profile-page__main {
  width: calc(100% - 25rem);
  margin-left: auto;
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 749px) {
  .profile-page__main {
    width: auto !important;
    margin-left: 0;
  }
  .profile-page__main .person-info__header {
    border-bottom: 0;
  }
}
.profile-page__main._resize {
  width: 100%;
}
.company-profile-page {
  padding: 2rem;
}
.company-profile-page__header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2rem;
}
.company-profile-page__item {
  margin-bottom: 2rem;
  background-color: #f2f2f2;
  padding: 2rem;
  border-radius: 0.3rem;
}
.company-profile-page__main-info-row {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}
.company-profile-page__logo {
  width: 10rem;
  position: relative;
  flex-shrink: 0;
  margin-right: 2rem;
}
.company-profile-page__logo::before {
  content: '';
  width: 100%;
  display: block;
  padding-top: 30%;
}
.company-profile-page__logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.company-profile-page__logo-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-profile-page__logo-placeholder svg {
  fill: #dedede;
}
.company-profile-page__name {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  flex: 1;
}
.company-profile-page__active {
  margin-right: 1rem;
  flex-shrink: 0;
}
.help-page__content {
  padding: 2rem;
}
.help-page__search {
  width: 50rem;
  max-width: 100%;
  margin-bottom: 3rem;
}
.help-page__item {
  margin-bottom: 3rem;
}
.help-page__title {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #4778ff;
}
.help-page__title a {
  color: inherit;
}
.time-kiosk-page__content {
  padding: 3rem 2rem;
  flex-grow: 1;
}
.time-kiosk-page__scan-title {
  margin: 0 0 2rem;
}
.time-kiosk-page__inline-preloader {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  color: #808080;
}
.time-kiosk-page__inline-preloader .preloader {
  margin-right: 1rem;
}
.time-kiosk-page__alert {
  margin-bottom: 2rem;
}
.time-kiosk-page__table {
  width: 50rem;
  max-width: 100%;
  margin-bottom: 3rem;
}
.time-kiosk-page__table td:first-child {
  font-weight: 700;
  color: #808080;
}
.time-kiosk-page__table td:last-child {
  text-align: right;
}
.time-kiosk-page__note {
  color: #808080;
}
.deposits-page__content {
  display: flex;
}
.deposits-page__list {
  min-width: 29rem;
  flex: 1 1 29rem;
  border-right: 1px solid #dedede;
}
.deposits-page__one-deposit {
  flex: 1 0 calc(100% - 29rem);
}
.reports-page__content {
  display: flex;
  flex: 1 1 0;
  overflow: hidden;
}
.reports-page__nav {
  width: 40rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #dedede;
}
.reports-page__main {
  flex: 1;
  width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.reports-page__main-content {
  padding: 2rem 2rem 4rem;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
}
.reports-page__table {
  margin-left: auto;
  margin-right: auto;
}
.reports-page__default {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.reports-page__default-title {
  color: #808080;
}
.reports-page__content-header {
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  display: flex;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  gap: 1.5rem 2rem;
}
.reports-page__category-select {
  margin-left: 2rem;
  margin-right: 2rem;
}
.reports-page__content-header-wrap {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.reports-page__dates-select {
  width: 20rem;
}
.reports-page__dates-select .select__option._disabled {
  display: none;
}
.reports-page__dates-range {
  width: 25rem;
}
.reports-page__actions {
  margin-left: auto;
}
@media (max-width: 1600px) {
  .reports-page__nav {
    width: 30rem;
  }
}
@media (max-width: 1100px) {
  .reports-page__content {
    flex-basis: auto;
  }
}
@media (max-width: 749px) {
  .reports-page__main-content {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .reports-page__category-select {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .reports-page__content-header {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    background: transparent;
  }
}
@media (max-width: 749px) {
  .reports-page__content-header {
    flex-wrap: wrap;
  }
  .reports-page__dates-select {
    flex: 1 1 100%;
  }
  .reports-page__dates-range {
    flex: 1;
  }
}
.vendors-page__main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 38rem;
  flex-grow: 1;
  background: white;
}
.vendors-page__persons-list {
  width: 100%;
}
.vendors-page__persons-list._short {
  width: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
}
.vendors-page__one-person {
  margin-left: auto;
  width: calc(100% - 30rem);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.vendors-page__one-person._full {
  width: 100%;
}
.vendors-page__status {
  margin-right: auto;
  flex-shrink: 1;
  max-width: calc(100% - 5rem);
}
.vendors-page__status .dropdown__dropdown {
  width: 14rem;
}
.vendors-page__status .dropdown__btn {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  min-height: 2.8rem;
  font-size: 1.4rem;
  line-height: 1.42857143em;
  font-weight: 600;
  color: #1f1f1f;
}
.vendors-page__status .dropdown__arrow svg {
  fill: #333333 !important;
}
@media screen and (max-width: 1600px) {
  .vendors-page__persons-list._short {
    width: 26rem;
  }
  .vendors-page__one-person {
    width: calc(100% - 26rem);
  }
}
@media screen and (max-width: 1340px) {
  .vendors-page__main {
    padding: 0;
  }
}
@media screen and (max-width: 749px) {
  .vendors-page__main {
    background: transparent;
  }
  .vendors-page .person-info__header {
    padding-top: 0;
    border-bottom: 0;
  }
  .vendors-page__persons-list {
    background: transparent;
  }
  .vendors-page__persons-list._short {
    display: none;
  }
  .vendors-page__one-person {
    margin-left: 0;
    width: 100%;
    height: auto;
  }
  .vendors-page._show-one-vendor .vendors-page__head {
    display: none;
  }
  .vendors-page__back-wrap {
    padding: 1.6rem;
  }
  .vendors-page__back-btn {
    border-radius: 0;
    border: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    display: inline-flex;
    font: inherit;
    text-align: inherit;
    color: inherit;
    text-decoration: none;
    font-size: 1.2rem;
    align-items: center;
    font-weight: 600;
    color: #1f1f1f;
  }
  .vendors-page__back-btn:not(:disabled):hover {
    text-decoration: unset;
  }
  .vendors-page__back-btn:not(:disabled):hover {
    text-decoration: unset;
  }
  .vendors-page__back-btn svg {
    width: 0.6rem;
    height: 1rem;
    transform: scaleX(-1);
    fill: currentColor;
    margin-right: 0.9rem;
    flex-shrink: 0;
  }
}
.additional-name__label {
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: inline-block;
  transition: 0.15s;
}
.additional-name__icon {
  background-color: #f2f2f5;
  min-width: 2.4rem;
  margin-right: 1rem;
}
.additional-name__icon:hover {
  background-color: #aa0300 !important;
  border-color: #aa0300 !important;
}
.additional-name__icon svg {
  width: 1.2rem;
  height: 1.2rem;
}
.additional-name__wrapper {
  display: flex;
  align-items: center;
}
.additional-name__input {
  width: 100%;
}
.shipping-modal {
  min-height: 15rem;
}
.shipping-modal__select {
  margin-bottom: 2rem;
}
.shipping-modal__content {
  padding: 10px 0;
  margin-bottom: 2rem;
}
.shipping-modal__details-title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.shipping-modal__details {
  width: 100%;
}
.shipping-modal__details td:first-child {
  font-weight: 600;
  width: 35%;
}
.shipping-modal__row {
  margin-bottom: 10px;
}
.shipping-modal__label {
  display: inline-block;
  width: 50%;
}
.shipping-modal__actions {
  display: flex;
  justify-content: flex-end;
}
.shipping-modal__button-cancel {
  margin-right: 1.5rem;
}
.shipping-file-files {
  position: relative;
  padding: 2rem;
}
.shipping-file-files__manipulation {
  margin-bottom: 2rem;
}
.loading-tabs__head {
  padding: 2rem;
}
.loading-tabs__container-wrap {
  margin-top: 0;
}
.loading-tabs__container-wrap .info-block__table-wrap {
  padding: 1rem 0 0;
}
.loading-tabs__table {
  width: 100%;
  margin: 0;
}
.loading-tabs__table td {
  vertical-align: middle;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.loading-tabs__table td._count {
  width: 3.5rem;
  padding-right: 0;
}
.loading-tabs__table td._descr {
  width: auto;
}
.loading-tabs__table td._comments {
  width: auto;
}
.loading-tabs__table td:nth-last-child(3) {
  width: 4.5rem;
}
.loading-tabs__table td:nth-last-child(3) .notes-btn {
  margin: 0;
}
.loading-tabs__table td:nth-last-child(2) {
  width: 5.4rem;
}
.loading-tabs__table td:last-child {
  width: 5.4rem;
}
.loading-tabs__table tr:last-child td {
  border-bottom: 0;
}
.loading-tabs__comments-button {
  position: relative;
  z-index: 2;
  background: white;
  padding: 0 0.6rem;
  margin-left: -0.6rem;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
  transition: 0.15s;
}
.loading-tabs__add-comments {
  margin-top: 1.5rem;
}
.loading-tabs__button-delete {
  background: #f2f2f2 !important;
  height: 2.4rem;
  min-width: 2.4rem !important;
  margin-right: 1.5rem;
  transition: all 0.3s;
}
.loading-tabs__button-delete:hover {
  border-color: #aa0300 !important;
  background-color: #aa0300 !important;
}
.loading-tabs__comments-container {
  display: flex;
  align-items: center;
}
.loading-tabs__comments-field {
  min-width: 22rem;
}
.loading-tabs__bottom {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.loading-tabs__bottom .col-4:last-child .input {
  max-width: 15rem;
}
.auth-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.auth-page__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}
.auth-page__logo {
  height: 4rem;
  margin-bottom: 4rem;
}
.auth-page__auth-form {
  margin-bottom: 4rem;
}
.auth-page__footer {
  align-self: stretch;
}
.editor-for-rates {
  flex-grow: 1;
}
.editor-for-rates .ProseMirror {
  min-height: 5rem;
}
.shipping-rates__main {
  position: relative;
  height: 30rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.shipping-rates__main-content {
  height: 30rem;
  flex-grow: 1;
  width: 80%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1340px) {
  .shipping-rates__main-content {
    width: 100%;
    white-space: nowrap;
    margin-left: 0;
  }
}
@media (max-width: 980px) {
  .shipping-rates-edit .info-block__row.first-row {
    margin-bottom: -3rem;
  }
  .shipping-rates-edit .info-block__row.first-row .col-3 {
    padding: 0 1rem 3rem 1rem;
  }
  .shipping-rates-edit .info-block__row.second-row,
  .shipping-rates-edit .info-block__row.third-row,
  .shipping-rates-edit .info-block__row.fourth-row {
    margin-bottom: -3rem;
  }
  .shipping-rates-edit .info-block__row.second-row .col-4,
  .shipping-rates-edit .info-block__row.third-row .col-4,
  .shipping-rates-edit .info-block__row.fourth-row .col-4 {
    padding: 0 1rem 3rem 1rem;
  }
}
.shipping-rates-list {
  height: 100%;
  overflow: auto;
}
.shipping-rates-list__header {
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.shipping-rates-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.shipping-rates-list__search,
.shipping-rates-list__head-btn {
  margin-right: 1rem;
}
.shipping-rates-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.shipping-rates-list__table-wrap {
  position: relative;
}
.shipping-rates-list__table {
  width: 100%;
  margin: 0;
}
.shipping-rates-list__table th._actions {
  width: 4.4rem;
}
.shipping-rates-list__table thead th {
  background-color: #d2d2d2;
  background-color: #dedede;
  border-bottom: 0;
}
.shipping-rates-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.shipping-rates-list__table td:not(._no-link) {
  cursor: pointer;
  transition: background-color 0.15s;
}
.shipping-rates-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shipping-rates-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.shipping-rates-list__info {
  padding-top: 1px;
}
.shipping-rates-list__info > * {
  margin-bottom: 0.4rem;
}
.shipping-rates-list__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.shipping-rates-list__help-list li {
  margin: 0.6rem 0;
}
.shipping-rates-list__notes-btn {
  position: relative;
  overflow: visible;
}
.shipping-rates-list__notes-btn.btn svg {
  margin-right: 0;
}
.shipping-rates-list__notes-btn-amount {
  position: absolute;
  top: -0.9rem;
  right: -0.9rem;
  background: #4778ff;
  min-width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  text-align: center;
  color: white;
}
.shipping-rates-list._short .shipping-rates-list__header {
  border-bottom: 1px solid #dedede;
}
.shipping-rates-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.shipping-rates-list__short-item._selected {
  background-color: #e3ebff;
}
.shipping-rates-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.shipping-rates-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.shipping-rates-list__short-el {
  width: 48%;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.shipping-rates-list__short-el._right {
  text-align: right;
}
.shipping-rates-list__search {
  width: 21.8rem;
  margin-right: 1rem;
}
@media (max-width: 1100px) {
  .shipping-rates-list__header {
    padding: 0 1.8rem;
    position: static;
    background-color: transparent;
    border: 0;
  }
  .shipping-rates-list__search {
    margin-right: 0;
    width: 100%;
    background-color: white;
    border-color: #e0e0e0;
  }
}
.shipping-rates-destinations__groups-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  overflow-y: auto;
}
.shipping-rates-destinations__groups-table {
  width: 100%;
  margin: 0;
}
.shipping-rates-destinations__groups-table th {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  background: white;
}
.shipping-rates-destinations__groups-table th:last-child {
  text-align: right;
}
.shipping-rates-destinations__groups-table td {
  background-color: #f2f2f2;
}
.shipping-rates-destinations__groups-table td:last-child {
  text-align: right;
}
.shipping-rates-destinations__groups-table tr._active-exact td {
  background-color: #e3ebff;
}
.shipping-rates-destinations__groups-table tbody tr {
  cursor: pointer;
}
.shipping-rates-destinations__main-content {
  height: 30rem;
  flex-grow: 1;
  width: 80%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.shipping-rates-destinations__table-wrap {
  height: 25rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.shipping-rates-destinations__scroll-block {
  flex-grow: 1;
}
.shipping-rates-destinations__table {
  width: 100%;
  margin: 0;
}
.shipping-rates-destinations__table tr {
  display: flex;
}
.shipping-rates-destinations__table th,
.shipping-rates-destinations__table td {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.shipping-rates-destinations__table td {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.shipping-rates-destinations__table tbody tr {
  cursor: pointer;
  transition: 0.15s;
}
.shipping-rates-destinations__table tbody tr:hover {
  background-color: #f2f2f2;
}
.shipping-rates-destinations__table tbody tr:hover .hide-particle__placeholder::before {
  opacity: 0;
}
.shipping-rates-destinations__table tbody tr:hover .hide-particle__placeholder::after {
  opacity: 1;
}
.shipping-rates-destinations__table th:first-child,
.shipping-rates-destinations__table td:first-child {
  width: 18rem;
}
.shipping-rates-destinations__table th:nth-child(2),
.shipping-rates-destinations__table td:nth-child(2) {
  width: 20rem;
  flex-grow: 1;
}
.shipping-rates-destinations__table th:nth-last-child(2),
.shipping-rates-destinations__table td:nth-last-child(2) {
  width: 4.4rem;
  align-items: center;
}
.shipping-rates-destinations__table th:last-child,
.shipping-rates-destinations__table td:last-child {
  width: 5.4rem;
}
.shipping-rates-destinations__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.shipping-rates-destinations__help-list li {
  margin: 0.6rem 0;
}
.shipping-rates-destinations__group-amount {
  display: inline-block;
  min-width: 2.5rem;
  min-height: 2.5rem;
  background: white;
  border-radius: 50%;
  text-align: center;
  padding: 0.4rem 0.3rem 0;
  font-weight: 600;
  color: #616161;
}
.shipping-rates-destinations__search-toggle {
  background: transparent;
  border: 1px solid #dedede;
}
.shipping-rates-destinations__search-toggle svg {
  width: 1.8rem;
  height: 1.8rem;
  fill: rgba(0, 0, 0, 0.65);
}
.shipping-rates-destinations__search-toggle._open,
.shipping-rates-destinations__search-toggle:hover {
  background-color: #00bb5d;
  border-color: #00bb5d;
}
.shipping-rates-destinations__search-toggle._open svg,
.shipping-rates-destinations__search-toggle:hover svg {
  fill: white;
}
.shipping-rates-destinations__search-short-wrap {
  padding: 2rem;
  border-bottom: 1px solid #dedede;
}
.shipping-rates-destinations__search-short {
  width: 100%;
}
@media (max-width: 1340px) {
  .shipping-rates-destinations__groups-list {
    position: static;
    width: 100%;
    height: auto;
    overflow-y: unset;
  }
  .shipping-rates-destinations__select-wrapper {
    padding: 10px 18px;
  }
}
@media (max-width: 1100px) {
  .shipping-rates-items {
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .shipping-rates-items__item {
    width: 100%;
    max-width: calc(100% / 2 - 8px);
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 50px 12px 8px;
    margin-top: 10px;
    position: relative;
  }
  .shipping-rates-items__item-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 0;
  }
  .shipping-rates-items__item-actions.actions {
    padding: 6px 12px;
  }
  .shipping-rates-items__item-checkbox {
    padding: 6px 12px;
  }
  .shipping-rates-items__item-block + .shipping-rates-items__item-block {
    margin-top: 10px;
  }
}
@media (max-width: 980px) {
  .shipping-rates-items__item {
    font-size: 1.2rem;
    line-height: 1.33333333em;
  }
}
@media (max-width: 749px) {
  .shipping-rates-items__item {
    width: 100%;
    max-width: 100%;
  }
}
.shipping-rates-select {
  width: 100%;
}
.shipping-rates-select__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.shipping-rates-select__count {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  background: #f2f2f2;
  border-radius: 50%;
  padding: 0.4rem 0.3rem 0;
  font-weight: 600;
  color: #616161;
}
.shipping-rates-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.shipping-rates-toolbar__drop-menu {
  margin-right: 1rem;
}
.shipping-rates-toolbar__drop-menu .dropdown__dropdown-inner {
  width: 15rem;
}
.shipping-rates-toolbar__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.shipping-rates-toolbar__head-btn {
  margin-right: 1rem;
}
@media (max-width: 1100px) {
  .shipping-rates-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
  }
  .shipping-rates-toolbar__selected {
    order: -1;
    margin-right: auto;
  }
  .shipping-rates-toolbar__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
  .shipping-rates-toolbar__head-btn {
    background-color: #808080 !important;
    border-color: #808080 !important;
  }
  .shipping-rates-toolbar__head-btn svg {
    fill: white !important;
  }
  .shipping-rates-toolbar__drop-menu {
    margin-right: 0;
  }
  .shipping-rates-toolbar__drop-menu .dropdown__dropdown {
    top: auto;
    bottom: 100%;
    left: auto;
    right: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .shipping-rates-toolbar__drop-menu .dropdown__dropdown-inner {
    top: auto;
    bottom: 0;
  }
}
.storage-rates__main {
  position: relative;
  height: 30rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.storage-rates__main-content {
  height: 30rem;
  flex-grow: 1;
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1340px) {
  .storage-rates__main-content {
    width: 100%;
    white-space: nowrap;
    margin-left: 0;
  }
}
.storage-rates-list {
  height: 100%;
  overflow: auto;
}
.storage-rates-list__header {
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.storage-rates-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.storage-rates-list__search,
.storage-rates-list__head-btn {
  margin-right: 1rem;
}
.storage-rates-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.storage-rates-list__table-wrap {
  position: relative;
}
.storage-rates-list__table {
  width: 100%;
  margin: 0;
}
.storage-rates-list__table th._actions {
  width: 4.4rem;
}
.storage-rates-list__table thead th {
  background-color: #d2d2d2;
  background-color: #dedede;
  border-bottom: 0;
}
.storage-rates-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.storage-rates-list__table td:not(._no-link) {
  cursor: pointer;
  transition: 0.15s;
}
.storage-rates-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.storage-rates-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.storage-rates-list__info {
  padding-top: 1px;
}
.storage-rates-list__info > * {
  margin-bottom: 0.4rem;
}
.storage-rates-list__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.storage-rates-list__help-list li {
  margin: 0.6rem 0;
}
.storage-rates-list__notes-btn {
  position: relative;
  overflow: visible;
}
.storage-rates-list__notes-btn.btn svg {
  margin-right: 0;
}
.storage-rates-list__notes-btn-amount {
  position: absolute;
  top: -0.9rem;
  right: -0.9rem;
  background: #4778ff;
  min-width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  text-align: center;
  color: white;
}
.storage-rates-list._short .storage-rates-list__header {
  border-bottom: 1px solid #dedede;
}
.storage-rates-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.storage-rates-list__short-item._selected {
  background-color: #e3ebff;
}
.storage-rates-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.storage-rates-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.storage-rates-list__short-el {
  width: 48%;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.storage-rates-list__short-el._right {
  text-align: right;
}
@media (max-width: 980px) {
  .storage-rates-edit .info-block__row.first-row {
    margin-bottom: -3rem;
  }
  .storage-rates-edit .info-block__row.first-row .col-4 {
    padding: 0 1rem 3rem 1rem;
  }
  .storage-rates-edit .info-block__row.second-row,
  .storage-rates-edit .info-block__row.third-row {
    margin-bottom: -3rem;
  }
  .storage-rates-edit .info-block__row.second-row .col-4,
  .storage-rates-edit .info-block__row.third-row .col-4,
  .storage-rates-edit .info-block__row.second-row .col-2,
  .storage-rates-edit .info-block__row.third-row .col-2 {
    padding: 0 1rem 3rem 1rem;
  }
}
@media (max-width: 1100px) {
  .storage-rates-items {
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .storage-rates-items__item {
    width: 100%;
    max-width: calc(100% / 2 - 8px);
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 50px 12px 8px;
    margin-top: 10px;
    position: relative;
  }
  .storage-rates-items__item-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 0;
  }
  .storage-rates-items__item-actions.actions {
    padding: 6px 12px;
  }
  .storage-rates-items__item-checkbox {
    padding: 6px 12px;
  }
  .storage-rates-items__item-block + .storage-rates-items__item-block {
    margin-top: 10px;
  }
}
@media (max-width: 980px) {
  .storage-rates-items__item {
    font-size: 1.2rem;
    line-height: 1.33333333em;
  }
}
@media (max-width: 749px) {
  .storage-rates-items__item {
    width: 100%;
    max-width: 100%;
  }
}
.storage-rates-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.storage-rates-toolbar__drop-menu {
  margin-right: 1rem;
}
.storage-rates-toolbar__drop-menu .dropdown__dropdown-inner {
  width: 15rem;
}
.storage-rates-toolbar__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.storage-rates-toolbar__head-btn {
  margin-right: 1rem;
}
@media (max-width: 1100px) {
  .storage-rates-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
  }
  .storage-rates-toolbar__selected {
    order: -1;
    margin-right: auto;
  }
  .storage-rates-toolbar__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
  .storage-rates-toolbar__head-btn {
    background-color: #808080 !important;
    border-color: #808080 !important;
  }
  .storage-rates-toolbar__head-btn svg {
    fill: white !important;
  }
  .storage-rates-toolbar__drop-menu {
    margin-right: 0;
  }
  .storage-rates-toolbar__drop-menu .dropdown__dropdown {
    top: auto;
    bottom: 100%;
    left: auto;
    right: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .storage-rates-toolbar__drop-menu .dropdown__dropdown-inner {
    top: auto;
    bottom: 0;
  }
}
.commodity-delivery-rates-list {
  height: 100%;
  overflow: auto;
}
.commodity-delivery-rates-list__header {
  z-index: 32;
  top: 5rem;
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
}
.commodity-delivery-rates-list__selection-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.commodity-delivery-rates-list__search,
.commodity-delivery-rates-list__head-btn {
  margin-right: 1rem;
}
.commodity-delivery-rates-list__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.commodity-delivery-rates-list__table-wrap {
  position: relative;
}
.commodity-delivery-rates-list__table {
  width: 100%;
  margin: 0;
}
.commodity-delivery-rates-list__table th._actions {
  width: 4.4rem;
}
.commodity-delivery-rates-list__table thead th {
  background-color: #d2d2d2;
  background-color: #dedede;
  border-bottom: 0;
}
.commodity-delivery-rates-list__table tbody tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.commodity-delivery-rates-list__table td:not(._no-link) {
  cursor: pointer;
  transition: 0.15s;
}
.commodity-delivery-rates-list__footer {
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commodity-delivery-rates-list__showing-info {
  margin: 0 auto 0 0;
  font-size: 1.2rem;
}
.commodity-delivery-rates-list__info {
  padding-top: 1px;
}
.commodity-delivery-rates-list__info > * {
  margin-bottom: 0.4rem;
}
.commodity-delivery-rates-list__help-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.commodity-delivery-rates-list__help-list li {
  margin: 0.6rem 0;
}
.commodity-delivery-rates-list__notes-btn {
  position: relative;
  overflow: visible;
}
.commodity-delivery-rates-list__notes-btn.btn svg {
  margin-right: 0;
}
.commodity-delivery-rates-list__notes-btn-amount {
  position: absolute;
  top: -0.9rem;
  right: -0.9rem;
  background: #4778ff;
  min-width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  text-align: center;
  color: white;
}
.commodity-delivery-rates-list._short .commodity-delivery-rates-list__header {
  border-bottom: 1px solid #dedede;
}
.commodity-delivery-rates-list__short-item {
  display: flex;
  padding: 1rem 2rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.commodity-delivery-rates-list__short-item._selected {
  background-color: #e3ebff;
}
.commodity-delivery-rates-list__short-checkbox {
  flex-shrink: 0;
  margin-right: 2rem;
}
.commodity-delivery-rates-list__short-item-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 50%;
}
.commodity-delivery-rates-list__short-el {
  width: 48%;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.commodity-delivery-rates-list__short-el._right {
  text-align: right;
}
@media (max-width: 980px) {
  .commodity-delivery-rates-edit .info-block__row.first-row {
    margin-bottom: -3rem;
  }
  .commodity-delivery-rates-edit .info-block__row.first-row .col-4 {
    padding: 0 1rem 3rem 1rem;
  }
  .commodity-delivery-rates-edit .info-block__row.second-row,
  .commodity-delivery-rates-edit .info-block__row.third-row,
  .commodity-delivery-rates-edit .info-block__row.fourth-row {
    margin-bottom: -3rem;
  }
  .commodity-delivery-rates-edit .info-block__row.second-row .col-3,
  .commodity-delivery-rates-edit .info-block__row.third-row .col-3,
  .commodity-delivery-rates-edit .info-block__row.fourth-row .col-3,
  .commodity-delivery-rates-edit .info-block__row.second-row .col-4,
  .commodity-delivery-rates-edit .info-block__row.third-row .col-4,
  .commodity-delivery-rates-edit .info-block__row.fourth-row .col-4,
  .commodity-delivery-rates-edit .info-block__row.second-row .col-6,
  .commodity-delivery-rates-edit .info-block__row.third-row .col-6,
  .commodity-delivery-rates-edit .info-block__row.fourth-row .col-6 {
    padding: 0 1rem 3rem 1rem;
  }
}
@media (max-width: 1100px) {
  .commodity-delivery-rates-items {
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .commodity-delivery-rates-items__item {
    width: 100%;
    max-width: calc(100% / 2 - 8px);
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 50px 12px 8px;
    margin-top: 10px;
    position: relative;
  }
  .commodity-delivery-rates-items__item-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 0;
  }
  .commodity-delivery-rates-items__item-actions.actions {
    padding: 6px 12px;
  }
  .commodity-delivery-rates-items__item-checkbox {
    padding: 6px 12px;
  }
  .commodity-delivery-rates-items__item-block + .commodity-delivery-rates-items__item-block {
    margin-top: 10px;
  }
}
@media (max-width: 980px) {
  .commodity-delivery-rates-items__item {
    font-size: 1.2rem;
    line-height: 1.33333333em;
  }
}
@media (max-width: 749px) {
  .commodity-delivery-rates-items__item {
    width: 100%;
    max-width: 100%;
  }
}
.commodity-delivery-rates-toolbar {
  display: flex;
  align-items: center;
  transition: 0.15s;
}
.commodity-delivery-rates-toolbar__drop-menu {
  margin-right: 1rem;
}
.commodity-delivery-rates-toolbar__drop-menu .dropdown__dropdown-inner {
  width: 15rem;
}
.commodity-delivery-rates-toolbar__selected {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.33333333em;
  color: #808080;
}
.commodity-delivery-rates-toolbar__head-btn {
  margin-right: 1rem;
}
@media (max-width: 1100px) {
  .commodity-delivery-rates-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem 1.6rem;
    width: 100%;
  }
  .commodity-delivery-rates-toolbar__selected {
    order: -1;
    margin-right: auto;
  }
  .commodity-delivery-rates-toolbar__selected .checkbox {
    vertical-align: middle;
    padding: 0.8rem;
    margin: -0.8rem;
    margin-right: 0;
    margin-top: -1rem;
  }
  .commodity-delivery-rates-toolbar__head-btn {
    background-color: #808080 !important;
    border-color: #808080 !important;
  }
  .commodity-delivery-rates-toolbar__head-btn svg {
    fill: white !important;
  }
  .commodity-delivery-rates-toolbar__drop-menu {
    margin-right: 0;
  }
  .commodity-delivery-rates-toolbar__drop-menu .dropdown__dropdown {
    top: auto;
    bottom: 100%;
    left: auto;
    right: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .commodity-delivery-rates-toolbar__drop-menu .dropdown__dropdown-inner {
    top: auto;
    bottom: 0;
  }
}
.commodity-label-edit {
  position: relative;
}
.commodity-label-edit__name {
  margin-bottom: 2rem;
}
.commodity-label-edit__error {
  margin-top: 3rem;
}
.commodity-label-edit__footer {
  display: flex;
  margin-top: 3rem;
}
.commodity-label-edit__separator {
  margin: 0 auto;
  padding: 1rem;
}
.commodity-label-edit__btn {
  margin-right: 2rem;
}
.commodity-label-edit__btn:last-child {
  margin-right: 0;
}
.photo__btns-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.photo__camera-wrap {
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  overflow: hidden;
  outline: 0.2rem solid transparent;
  border-radius: 0.5rem;
  background-color: #d8d8d8;
  transition: outline-color 0.3s;
}
.photo__camera-wrap._active {
  outline-color: #14a75d;
}
.photo__camera-wrap._active video {
  opacity: 0;
}
.photo__camera-wrap:before {
  content: '';
  display: block;
  padding-top: 75%;
  width: 100%;
}
.photo__camera-wrap img,
.photo__camera-wrap video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.photo__camera-wrap video {
  transition: opacity 0.15s;
}
.photo__camera-wrap img {
  z-index: 2;
}
.photo__photo-wrap {
  position: absolute;
}
.photo__clear {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
}
.photo__done {
  display: flex;
  justify-content: flex-end;
}
