@media screen and (max-width: @breakpoints[xl]) {
  .customers-page {
    &__persons-list {
      &._short {
        width: 26rem;
      }
    }

    &__one-person {
      width: calc(100% - 26rem);
    }
  }
}

@media screen and (max-width: @breakpoints[lg]) {
  .customers-page {
    &__main {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: @breakpoints[sm]) {
  .customers-page {
    &__main {
      background: transparent;
    }

    .person-info__header {
      padding-top: 0;
      border-bottom: 0;
    }

    &__persons-list {
      background: transparent;

      &._short {
        display: none;
      }
    }

    &__one-person {
      margin-left: 0;
      width: 100%;
      height: auto;
    }

    &._show-one-customer {
      .customers-page__head {
        display: none;
      }
    }

    &__back-wrap {
      padding: @page-sides-padding-sm;
    }

    &__back-btn {
      .reset-button;
      font-size: 1.2rem;
      align-items: center;
      font-weight: @w-semi-bold;
      color: @c-grey-darkest;

      svg {
        width: 0.6rem;
        height: 1rem;
        transform: scaleX(-1);
        fill: currentColor;
        margin-right: 0.9rem;
        flex-shrink: 0;
      }
    }
  }
}
